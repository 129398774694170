import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import imageSlider1 from "../assets/images/home/slider-home/1.png";
import imageSlider2 from "../assets/images/home/slider-home/slider2.webp";
import imageSlider3 from "../assets/images/home/slider-home/2.png";
import imageSlider4 from "../assets/images/home/slider-home/3.png";
import imageSlider5 from "../assets/images/home/slider-home/4.png";
import { ReactComponent as CartSlider } from "../assets/icons/CartSlider.svg";
import { ReactComponent as CalendarSlider } from "../assets/icons/CalendarSlider.svg";
import { ReactComponent as GeoipSlider } from "../assets/icons/GeoipSlider.svg";
import { ReactComponent as Search } from "../assets/icons/Search.svg";
import Flatpickr from "react-flatpickr";
import { fetchAutocompleteFilters } from "../services/apiServiceAutocomplete";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import { Autoplay, EffectFade } from "swiper/modules";

export function HeroSection() {
  const [search, setSearch] = useState("");
  const [zip, setZip] = useState("");
  const [when, setWhen] = useState("");
  const [where, setWhere] = useState([]);
  const [searchWhen, setSearchWhen] = useState("");
  const [searchWhere, setSearchWhere] = useState("");
  const [rental, setRental] = useState("");
  const [rentalOptions, setRentalOptions] = useState([]);
  const productModalRef = useRef(null);
  const zipModalRef = useRef(null);
  const productInputRef = useRef(null);
  const zipInputRef = useRef(null);

  const handleSearch = async (event) => {
    const value = event.target.value;
    setSearch(value);
    if (value.length >= 3) {
      const results = await fetchAutocompleteFilters(
        value,
        "categories_synonyms"
      );
      setRentalOptions(results);
    }
  };

  const handleWhen = (selectedDates) => {
    const correctFormatDates = selectedDates.map((date) => {
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      return localDate.toISOString().split("T")[0];
    });
    setSearchWhen(correctFormatDates.join(","));
    setWhen(correctFormatDates.join(","));
  };

  const handleWhere = async (event) => {
    const value = event.target.value;
    setZip(value);
    if (value.length >= 3) {
      const results = await fetchAutocompleteFilters(value, "geocode_zips");
      setWhere(results);
    }
  };

  const handleItemClick = (record, setState, setValue, isProduct) => () => {
    const value = record.data ? record.data.zip : record.value;
    if (isProduct) {
      setSearch(record.text);
      setRental(record.text);
    } else {
      setZip(value);
      setSearchWhere(record.value);
    }
    setState([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        productModalRef.current &&
        !productModalRef.current.contains(event.target) &&
        productInputRef.current &&
        !productInputRef.current.contains(event.target)
      ) {
        setRentalOptions([]);
      }
      if (
        zipModalRef.current &&
        !zipModalRef.current.contains(event.target) &&
        zipInputRef.current &&
        !zipInputRef.current.contains(event.target)
      ) {
        setWhere([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const handleSend = () => {
    let date = new Date();
    date.setTime(date.getTime() + 3 * 24 * 60 * 60 * 1000);
    let expires = "; expires=" + date.toUTCString();
    if (search) {
      document.cookie = `searchHome=${search}${expires}; SameSite=None; Secure; path=/`;
    }
    if (when) {
      document.cookie = `whenHome=${when}${expires}; SameSite=None; Secure; path=/`;
    }
    if (searchWhere) {
      document.cookie = `whereHome=${searchWhere}${expires}; SameSite=None; Secure; path=/`;
      document.cookie = `whereHomeZip=${zip}${expires}; SameSite=None; Secure; path=/`;
    }
    window.location.href = "/alquiler";
  };

  return (
    <>
      <section className="w-full h-[90vh] flex items-center justify-center lg:p-12 lg:pb-24">
        <Swiper
          effect={"fade"}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: false,
          }}
          navigation={false}
          modules={[Autoplay, EffectFade]}
          className="w-full h-full overflow-hidden bg-dark lg:rounded-3xl"
        >
          <SwiperSlide className="object-cover w-full h-full current-slide">
            <img src={imageSlider1} alt="persona con cortacésped rojo cortando superficie de césped" className="w-full" />
          </SwiperSlide>
          <SwiperSlide className="object-cover w-full h-full current-slide">
            <img src={imageSlider2} alt="Maquinaria de construcción pesada amarilla puestas en fila" className="w-full" />
          </SwiperSlide>
          <SwiperSlide className="object-cover w-full h-full current-slide">
            <img src={imageSlider3} alt="conjunto de plataformas elevadoras de color azul en una obra" className="w-full" />
          </SwiperSlide>
          <SwiperSlide className="object-cover w-full h-full current-slide">
            <img src={imageSlider4} alt="fregadora eléctrica o de batería limpiando superficie tipo mármol" className="w-full" />
          </SwiperSlide>
          <SwiperSlide className="object-cover w-full h-full current-slide">
            <img src={imageSlider5} alt="Transpaleta amarilla en almacén industrial con estanterías metálicas" className="w-full" />
          </SwiperSlide>
        </Swiper>
        <div className="absolute z-10 max-w-[94vw] md:max-w-[70vw] lg:max-w-[130vh] lg:max-h-[50vh] px-4 py-9 md:p-14 space-y-6 md:space-y-14 bg-white bg-opacity-65 rounded-2xl lg:rounded-3xl backdrop-blur shadow-lg">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: false,
            }}
            navigation={false}
            modules={[Autoplay]}
          >
            {/* <SwiperSlide>
              <h1 className="justify-center w-full gap-2 text-center text-black lowercase lg:flex h1 current-text">
                Alquila{" "}
                <span className="text-primary">todas las herramientas</span> en
                un solo lugar
              </h1>
            </SwiperSlide> */}
            <SwiperSlide>
              <h1 className="justify-center w-full gap-2 text-center text-black lowercase lg:flex flex-wrap h1">
                Encuentra{" "}
                <span className="text-primary">toda la maquinaria</span> en un
                solo lugar con elalquilador.com
              </h1>
            </SwiperSlide>
          </Swiper>
          <div className="items-center w-full gap-6 px-0 py-3 space-y-4 rounded-lg lg:px-3 lg:bg-white lg:rounded-full lg:flex lg:space-y-0">
            <div className="items-center justify-start flex-1 gap-6 space-y-4 lg:flex lg:pl-6 lg:space-y-0">
              {/* Inputs Product */}
              <div
                ref={productInputRef}
                className="relative flex items-center flex-1 gap-3 px-6 py-4 transition-all duration-150 ease-in-out bg-white border-black rounded-full lg:px-0 lg:rounded-none lg:bg-transparent lg:border-r stroke-black border-opacity-10 hover:stroke-primary"
              >
                <CartSlider />
                <input
                  className="w-full font-bold text-black border-none appearance-none focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray"
                  id="rental"
                  value={search}
                  onChange={handleSearch}
                  onKeyDown={handleKeyPress}
                  type="text"
                  placeholder="¿Qué quieres alquilar?"
                />
                {rentalOptions.length > 0 ? (
                  <div
                    ref={productModalRef}
                    id="rentalOptions"
                    className="absolute z-50 mt-6 bg-white border shadow-md min-w-max top-full rounded-xl border-zinc-100"
                  >
                    <div className="flex flex-col gap-4 p-4 overflow-auto max-h-48">
                      {rentalOptions.length > 0
                        ? rentalOptions.map((record, index) => {
                            return (
                              <div
                                key={index}
                                className="flex items-center gap-2 cursor-pointer"
                                onClick={handleItemClick(
                                  record,
                                  setRentalOptions,
                                  setRental,
                                  true
                                )}
                              >
                                <span className="min-w-[15px] min-h-[15px] border border-primary inline-block rounded-full checkbox-filter"></span>
                                <input
                                  type="checkbox"
                                  className="hidden"
                                  name=""
                                  id=""
                                />
                                <label
                                  htmlFor=""
                                  className="text-sm text-left cursor-pointer"
                                >
                                  {record.text}
                                </label>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* Inputs When */}
              <div className="flex items-center flex-1 gap-3 px-6 py-4 transition-all duration-150 ease-in-out bg-white border-r border-black rounded-full lg:px-0 lg:rounded-none lg:bg-transparent stroke-black border-opacity-10 hover:stroke-primary">
                <CalendarSlider />
                <Flatpickr
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y",
                    locale: "es",
                    minDate: "today",
                  }}
                  onChange={handleWhen}
                  className="placeholder:text-primary placeholder:font-bold focus:placeholder-gray focus:outline-none focus:ring-0"
                  placeholder="¿Cuándo?"
                ></Flatpickr>
              </div>
              {/* Inputs Where */}
              <div
                ref={zipInputRef}
                className="relative flex items-center flex-1 gap-3 px-6 py-4 transition-all duration-150 ease-in-out bg-white rounded-full lg:px-0 lg:rounded-none lg:bg-transparent stroke-black hover:stroke-primary"
              >
                <GeoipSlider />
                <input
                  className="w-full font-bold text-black border-none appearance-none focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray"
                  id="zip"
                  value={zip}
                  onChange={handleWhere}
                  type="text"
                  placeholder="¿Código postal?"
                />
                {where.length > 0 ? (
                  <div
                    ref={zipModalRef}
                    id="whereTopBarPlp"
                    className="absolute z-50 mt-6 bg-white border shadow-md min-w-max top-full rounded-xl border-zinc-100"
                  >
                    <div className="flex flex-col gap-4 p-4 overflow-scroll max-h-48">
                      {where.length > 0
                        ? where.map((record, index) => {
                            return (
                              <div
                                key={index}
                                className="flex items-center gap-2 cursor-pointer"
                                onClick={handleItemClick(
                                  record,
                                  setWhere,
                                  setZip,
                                  false
                                )}
                              >
                                <span className="min-w-[15px] min-h-[15px] border border-primary inline-block rounded-full checkbox-filter"></span>
                                <input
                                  type="checkbox"
                                  className="hidden"
                                  name=""
                                  id=""
                                />
                                <label
                                  htmlFor=""
                                  className="text-sm text-left cursor-pointer"
                                >
                                  {record.text}
                                </label>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* Button Search */}
            <button
              onClick={handleSend}
              disabled={where.length > 0}
              className={`flex items-center justify-center w-full gap-2 px-6 py-6 text-white transition-all duration-150 ease-in-out rounded-full lg:w-fit button stroke-white hover:bg-opacity-90 ${
                where.length > 0 ? "bg-[#6e6e6e]" : "bg-primary"
              } `}
            >
              <Search />
              <p>Buscar</p>
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
