import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "../components/ui/Button";
import UserVerified from "../components/UserVerified";
import { useAuth } from "../hooks/AuthProvider";
import { DataEncoder } from "../utils/common";

const VerifyEmail = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { token: authToken, isEmailVerified } = useAuth();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const getQueryParams = () => {
    return {
      token: searchParams.get("token"),
      email: searchParams.get("email")
        ? decodeURIComponent(searchParams.get("email"))
        : null,
    };
  };

  async function handleEmailVerification() {
    const { token, email } = getQueryParams();
    if (!token || !email) {
      if (authToken && isEmailVerified) {
        setError("");
      } else if (authToken && !isEmailVerified) {
        setError("Usuario no verificado");
      } else {
        setError("El enlace no es válido.");
      }
      return;
    }

    try {
      setIsLoading(true);

      const encoder = new DataEncoder();
      const queryString = encoder.encode({ token, email });

      const response = await fetch(
        `${API_BASE_URL}/verify-email?${queryString}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.errors);
      if (response.status === 200) {
        setError("");
      } else if (response.status === 422) {
        const responseBody = await response.json();
        const errorMessage =
          responseBody.errors?.token?.[0] || "Datos inválidos proporcionados.";
        setError(errorMessage);
      } else {
        let errorMessage;
        switch (response.status) {
          case 401:
            errorMessage = "No autorizado. Verifique su token.";
            break;
          case 404:
            errorMessage = "No se encontró la cuenta.";
            break;
          case 500:
            errorMessage =
              "Error en el servidor, inténtelo de nuevo más tarde.";
            break;
          default:
            errorMessage = "Error en la petición.";
            break;
        }
        setError(errorMessage);
      }
    } catch (error) {
      setError("Ocurrió un error inesperado.");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handleEmailVerification();
  }, []);

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center min-h-[calc(100vh-20vh)]">
        <h2 className="mb-16 text-2xl font-bold">Cargando...</h2>
      </div>
    );
  }

  if (error) {
    if (error === "Usuario no verificado") {
      return <UserVerified />;
    }
    return (
      <div className="flex flex-col items-center justify-center py-10 h-1/2">
        <div className="flex flex-col gap-10 p-4 bg-white shadow rounded-xl py-10 w-[80%]">
          <h1 className="text-3xl font-bold">Ocurrió un error</h1>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center py-10 h-1/2">
      <div className="flex flex-col gap-10 p-4 bg-white shadow rounded-xl py-10 w-[80%]">
        <h1 className="text-3xl font-bold text-primary">
          ¡Tu cuenta ha sido verificada!
        </h1>
        <p>Ahora puedes usar todas las funcionalidades.</p>

        {authToken ? (
          <Button
            href="/area-cliente"
            type="primaryWhite"
            classButton={"w-fit mx-auto"}
          >
            Ir a tu perfil
          </Button>
        ) : (
          <Button
            href="/login"
            type="primaryWhite"
            classButton={"w-fit mx-auto"}
          >
            Iniciar sesión
          </Button>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
