import React, { useState, useEffect } from "react";
import { Drawer, Typography, IconButton } from "@material-tailwind/react";
import CartDrawerContent from "../components/CartDrawerContent";
import { useCartDrawer } from "../helpers/CartDrawerContext";
import { formatCurrency } from '../utils/formatters';
import { useEvent } from '../helpers/useEvent';

export function DrawerPlacement() {
	const { openRight, toggleDrawer } = useCartDrawer();
	const [cartData, setCartData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const sendEvent = useEvent();
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
	const urlParams = [
		["with[0]", "sales_orders.sales_orders_lines.product.image"],
		["with[1]", "state"],
	];

	const paramString = urlParams
		.map(([param, value]) => `${param}=${value}`)
		.join("&");

	const fetchCartData = async (sessionId) => {
		if (!sessionId) {
			console.error("No session ID available");
			return;
		}

		const url = `${API_BASE_URL}/shopping-cart/${sessionId}?${paramString}&state=1`;
		setIsLoading(true);
		setError("");  // Clear any existing errors before fetching
		try {
			console.log(`Fetching cart data with session ID: ${sessionId}`);
			const response = await fetch(url, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			const data = await response.json();
			if (!response.ok) {
					const errorMessages = Object.values(data.errors)
							.flat()
							.join(', ');
					throw new Error(errorMessages);
			}
			setCartData(data);
		} catch (error) {
			console.error("Error fetching cart data:", error);
			setError(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (openRight) {
			const sessionId = localStorage.getItem("session_id");
			console.log(`Opening cart drawer with session ID: ${sessionId}`);
			if (sessionId) {
				fetchCartData(sessionId);
			} else {
				console.log("No session ID found in localStorage");
			}
		}
	}, [openRight]);

	const handleDeleteLine = async (sessionId, lineId) => {
		try {
			const response = await fetch(
				`${API_BASE_URL}/shopping-cart/${sessionId}/offer/${lineId}`,
				{
					method: "DELETE",
				}
			);

			const responseData = await response.json();
			if (!response.ok) {
					const errorMessages = Object.values(responseData.errors)
							.flat()
							.join(', ');
					throw new Error(errorMessages);
			}

			fetchCartData(sessionId);
		} catch (error) {
			console.error("Error al eliminar la oferta del carrito:", error);
		}
	};

	const handleClick = (eventType, e) => {
        e.preventDefault();
        const url = e.currentTarget.href; 
        sendEvent(eventType);
        setTimeout(() => {
            window.location.href = url; 
        }, 100); 
    };

	return (
		<React.Fragment>
			<Drawer
				size={600}
				placement="right"
				open={openRight}
				onClose={() => toggleDrawer(false)}
				className="flex-shrink-0 p-4 bg-white rounded-lg lg:p-10 min-w-600"
				overlayProps={{
					className: 'fixed' // Añadir clase personalizada al overlay
				}}>
				<div className="flex items-center justify-between mb-6">
					<Typography className="text-black h2">Resumen del Carrito</Typography>
					<IconButton
						variant="text"
						color=""
						onClick={() => toggleDrawer(false)}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1}
							stroke="black"
							className="w-12 h-12 rounded hover:none">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M6 18L18 6M6 6l12 12" />
						</svg>
					</IconButton>

				</div>
				{isLoading ? (
					<Typography color="gray" className="pr-4 mb-8 font-normal">
						Cargando datos del carrito...
					</Typography>
				) : error ? (
					<Typography color="gray" className="pr-4 mb-8 font-normal">
						{error}
					</Typography>
				) : (
					cartData && (
						<CartDrawerContent
							cartData={cartData}
							onDeleteLine={handleDeleteLine}
							showDeleteButton={true}
							sessionId={cartData.session_id}
						/>
					)
				)}
				<div className="absolute bottom-0 left-0 flex flex-col w-full gap-3 p-5">
					{cartData && cartData.base_amount && (
						<div className="flex flex-col gap-2 p-4 bg-light rounded-2xl">
							<div className="flex justify-between gap-4">
								<p>Base imponible:</p>
								<p className="font-bold">
									{formatCurrency(cartData.base_amount)}
								</p>
							</div>
							<div className="flex justify-between gap-4">
								<p>IVA</p>
								<p className="font-bold">
									{formatCurrency(cartData.tax_amount)}
								</p>
							</div>
							<div className="flex justify-between gap-4 pt-4 border-t border-zinc-200">
								<p className="h4 text-primary">Total</p>
								<p className="font-bold h4">
									{formatCurrency(cartData.total_amount)}
								</p>
							</div>
						</div>
					)}
					{cartData && parseFloat(cartData.base_amount) > 0 && (
						<a  onClick={(event) => handleClick('inicio_checkout', event)} 
							href="/checkout"
							className="flex items-center justify-center w-full mb-2 text-white transition-all duration-150 ease-in-out rounded-full h-14 bg-primary button hover:bg-opacity-90">
							<p>Continuar</p>
						</a>
					)}
				</div>
			</Drawer>
		</React.Fragment>
	);
}
