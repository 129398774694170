import React from "react";

function Policy() {
    return (
        <section className="text-left px-4 lg:px-20 py-14 md:py-24 lg:flex-col flex  gap-5">
            <h1 className="pb-2 text-4xl text-left font-bold text-black md:text-4xl">
                Política de privacidad
            </h1>
            <div className="py-6 space-y-2">
                <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                    ¿Quién es el responsable del tratamiento de sus datos personales?
                </h2>
                <p className="text-sm text-black text-opacity-60">
                    MyGarret S.L. (en adelante, «elgestionador.com»), CIF nº B42887570, ubicado en Calle Nicaragua , 15005, A Coruña B42887570 y correo electrónico: datos@elgestionador.com, será responsable de sus datos personales (en adelante, los «Datos»).
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Le informamos que cuando a través de nuestra Página Web realice algún comentario en redes sociales, el responsable de los Datos que facilite a través de las mismas será la red social correspondiente y no elgestionador.com debiendo dirigirse a dicha red social para ejercer, en su caso, sus derechos de protección de datos que puede consultar en el apartado ¿Cuáles son los derechos de protección de sus datos personales? de la presente Política de Privacidad.
                </p>
            </div>
            <div className="py-6 space-y-2">
                <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                    ¿A quién es aplicable esta Política?
                </h2>
                <p className="text-sm text-black text-opacity-60">
                    La utilización de la Página Web elgestionador.com no está dirigida a menores de 14 años y, por consiguiente, éstos deberán abstenerse de facilitar cualquier información de carácter personal. En este sentido, elgestionador.com recomienda la utilización de su Página Web a personas mayores de 18 años. Para ello, elgestionador.com se reserva el derecho a comprobar en cualquier momento la edad de los usuarios de su Página Web.
                </p>
            </div>
            <div className="py-6 space-y-2">
                <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                    ¿Cuándo recabamos sus datos personales?
                </h2>
                <p className="text-sm text-black text-opacity-60">
                    elgestionador.com recaba y trata datos de carácter personal tanto de usuarios que se hayan registrado en nuestra Página Web, como de usuarios que, no habiéndose registrado, naveguen o las consulten.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    De esta manera, elgestionador.com obtiene y utiliza los datos de la IP y demás identificadores proporcionados por el dispositivo desde el que navegue por nuestra Página Web, a la vez que recaba los Datos que usted proporcione en el momento de registrarse como «Usuario» de elgestionador.com así como aquellos Datos obtenidos como consecuencia de su navegación, solicitudes, publicaciones, conversaciones, búsquedas, alquileres, valoraciones de otros usuarios, participación en concursos y los Datos derivados, en su caso, de la vinculación de su registro en nuestra Página Web con su cuenta en Facebook o Google.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Adicionalmente, si nos da su consentimiento elgestionador.com también tratará la ubicación de su dispositivo para informarle de los productos que tiene más cerca, permitiéndole así disfrutar de nuestros servicios de manera completa.
                </p>
            </div>
            <div className="py-6 space-y-2">
                <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                    ¿Qué datos recabamos?
                </h2>
                <p className="text-sm text-black text-opacity-60">
                    elgestionador.com solamente recaba los Datos que resultan estrictamente necesarios para la consecución de las finalidades de obtención de los mismos, las cuales detallamos en el apartado ¿Con qué finalidades tratamos sus datos personales? de la presente Política de Privacidad. Es por ello que, en caso de no facilitarnos dichos Datos no podremos, gestionar el registro solicitado, prestarle el servicio solicitado, llevar a cabo la venta solicitada, facilitarle la información requerida, etc., que en cada caso corresponda.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Asimismo, le informamos que determinadas funcionalidades de nuestra Página Web utilizan la navegación considerando su ubicación para poder proporcionarle la información más relevante en cada caso, así como para ofrecerle ciertos servicios. Aunque la navegación considerando la ubicación es siempre una elección del usuario, en los casos en los que Usted la rechace la Página Web de elgestionador.com no podrán ofrecerle las funcionalidades o proporcionarle los contenidos basados en dicha ubicación.
                </p>
            </div>
            <div className="py-6 space-y-2">
                <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                    ¿Con qué finalidades y con qué base legitimadora tratamos sus datos personales?
                </h2>
                <p className="text-sm text-black font-bold ">
                    En el caso de que usted no se haya registrado en nuestra página Web, elgestionador.com tratará la IP de su dispositivo para las siguientes finalidades:
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Acceder a su ubicación mientras navega por nuestra página web para mostrarle los productos que tienes más cerca. Dicho tratamiento lo llevaremos a cabo únicamente si nos da su consentimiento para ello, mediante la aceptación de nuestra política de cookies o los ajustes de su dispositivo.
                </p>
                <p className="text-sm text-black font-bold ">
                    En el caso que usted se haya registrado en nuestra página Web, las finalidades para las cuales trataremos sus Datos de carácter personal son:
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Gestionar su alta y sus preferencias de usuario dentro del área de usuario registrado, así como para prestarle los servicios de nuestra página Web que Usted haya solicitado. La base de legitimación que habilita a elgestionador.com a tratar sus datos para la presente finalidad es su solicitud de registro.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Para velar por el buen uso de nuestros servicios y productos, impidiendo que se lleven a cabo usos ilícitos o contrarios a nuestra política y valores, pudiendo incluso darle de baja como usuario registrado. La base legitimadora de dicho tratamiento es el cumplimiento por parte de elgestionador.com de determinadas obligaciones legales, así como el interés legítimo de proteger nuestros servicios y productos.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Mostrarles publicidad ajustada a sus hábitos de consumo y navegación obtenidos del uso de nuestra página web, en atención a nuestro interés legítimo para la mejora de la oferta comercial.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Remitirle, por cualquier medio, incluidos por medios electrónicos, comunicaciones comerciales en relación con los productos y servicios ofrecidos por elgestionador.com, comunicaciones que podrán dirigirse en atención a sus hábitos de consumo, navegación o utilización de nuestra Página Web. elgestionador.com realizará dicho tratamiento en atención a la relación mantenida con usted, teniendo en cuenta que las citadas comunicaciones siempre versarán sobre nuestros productos y servicios.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Gestionar su participación en los concursos que organizamos, legitimando dicho tratamiento la aceptación por su parte de las bases legales del correspondiente concurso en el que usted participe libremente.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Analizar el contenido de los chats en los que interactúe con otros usuarios, al objeto de verificar que se hace un uso lícito de nuestros servicios. Tratamiento que realizaremos al amparo de nuestro interés legítimo para evitar fraude o actos ilícitos.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Realizar un seguimiento de la entrega de los productos y/o servicios alquilados a través de nuestra Página Web. elgestionador.com realiza dicho tratamiento como consecuencia de la aceptación por su parte de las condiciones generales de contratación realizada en el momento de solicitar dicho servicio de entrega de producto o servicios solicitados.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Gestionar las quejas y consultas sobre los productos y/o servicios adquiridos a través de nuestra Página Web y, siendo la base legitimadora de este tratamiento la obligación legal de elgestionador.com de acuerdo con la normativa de defensa de los consumidores y usuarios.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Si usted nos ha proporcionado su consentimiento expreso a tal efecto, comunicaremos sus datos a terceras empresas ajenas a elgestionador.com con la finalidad mostrarles publicidad adaptada a sus preferencias de consumo y navegación tanto de productos y servicios propios, como de terceros. Dicho tratamiento únicamente se llevará a cabo si nos da su consentimiento para ello.
                </p>
            </div>
            <div className="py-6 space-y-2">
                <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                    ¿Durante cuánto tiempo conservamos sus datos personales?
                </h2>
                <p className="text-sm text-black font-bold ">
                    En el caso de que usted no se haya registrado en nuestra página Web, el tiempo de conservación de sus datos de carácter personal por parte de elgestionador.com será el siguiente:
                </p>
                <p className="text-sm text-black text-opacity-60">
                    El tratamiento realizado de la IP o de cualquier otro identificador de su dispositivo para conocer el origen de la descarga de nuestra web se llevará a cabo únicamente en el momento en que tenga lugar dicha descarga.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Por su parte elgestionador.com tratará y conservará la ubicación de su dispositivo con las finalidades antes indicadas, mientras no deshabilite la geolocalización de su dispositivo.
                </p>
                <p className="text-sm text-black font-bold ">
                    En el caso que usted se haya registrado en nuestra página Web, los Datos que tratamos se conservarán durante los siguientes plazos:
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Los datos recabados para permitirle el registro en nuestra página web o aplicación ser conservarán mientras Usted no cancele su cuenta.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Los datos recabados para gestionar sus preferencias como usuario se conservarán mientras usted no se oponga a dicho tratamiento.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Aquellos datos recabados y tratados para velar por el buen uso de nuestros servicios y productos se conservarán hasta que se haya llevado a cabo la acción de verificación correspondiente.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Los datos recabados para mostrarle publicidad segmentada serán utilizados mientras no se oponga a dicho tratamiento.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Aquellos datos recabados para remitirle comunicaciones comerciales sobre nuestros propios servicios se conservarán mientas no se dé de baja como usuario registrado y no se oponga a dicho tratamiento.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Los datos recabados como consecuencia de su participación en los concursos realizados por elgestionador.com se conservarán hasta que finalice el concurso correspondiente.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Conservaremos los datos que Usted facilite a través de nuestro chat mientras realicemos la comprobación correspondiente sobre el uso lícito de nuestros servicios.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Los datos obtenidos como consecuencia de la contratación por su parte de los servicios de entrega se conservarán hasta que prescriba la responsabilidad civil derivada de dicho servicio.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Los datos recabados como consecuencia de la contratación por su parte de nuestros servicios de posicionamiento se conservarán hasta que se dé de baja de dicho servicio.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Aquellos datos obtenidos como consecuencia de las quejas o consultas que nos pueda dirigir se conservarán hasta que se haya resuelto la queja o consulta correspondiente.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Los datos relativos a sus hábitos de navegación o de consumo que podamos haber obtenido de terceros serán mantenidos mientras usted no revoque el consentimiento otorgado.
                </p>
                <p className="text-sm text-black font-bold ">
                    De manera general, cuando Usted se dé de baja como usuario registrado de elgestionador.com, ésta eliminará toda la información de que dispusiera de aquél. Sin embargo, elgestionador.com conservará datos meramente estadísticos de utilización por parte de los usuarios de sus recursos y herramientas.
                </p>
            </div>
            <div className="py-6 space-y-2">
                <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                    ¿Con quién compartimos sus datos personales?
                </h2>
                <p className="text-sm text-black text-opacity-60">
                    Como le hemos informado, únicamente, en caso de que nos dé su consentimiento para ello, elgestionador.com comunicará sus datos a terceras empresas ajenas a nuestra compañía.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Por otro lado, terceros situados fuera de la Unión Europea podrán acceder a sus datos personales con la única finalidad de prestarnos servicios que son necesarios para poder gestionar y ofrecerles a nuestros usuarios nuestros servicios y productos. A este respecto, le informamos que seleccionamos únicamente a los proveedores de servicios que ofrecen adecuadas garantías de protección de sus Datos, pudiéndose poner en contacto con elgestionador.com para mayor información al respecto.
                </p>
            </div>
            <div className="py-6 space-y-2">
                <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                    ¿Cuáles son sus derechos en materia de protección datos de carácter personal?
                </h2>
                <p className="text-sm text-black text-opacity-60">
                    Usted podrá ejercer sus derechos de acceso, rectificación, supresión, oposición, limitación al tratamiento y portabilidad de sus Datos, así como la revocación, en cualquier momento, de los consentimientos, en su caso, prestados ante MyGarret S.L., CIF nº B42887570, ubicado en Calle Nicaragua , 15005, A Coruña B42887570, adjuntando fotocopia de su DNI o documento identificativo válido equivalente. Para su mayor comodidad, y sin perjuicio de que se deban cumplir con determinados requisitos formales establecidos por la RGPD, MyGarret S.L. le ofrece la posibilidad de ejercer los derechos antes referidos a través del correo electrónico: datos@elgestionador.com.
                </p>
                <p className="text-sm text-black text-opacity-60">
                    Igualmente, le recordamos la posibilidad de dirigir una reclamación ante la Agencia Española de Protección de Datos, especialmente cuando considere que elgestionador.com no ha satisfecho debidamente el ejercicio de sus derechos. Dicha reclamación podrá presentarla ante la citada autoridad mediante las diferentes opciones que la sede electrónica de la Agencia Española de Protección de datos ofrece
                </p>
                <a href="https://www.aepd.es/derechos-y-deberes/conoce-tus-derechos" target="_blank" className="underline">
                    <p className="text-xs text-black text-opacity-60">
                        (https://www.aepd.es/reglamento/derechos/index.html)
                    </p>
                </a>
            </div>
        </section>
    );
}

export default Policy;
