import React from 'react';
import Button from '../components/ui/Button';

const NotFound = () => {
    return (
        <div className='flex flex-col items-center justify-center py-10 h-1/2'>
            <div className='flex flex-col gap-10 p-4 bg-white shadow rounded-xl py-10 w-[80%]'>
                <h1 className="text-3xl font-bold text-primary">¡Ups!</h1>
                <h2 className='text-xl font-bold'>Error 404</h2>
                <p>No encontramos lo que estás buscando.</p>
                <Button href="/alquiler" type='primary' classButton={'w-fit mx-auto'}>
                    Seguir alquilando
                </Button>
            </div>
        </div>
    );
};

export default NotFound;
