import React, { useRef, useState, useEffect } from "react";
import CarrouselSection from "../components/CarrouselSection";
import ProductCard from "../components/ProductCard";
import { is, DataEncoder } from "../utils/common";

export function CarrouselEssentials() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [productsApi, setProductsApi] = useState([]);
  const encoder = new DataEncoder();
  const arrayFilters = {
    filters: {
      featured: true,
      with_offers: true,
    },
    with: ["image", "products_offers_published.junior"],
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const url = `${API_BASE_URL}/products?${encoder.encode(arrayFilters)}`;
        const response = await fetch(encodeURI(url));
        if (!response.ok) {
          throw new Error("Error al cargar los productos");
        }
        const responseData = await response.json();
        setProductsApi(Object.values(responseData.data));
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchProducts();
  }, []);

  const slides = productsApi.map((product, index) => (
    <ProductCard key={product.id} product={product} isFullWidth={true} />
  ));

  return (
    <section className="space-y-2 text-black text-start py-14 md:py-24 h2">
      {slides.length > 0 && (
        <div>
          <div className="self-center px-4 mx-auto lg:px-20 w-fit">
            <h2 className="text-center">
              esenciales<span className="text-primary"> para ti</span>
            </h2>
          </div>
          <CarrouselSection
            id="2"
            slides={slides}
            slidesPerGroup={1}
            desktopSlidesPerGroup={3}
            slidesPerView={1.5}
            desktopViews={6}
            class_mobile="products_carrousel_mobile"
          />
          <div className="flex justify-center pt-9">
            <a href="/alquiler">
              <button className="flex items-center justify-center py-6 mt-2 text-black transition-all duration-150 ease-in-out bg-transparent border-2 rounded-full button px-14 border-primary hover:bg-primary hover:text-white">
                <p>ver todas</p>
              </button>
            </a>
          </div>
        </div>
      )}
    </section>
  );
}
