export function is(className, object) {
  return Object.prototype.toString.call(object) === '[object '+ className +']';
}

export function DataEncoder() {
  this.levels = [];
}

DataEncoder.prototype.__dataEncoding = function(data, actualKey = null) {
    let finalString = '';
    if (actualKey) {
        this.levels.push(actualKey);
    }

    const keys = Object.keys(data);
    const l = keys.length;
    for(let a = 0; a < l; a++) {
        const key = keys[a];
        let value = data[key];

        if (is('Object', value)) {
            finalString += this.__dataEncoding(value, key);
        } else if (is('Array', value)) {
            const arrValSize = value.length;
            for (let b = 0; b < arrValSize; b++) {
                let arrVal = value[b];
                if (actualKey) {
                    finalString += actualKey;
                    for(let c = 1; c < this.levels.length; c++) finalString += '[' + this.levels[c] + ']';
                    if (arrVal === undefined || arrVal === null) arrVal = '';
                    finalString += '[' + key + '][]=' + arrVal + '&';
                } else {
                    if (arrVal === undefined || arrVal === null) arrVal = '';
                    finalString += key + '[]=' + arrVal + '&';
                }
            }
        } else {
            if (actualKey) {
                finalString += this.levels[0];
                for(let c = 1; c < this.levels.length; c++) finalString += '[' + this.levels[c] + ']';
                if (value === undefined || value === null) value = '';
                finalString += '[' + key + ']=' + value + '&';
            } else {
                if (value === undefined || value === null) value = '';
                finalString += key + '=' + value + '&';
            }
        }
    }
    this.levels.pop();
    return finalString;
}

DataEncoder.prototype.encode = function(data) {
    if (!is('Object', data) || data === {}) return null;
    return this.__dataEncoding(data).slice(0, -1);
}