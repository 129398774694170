import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { PostsContext } from "../helpers/PostsContext";
import PostsList from "../components/PostsList";
import BlogFilters from "../components/BlogFilters";
import ImageTitle from "../assets/images/Posts/bg-blog.webp";
import GuiaFilters from "../components/GuiaFilters";

function Posts() {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState("");

  let title;
  if (selectedCategory) {
    title = `<span className="text-primary">${selectedCategory}</span>`;
  } else {
    if (location.pathname === "/guias") {
      title = 'guias<span class="text-primary">elalquilador</span>';
    } else if (location.pathname === "/blog") {
      title = `<span class="text-primary">elalquilablog</span>`;
    } else {
      title = '<span className="text-primary">elalquilablog</span>';
    }
  }

  const { filters, currentPage, updateFilters, setCurrentPage } =
    useContext(PostsContext);

  return (
    <>
      <section className="flex gap-5 px-4 text-left lg:px-20 py-14 md:py-24 lg:flex-col text-primary">
        <div>
          <div className="relative h-[50vh] w-full overflow-hidden rounded-xl bg-dark">
            <img
              src={ImageTitle}
              alt="equipo dialogando sobre sus proyectos de construcción, con un casco de seguridad en primer plano junto a una tablet, representando la colaboración en el elalquilablog"
              className="object-cover w-full h-full md:object-center"
            />
            <div className="absolute w-full space-y-4 text-center transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
              <h1
                className="flex gap-2 justify-center px-4 text-4xl font-bold lowercase md:px-12 md:text-7xl lg:px-64"
                dangerouslySetInnerHTML={{ __html: title }}
              ></h1>
            </div>
          </div>
        </div>
      </section>
      {location.pathname.includes("blog") ? (
        <BlogFilters
          initialFilters={filters}
          onFilterChange={updateFilters}
          onCategorySelect={setSelectedCategory}
        />
      ) : (
        <GuiaFilters
          initialFilters={filters}
          onFilterChange={updateFilters}
          onCategorySelect={setSelectedCategory}
        />
      )}

      <PostsList
        filters={filters}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

export default Posts;
