import { HighlightedTitle } from "../ui/HighlightedTitle";
import StepItem from "./StepItem";

function Steps({variables}) {
  const { id, title, highlightedText, steps } = variables;
  return (
    <section id={id} className="">
      <div className="container m-auto text-center px-4 lg:px-20 py-14 md:py-24 text-black h2 space-y-1">
        <h2 className="pb-12 h1 lowercase">
          <HighlightedTitle title={title} highlight={highlightedText} />
        </h2>
        {steps.map((step, index) => (
          <StepItem key={index} step={step} index={index} />
        ))}
      </div>
    </section>
  )
}

export default Steps
