import { useDataLayer } from '../context/DataLayerContext';

export const useEvent = () => {
  const { dispatch } = useDataLayer();

  const sendEvent = (event) => {
    dispatch({
      type: 'SEND_EVENT',
      event
    });

    console.log('Event sent:', event);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: event,
        event_label: event,
      });
    }
  };

  return sendEvent;
};
