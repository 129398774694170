import React from 'react';

function Star({ fill = "#FFC700" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M7.20776 0.425407L8.62326 3.45055C8.74122 3.70264 8.97714 3.82866 9.21306 3.89168L12.339 4.3959C12.9878 4.52195 13.2237 5.34122 12.7518 5.78238L10.5106 8.05128C10.3337 8.24035 10.2157 8.49241 10.2747 8.80753L10.8055 12.0217C10.9235 12.7149 10.2747 13.2191 9.74387 12.904L6.85388 11.3285C6.61796 11.2024 6.38204 11.2024 6.14612 11.3285L3.25612 12.904C2.7253 13.2191 2.07654 12.7149 2.19449 12.0217L2.72531 8.80753C2.78429 8.55543 2.66633 8.24035 2.48939 8.05128L0.248168 5.78238C-0.223669 5.34122 0.0122478 4.45892 0.661023 4.3959L3.78694 3.89168C4.02286 3.82866 4.25878 3.70264 4.37674 3.45055L5.79224 0.425407C6.14612 -0.141802 6.97184 -0.141802 7.20776 0.425407Z" fill={fill}/>
  </svg>
  )
}

export default Star