import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Inicializar como null
  const [token, setToken] = useState(localStorage.getItem("userToken"));
  const [loading, setLoading] = useState(true); // Estado de carga
  const navigate = useNavigate();

  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const isAuthenticated = !!token;
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  if (token === "null") {
    localStorage.removeItem("userToken");
    setToken(null);
  }

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        if (!token) {
          setLoading(false); // No hay token, no hay necesidad de comprobar la autenticación
          return;
        }

        const response = await fetch(`${API_BASE_URL}/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
          setIsEmailVerified(!!userData.email_verified_at);
        } else {
          setUser(null);
          setToken(null);
          localStorage.removeItem("userToken");
          navigate("/login");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };

    checkAuthentication();
  }, [token, navigate]);

  const loginAction = async (data, redirect = true) => {
    try {
      //https://elalquilador-erp.dev-lab.es/api
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        setToken(responseData.token);
        setUser(responseData.user);
        localStorage.setItem("userToken", responseData.token);
        if (redirect) {
          navigate("/");
        } else {
          return {
            success: true,
            loginToken: responseData.token,
          };
        }
      } else {
        let errorMessage;
        switch (response.status) {
          case 401:
            errorMessage = "Usuario o contraseñas incorrectos";
            break;
          case 422:
            errorMessage = "El email no tiene un formato correcto";
            break;
          case 500:
            errorMessage = "Error en el servidor";
            break;
          default:
            errorMessage = "Error en la petición";
            break;
        }
        return { success: false, message: errorMessage };
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const logoutAction = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("userToken");
    localStorage.removeItem("userData");
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        loading,
        loginAction,
        logoutAction,
        isAuthenticated,
        isEmailVerified,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
