import React, { useState, useEffect } from "react";
import { ReactComponent as Plus } from "../assets/icons/icon-plus.svg";
import { ReactComponent as Minus } from "../assets/icons/icon-minus.svg";
function CategoryItem({ category, level, parentPath }) {
  // const [isOpen, setIsOpen] = useState(parentPath.length === 1);
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const currentPath = [...parentPath, category.description];
  return (
    <div className={`border-b border-gray-200`}>
      <div
        className={`flex justify-between items-center cursor-pointer `}
        onClick={toggleOpen}
      >
        {category.categories && category.categories.length > 0 ? (
          <>
            <div
              className={`lowercase font-bold py-[5px] ${
                isOpen && "text-primary"
              }`}
            >
              {category.description}
            </div>
            <span className="pr-4">{isOpen ? <Plus /> : <Minus />}</span>
          </>
        ) : (
          <a
            className="flex justify-between items-center cursor-pointer font-bold py-[5px]"
            href={`/alquiler/${category.slug}`}
          >{`${category.description}`}</a>
        )}
      </div>
      {isOpen && category.categories && (
        <div className="pl-4 py-[6px] mb-[10px] bg-[#F4F4F4] rounded-[24px]">
          {category.categories.length > 0 && (
            <a
              className="flex justify-between items-center cursor-pointer py-[5px] font-bold"
              href={`/alquiler/${category.slug}`}
            >{`ver todo ${category.description}`}</a>
          )}
          {category.categories.map((subCategory) => (
            <CategoryItem
              key={subCategory.id}
              category={subCategory}
              level={level + 1}
              parentPath={currentPath}
            />
          ))}
        </div>
      )}
    </div>
  );
}
function MobileCollapseCategories({ allCategories }) {
  useEffect(() => {
    console.log(allCategories);
  }, [allCategories]);
  return (
    <div className="w-full max-w-md mx-auto bg-white rounded-[24px]">
      {allCategories.data.map((category) => (
        <CategoryItem
          key={category.id}
          category={category}
          level={0}
          parentPath={[]}
        />
      ))}
    </div>
  );
}
export default MobileCollapseCategories;
