import React from 'react';
import { useEffect, useState } from 'react';
import { useFilterContext } from '../../helpers/FilterContext';

const RenderCategories = ({ categories, fetchedFilters }) => {
  const [visibleLevels, setVisibleLevels] = useState({});
  const { state, dispatch } = useFilterContext();
  const toggleLevel = (path) => {
    setVisibleLevels((prevLevels) => ({
      ...prevLevels,
      [path]: !prevLevels[path],
    }));
  };

  function handleCheckBoxSpan(id) {
    dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: 'categories_ids', values: [id] } });
  }
  const renderCategories = (categories, level = 0, path = '') => {
    if (level > 4 || !categories) return null;

    return categories.map((category, index) => {
      const newPath = `${path}/${index}`;
      const hasSubcategories = category.categories && category.categories.length > 0;

      return (
        <div key={index}>
          <div
            style={{ marginLeft: `${level * 20}px`, cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            <div className='w-full' onClick={() => handleCheckBoxSpan(category.id)}>
              <span className='w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter'></span>
              <label htmlFor="">{category.description}</label>
            </div>
            
            {hasSubcategories && (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="24" height="24" style={{ marginRight: '5px' }} onClick={() => toggleLevel(newPath)}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            )}
          </div>
          {visibleLevels[newPath] && hasSubcategories && (
            <div>
              {renderCategories(category.categories, level + 1, newPath)}
            </div>
          )}
        </div>
      )
    })
  }
  return <div>{renderCategories(categories)}</div>
}

export default RenderCategories;