import React from 'react';
import FilterCategories from './filters/FilterCategories';
import FilterBrands from './filters/FilterBrands';

function FilterInputSelect({ filter, filtersContent, fetchedFilters }) {

  return (
    <div className="flex flex-col items-center py-4">
      {
        filter[0] == 'categories_ids' ?
          <FilterCategories filter={filter} filtersContent={filtersContent} fetchedFilters={fetchedFilters} />
          : null
      }
      {
        filter[0] == 'brands_ids' ?
          <FilterBrands filter={filter} filtersContent={filtersContent} fetchedFilters={fetchedFilters} />
          : null
      }
    </div>
  );
}

export default FilterInputSelect;
