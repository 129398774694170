export const HighlightedTitle = ({
  title,
  highlight,
  highlightClass = "text-primary",
}) => {
  if (!highlight) return <>{title}</>;

  const parts = title.split(new RegExp(`(${highlight})`, "gi"));

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className={highlightClass}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
};
