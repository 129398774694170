import card_icon from "../../assets/images/ClientArea/cards.png";
import user_icon from "../../assets/images/ClientArea/user.png";
import key_icon from "../../assets/images/ClientArea/key.png";
import list_icon from "../../assets/images/ClientArea/list.png";
import IconHeart from "../../assets/images/heart.svg";

function Menu({ position }) {
  return (
    <div className="flex flex-row md:flex-col gap-0 w-full md:min-w-[300px] md:max-w-[300px] overflow-scroll scrollbar-hide" id="menu_private">
      <a
        href="/perfil"
        className={`flex gap-4 py-2 px-6 md:py-4 md:px-4 items-center md:min-w-[300px] rounded-full w-fit whitespace-nowrap ${position == "account" ? "active" : ""
          }`}>
        <img className='max-w-[25px] md:max-w-none' src={user_icon} alt="" />
        <p className="text-lg md:text-2xl">mi cuenta</p>
      </a>
      <a
        href="/direcciones"
        className={`flex gap-4 py-2 px-6 md:py-4 md:px-4 items-center md:min-w-[300px] rounded-full w-fit whitespace-nowrap ${position == "addresses" ? "active" : ""
          }`}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 text-primary">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
        </svg>

        <p className="text-lg md:text-2xl">mis direcciones</p>
      </a>
      <a
        href="/pedidos"
        className={`flex gap-4 py-2 px-6 md:py-4 md:px-4 items-center md:min-w-[300px] rounded-full w-fit whitespace-nowrap ${position == "orders" ? "active" : ""
          }`}>
        <img  className='max-w-[25px] md:max-w-none' src={list_icon} alt="" />
        <p className="text-lg md:text-2xl">pedidos</p>
      </a>

      <a href="/favoritos" className={`flex gap-4 py-2 px-6 md:py-4 md:px-4 items-center md:min-w-[300px] rounded-full w-fit whitespace-nowrap ${position == 'payments' ? 'active' : ''}`}>
        <img  className='max-w-[25px] md:max-w-none' src={IconHeart} alt="" />
        <p className='text-lg md:text-2xl'>favoritos</p>
      </a>

      <a
        href="/seguridad"
        className={`flex gap-4 py-2 px-6 md:py-4 md:px-4 items-center md:min-w-[300px] rounded-full w-fit whitespace-nowrap ${position == "security" ? "active" : ""
          }`}>
        <img  className='max-w-[25px] md:max-w-none' src={key_icon} alt="" />
        <p className="text-lg md:text-2xl">seguridad</p>
      </a>
    </div>
  );
}

export default Menu;
