import React, { useState, useEffect } from "react";
import { useAuth } from "../../hooks/AuthProvider";
import card_icon from "../../assets/images/ClientArea/cards.png";
import user_icon from "../../assets/images/ClientArea/user.png";
import key_icon from "../../assets/images/ClientArea/key.png";
import list_icon from "../../assets/images/ClientArea/list.png";
import IconHeart from "../../assets/images/heart.svg";
import close_icon from "../../assets/images/ClientArea/log-out.svg";
import UserVerified from "../../components/UserVerified";

function ClientArea() {
  const { user, isEmailVerified } = useAuth();

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("session_id");
    window.location.reload();
  };

  const handlePreventNavigation = (e) => {
    if (!isEmailVerified) {
      e.preventDefault();
    }
  };

  // Render a loading message or spinner while the user data is not available
  if (!user || !user.name || !user.surname) {
    return (
      <div className="flex flex-col justify-center items-center min-h-[calc(100vh-20vh)]">
        <h2 className="mb-16 text-2xl font-bold">Cargando...</h2>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center min-h-[calc(100vh-20vh)] px-4 pt-8 pb-4 md:px-0 md:py-0">
      <h2 className="mb-16 text-2xl font-bold lowercase">
        Bienvenido {user.name} {user.surname}
      </h2>
      {!isEmailVerified && <UserVerified />}
      <div className="flex items-center justify-center gap-4 flex-row flex-wrap">
        <a
          href={isEmailVerified ? "/perfil" : undefined}
          className={`flex flex-col gap-2 justify-center items-center w-[calc(50%-1rem)] md:w-[220px] min-h-[200px] bg-white p-12 rounded-2xl ${
            !isEmailVerified ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={handlePreventNavigation}
        >
          <img src={user_icon} alt="Account Icon" />
          <span>mi cuenta</span>
        </a>
        <a
          href={isEmailVerified ? "/direcciones" : undefined}
          className={`flex flex-col gap-2 justify-center items-center w-[calc(50%-1rem)] md:w-[220px] min-h-[200px] bg-white p-12 rounded-2xl ${
            !isEmailVerified ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={handlePreventNavigation}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-9 h-9 text-primary"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          <span>mis direcciones</span>
        </a>
        <a
          href={isEmailVerified ? "/pedidos" : undefined}
          className={`flex flex-col gap-2 justify-center items-center w-[calc(50%-1rem)] md:w-[220px] min-h-[200px] bg-white p-12 rounded-2xl ${
            !isEmailVerified ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={handlePreventNavigation}
        >
          <img src={list_icon} alt="Orders Icon" />
          <span>mis pedidos</span>
        </a>
        <a
          href={isEmailVerified ? "/favoritos" : undefined}
          className={`flex flex-col gap-2 justify-center items-center w-[calc(50%-1rem)] md:w-[220px] min-h-[200px] bg-white p-12 rounded-2xl ${
            !isEmailVerified ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={handlePreventNavigation}
        >
          <img src={IconHeart} alt="Favorites Icon" />
          <span>favoritos</span>
        </a>
        <a
          href={isEmailVerified ? "/seguridad" : undefined}
          className={`flex flex-col gap-2 justify-center items-center w-[calc(50%-1rem)] md:w-[220px] min-h-[200px] bg-white p-12 rounded-2xl ${
            !isEmailVerified ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={handlePreventNavigation}
        >
          <img src={key_icon} alt="Save Icon" />
          <span>seguridad</span>
        </a>
        <div
          onClick={handleLogout}
          className="flex cursor-pointer flex-col gap-2 justify-center items-center w-[calc(50%-1rem)] md:w-[220px] min-h-[200px] bg-white p-12 rounded-2xl"
        >
          <img src={close_icon} alt="Close Icon" />
          <span>cerrar sesión</span>
        </div>
      </div>
    </div>
  );
}

export default ClientArea;
