import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <div
      className={`icon bg-light h-9 md:h-12 w-9 md:w-12 rounded-full flex justify-center items-center text-center text-primary hover:bg-primary hover:text-white transition-all ease-in-out duration-150 ${
        open === id ? "open-icon" : "close-icon"
      }`}
      onClick={() => {}}
    >
      <span className="h2">{open === id ? "-" : "+"}</span>
    </div>
  );
}

export function AccordionCustomIcon() {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <>
      <Accordion
        open={open === 1}
        icon={<Icon id={1} open={open} />}
        className="py-2"
      >
        <AccordionHeader
          onClick={() => handleOpen(1)}
          className="flex gap-9 items-center justify-between h4 font-bold text-black select-none"
        >
          ¿Debo registrarme en elalquilador.com para alquilar una máquina o
          herramienta profesional?
        </AccordionHeader>
        <AccordionBody className="accordion-content overflow-hidden transition-max-height duration-300 text-start body font-bold text-primary pb-4 leading-5">
          <p>
            Sí, en elaquilador valoramos la seguridad y eficiencia de nuestros
            clientes.
          </p>
          <p className="pt-3 text-dark">
            El registro en elalquilador.com es un paso esencial para garantizar
            un proceso de alquiler profesional y sin complicaciones. Al
            registrarte, obtendrás acceso a exclusivos beneficios, como un
            perfil personalizado, un historial de alquiler detallado y la
            posibilidad de realizar un seguimiento de tus transacciones. Además,
            la información proporcionada durante el registro nos permite
            ofrecerte un servicio individualizado y una experiencia de alquiler
            más eficiente.
          </p>
        </AccordionBody>
      </Accordion>
      <Accordion
        open={open === 2}
        icon={<Icon id={2} open={open} />}
        className="py-2"
      >
        <AccordionHeader
          onClick={() => handleOpen(2)}
          className="flex gap-9 items-center justify-between h4 font-bold text-black select-none"
        >
          ¿Qué incluye el precio cuando alquilas máquinas o herramientas
          profesionales?
        </AccordionHeader>
        <AccordionBody className="accordion-content overflow-hidden transition-max-height duration-300 text-start body font-bold text-primary pb-4 leading-5">
          <p className="pb-3">1. Precio por día.</p>
          <p className="pb-3">
            2. Fianza:{" "}
            <span className="text-dark">
              una fianza que se requiere al momento de la reserva. Esta fianza
              se utiliza como garantía y se reembolsará una vez que se devuelva
              la maquinaria o herramientas en las mismas condiciones en que se
              entregaron.
            </span>
          </p>
          <p className="pb-3">
            3. Transporte:{" "}
            <span className="text-dark">
              dependiendo de la ubicación y las necesidades de entrega, el
              precio del alquiler puede incluir el transporte de la maquinaria o
              herramienta a tu lugar de trabajo.
            </span>
          </p>
          <p className="pb-3">
            4. Uso y montaje:{" "}
            <span className="text-dark">
              en algunos casos existirá la opción de contratar el montaje o a un
              operario.
            </span>
          </p>
          <p className="pb-3">
            5. Otros gastos relacionados con la maquinaria:{" "}
            <span className="text-dark">
              seguro de responsabilidad civil y gestión de residuos obligatorios
              para garantizar el cumplimiento de las regulaciones y prácticas
              ambientales.
            </span>
          </p>
        </AccordionBody>
      </Accordion>
      <Accordion
        open={open === 3}
        icon={<Icon id={3} open={open} />}
        className="py-2"
      >
        <AccordionHeader
          onClick={() => handleOpen(3)}
          className="flex gap-9 items-center justify-between h4 font-bold text-black select-none"
        >
          ¿Quién se puede beneficiar del alquiler de maquinarias y herramientas
          profesionales?
        </AccordionHeader>
        <AccordionBody className="accordion-content overflow-hidden transition-max-height duration-300 text-start body font-bold text-primary pb-4 leading-5">
          <p className="pb-3">
            La buena noticia para nuestros usuarios es que todos se pueden
            beneficiar del alquiler de la maquinaria y herramientas: tanto
            particulares como autónomos, empresas e instituciones públicas. Los
            casos son muy variados.
          </p>
          <p className="pb-3 text-dark">
            Por ejemplo, los usuarios particulares ven en nosotros una magnífica
            oportunidad de conseguir un equipo de alta calidad, en perfecto
            estado de mantenimiento, pero a un precio insignificante comparado
            con la compra. También es muy útil para comunidades de vecinos que
            desean disminuir los gastos en tareas de mantenimiento o en
            reparación de desperfectos. Y lo mismo ocurre con ayuntamientos y
            entes públicos que quieren contar con herramienta de primera sin
            trastocar las partidas presupuestarias.
          </p>
          <p className="pb-3 text-dark">
            Los autónomos y las pequeñas empresas, por su parte, pueden recurrir
            a este servicio para reforzar la operativa en picos de demanda o
            bien para sustituir equipos cuando estos se han averiado
            inesperadamente. Y si acabas de montar tu negocio y no puedes aún
            invertir en nueva herramienta, ¿qué mejor que usarla en régimen de
            alquiler y repercutir los gastos en la factura?.
          </p>
        </AccordionBody>
      </Accordion>
    </>
  );
}
