import React from 'react';
import { useEffect, useState } from 'react';
import { is, DataEncoder } from '../../utils/common';
import RenderCategories from './RenderCategories';
import { useFilterContext } from '../../helpers/FilterContext';

function FilterCategories({ filter, filtersContent, fetchedFilters }) {
  //filter[1][1] = 'Autocomplete:brands' - Lets get content after the :
  const [allCategories, setAllCategories] = useState({});
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const { state, dispatch } = useFilterContext();
  /*
  const filterName = filter[1][1].split(':')[1];
  const filterId = filter[1][3];
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const arrayFilterObject = {
    "query": filterName,
    "search": search,
    "per_page": 5
  }
  const { state, dispatch } = useFilterContext();
  useEffect(() => {
    if( state.filtersSelected[filterId] ){
      let n = 0;
      for (let [key, value] of Object.entries(state.filtersSelected)) {
        n = value
      }
      const array = Object.entries(n)
      let contentToPush = [];
      array.map((item) => {
        contentToPush.push({
          value: Number(item[1].value),
          text: item[1].text
        })
      })
      setSelectedItems(contentToPush);
    }
    else{
      setSelectedItems([]);
      setSearch('');
      setRecords([]);
    }
  }, [state.filtersSelected]);
  */

  /*

  function handleCheckBoxSpan(selected) {
    if(selectedItems.length > 0){
      const index = selectedItems.findIndex(item => item.value === selected.value);
      let updatedSelected;
      if (index === -1) {
        updatedSelected = [...selectedItems, selected];
      } else {
        updatedSelected = [...selectedItems];
        updatedSelected.splice(index, 1);
      }
      setSelectedItems(updatedSelected);
      const idsToUpdate = updatedSelected.map(item => item.value);
      dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filterId, values: idsToUpdate } });
    }
    else{
      const updateSelected = [...selectedItems, selected];
      setSelectedItems(updateSelected);
      const idsToUpdate = updateSelected.map(item => item.value);
      dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filterId, values: idsToUpdate } });
    }
  }

  */
  useEffect(() => {
    if (fetchedFilters && fetchedFilters.categories_ids) {
      const selectedTexts = Object.values(fetchedFilters.categories_ids)
      if(selectedTexts.length > 0){
        setCategoriesSelected(selectedTexts[0].text);
      }
    }
  }, [fetchedFilters]);

  const encoder = new DataEncoder();
  const fetchAllCategories = async () => {
    const params = {
      "with": [
        "categories.categories.categories"
      ],
      "filters": {
          "level": 1
      }
    }
    try {
      const response = await fetch(`${API_BASE_URL}/categories?${encoder.encode(params)}`);
      if (!response.ok) {
        throw new Error('Error al cargar las categorias');
      }
      const responseData = await response.json();
      setAllCategories(responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  useEffect(() => {
    fetchAllCategories();
  }, []);
  
  function deleteCategorieId() {
    dispatch({ type: 'REMOVE_FILTER', payload: 'categories_ids' });
  }


  return (
    <div className="flex flex-col items-center py-4">
      {
        fetchedFilters &&
        fetchedFilters.categories_ids &&
        <div className='w-full pb-8'>
          <div className='w-full'>
            <span className='w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter checked' onClick={deleteCategorieId}></span>
            <label htmlFor="">{categoriesSelected}</label>
          </div>
        </div>
      }
      <RenderCategories categories={allCategories.data} fetchedFilters={fetchedFilters} />
    </div>
  );
}

export default FilterCategories;
