import React, { useEffect, useState } from "react";
import Menu from "../../components/private/Menu";
import Modal2 from "../../components/Modal2";
import { useAuth } from "../../hooks/AuthProvider";
import { is, DataEncoder } from "../../utils/common";
import { formatCurrency } from '../../utils/formatters';








function Orders() {
	const { token, isAuthenticated } = useAuth();
	const [orders, setOrders] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const encoder = new DataEncoder();
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

	useEffect(() => {
		const fetchSalesOrders = async () => {
			try {
				const arrayParameters = {
					filters: {},
					with: ["thirdparty", "sales_orders_lines.product.brand", "state"],
					order: [],
					page: 1,
					per_page: 10,
				};

				const response = await fetch(
					`${API_BASE_URL}/sales-orders?${encoder.encode(arrayParameters)}`,
					{
						method: "GET",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				const salesOrdersData = await response.json();
				if (!response.ok) {
						const errorMessages = Object.values(salesOrdersData.errors)
						.flat()
						.join(', ');
						throw new Error(errorMessages);
				}
				const ordersArray = Object.values(salesOrdersData.data);
				// console.log("Datos de los pedidos:", ordersArray);
				setOrders(ordersArray);
			} catch (error) {
				console.error("Error:", error.message);
			}
		};

		if (isAuthenticated) {
			fetchSalesOrders();
		}
	}, [isAuthenticated, token]);

	const openModal = (order) => {
		setSelectedOrder(order);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setSelectedOrder(null);
		setIsModalOpen(false);
	};

	return (
		<div className="p-[15px] md:p-[35px] flex flex-col md:flex-row gap-8 md:gap-24 min-h-[calc(100vh-135px)]">
			<Menu position="orders" />
			<div className="w-full">
				<h2 className="text-left text-[34px] text-primary font-bold mb-2">
					mis pedidos
				</h2>
				<div className="flex flex-col items-start justify-start w-full h-full p-8 bg-white rounded-xl">
					<div className="w-full text-left">
						{/* <h3 className="text-lg font-bold">Pedidos en curso</h3>
						<p className="">
							Actualmente no tienes ningún pedido en curso
						</p> */}
						{/* <a href="/alquiler" className="flex items-center justify-center gap-2 px-24 py-4 mt-4 text-white transition-all duration-150 ease-in-out rounded-full bg-primary button w-fit stroke-white hover:bg-opacity-90">
							Empieza a alquilar
						</a> */}
						{/* <h3 className="mt-8 text-lg font-bold">Historial de pedidos</h3> */}
						{orders.length === 0 ? (
							<p className="">
								Todavía no has realizado ningún pedido
							</p>
						) : (
							<div className="w-full overflow-x-auto">
								<table className="min-w-full bg-white border-collapse rounded-lg shadow-md">
									<thead className="">
										<tr className="bg-primary">
											<th className="px-4 py-2 text-center text-white">Número</th>
											<th className="px-4 py-2 text-center text-white">F. Pedido</th>
											<th className="px-4 py-2 text-center text-white">Estado</th>
											<th className="px-4 py-2 text-center text-white">Productos</th>
											<th className="px-4 py-2 text-center text-white">Fianza</th>
											<th className="px-4 py-2 text-center text-white">Base</th>
											<th className="px-4 py-2 text-center text-white">Impuestos</th>
											<th className="px-4 py-2 text-center text-white">Total</th>
											<th className="px-4 py-2 text-center text-white"></th>
										</tr>
									</thead>
									<tbody className="">
										{orders.map((order) => (
											<tr key={order.id} className="odd:bg-primary-50 even:bg-white">
												<td className="px-4 py-2 text-center">
													{order.number}
												</td>
												<td className="px-4 py-2 text-center">
													{order.date}
												</td>
												<td className="px-4 py-2 text-center">
													{order.state?.description}
												</td>
												<td className="px-4 py-2 text-center">
													{order.products_count}
												</td>
												<td className="px-4 py-2 text-center">
													{order.base_bail}
												</td>
												<td className="px-4 py-2 text-right">
													{formatCurrency(order.base_line)}
												</td>
												<td className="px-4 py-2 text-right">
													{formatCurrency(order.tax_line)}
												</td>
												<td className="px-4 py-2 text-right">
													{formatCurrency(order.total_line)}
												</td>
												<td className="px-4 py-2 text-right">
													<button onClick={() => openModal(order)} className="font-bold text-primary-600 hover:text-primary-dark">
														Ver
													</button>

												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
					</div>
				</div >
			</div >
			{selectedOrder && (
				<Modal2 isOpen={isModalOpen} onClose={closeModal} widthModal='max-w-5xl'>
					<Modal2.Header>
						<h2>Detalle del pedido <span className="font-bold">{selectedOrder.number}</span></h2>
					</Modal2.Header>
					<Modal2.Body>
						<div className="flex flex-col w-full">
							<div className="flex gap-4">
								<p><strong>Fecha de pedido:</strong> {selectedOrder.date}</p>
								<p><strong>Estado:</strong> {selectedOrder.state?.description}</p>
							</div>
							{/* <div className="border-b border-primary border-1 my-4"></div> */}
							<div className="my-14">
								<div className="flex flex-col gap-2">
									<div className="flex py-2 text-white rounded-t-xl bg-primary">
										<div className="w-[40%] px-4 text-center">Producto</div>
										<div className="w-[10%] px-4 text-center">Unid.</div>
										<div className="w-[20%] px-4 text-center">Marca</div>
										<div className="w-[10%] px-4 text-center">Base</div>
										<div className="w-[10%] px-4 text-center">IVA</div>
										<div className="w-[10%] px-4 text-center">Total</div>
									</div>
								</div>
								<div className="flex flex-col w-full gap-1">
									{selectedOrder.sales_orders_lines.map((line) => (
										<div key={line.id} className={`flex w-full items-center cursor-pointer  py-2 last:rounded-b-xl hover:bg-gray-300 ${line.related_line_id ? 'bg-gray-200' : 'bg-gray-400'}`} >
											<div className="w-[40%] px-4">
												{line.product.short_name}
											</div>
											<div className="w-[10%] px-4 text-center">
												{line.units}
											</div>
											<div className="w-[20%] px-4 text-center">
												{line.product.brand && (
													<div>{line.product.brand.brand}</div>
												)}
											</div>
											<div className="w-[10%] px-4 text-right">
												<div>{formatCurrency(line.base_line)}</div>
											</div>
											<div className="w-[10%] px-4 text-right">
												<div>{formatCurrency(line.tax_line)}</div>
											</div>
											<div className="w-[10%] px-4 text-right">
												<div>{formatCurrency(line.total_line)}</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>

					</Modal2.Body>
					<Modal2.Footer>
						<div className="border-t border-primary border-1 my-4"></div>
						<div className="flex gap-10 justify-end">
							<div className="flex gap-3 text-xl">
								<div className="text-primary">Base</div>
								<div className="font-bold">
									{formatCurrency(selectedOrder.base_line)}
								</div>
							</div>
							<div className="flex gap-3 text-xl">
								<div className="text-primary">IVA</div>
								<div className="font-bold">
									{formatCurrency(selectedOrder.tax_line)}
								</div>
							</div>
							<div className="flex gap-3 text-xl">
								<div className="text-primary">Total</div>
								<div className="font-bold">
									{formatCurrency(selectedOrder.total_line)}
								</div>
							</div>
						</div>
					</Modal2.Footer>
				</Modal2>
			)
			}
		</div >
	);
}
export default Orders;
