import React from 'react';

const InformationIcon = ({ width = "18", height = "18", color = "#FF7A00" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M9 5V9.79998M9 12.6364V12.2M17 9C17 13.4364 13.4364 17 9 17C4.56364 17 1 13.4364 1 9C1 4.56364 4.56364 1 9 1C13.4364 1 17 4.56364 17 9Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default InformationIcon;