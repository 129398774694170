import React, { useState } from "react";

import ImageWithText from "../components/sections/ImageWithText";
import ImageWithTextImage from "../../src/assets/images/Experts/hero-experts.webp";
import ExpertsIntroImage from "../../src/assets/images/Experts/experts-intro.webp";
import ExpertsEndImage from "../../src/assets/images/Experts/experts-end.webp";
import ScrollToHash from "../components/ScrollToHash";

const ImageWithTextVariables = {
  title: "escribe a nuestro experto",
  highlightedText: "experto",
  subtitle:
    "queremos que la experiencia de nuestros clientes sea la mejor posible. Todos tus comentarios nos ayudan a mejorar. Si deseas más información acerca del alquiler o tienes alguna consulta no dudes en rellenar el siguiente formulario.",
  subtitle_2: "",
  buttonText: "Contacta",
  href: "#form",
  image: ImageWithTextImage,
  alt: "Sobre elalquilador",
};

function Expert() {
  return (
    <>
      <ScrollToHash targetHashes={['#form']} />
      <ImageWithText variables={ImageWithTextVariables} />
      <section className="text-start py-14">
        <div className="container m-auto px-4 lg:px-20 space-y-14 md:space-y-24">
          <div className="w-full md:flex items-center gap-12">
            <div className="h-full w-full md:w-7/12">
              <h2 className="pt-9 h2 text-black">
                Nuestro equipo profesional,
                <span className="text-primary lowercase">
                  {" "}
                  a tu disposición
                </span>
              </h2>
              <p className="py-4 body text-dark leading-5">
                En elalquilador nuestra prioridad eres tú. Contamos con un
                equipo con experiencia en el sector que comprenderá tus dudas y
                podrá ayudarte de forma totalmente personalizada. Queremos
                acompañarte en tu negocio proporcionándote soluciones en todo lo
                relacionado con el alquiler de maquinaria y herramientas.
              </p>
            </div>
            <img
              src={ExpertsIntroImage}
              className="rounded-3xl h-full w-full md:w-5/12 object-contain"
              alt="atención al cliente del alquilador respondiendo consultas sobre el alquiler de maquinaria a través de WhatsApp"
            />
          </div>
        </div>
      </section>
      <RegistrationForm />
      <section className="text-start py-14">
        <div className="container m-auto px-4 lg:px-20 space-y-14 md:space-y-24">
          <div className="w-full md:flex items-center gap-12">
            <img
              src={ExpertsEndImage}
              className="rounded-3xl h-full w-full md:w-5/12 object-contain"
              alt="Persona usando un teléfono móvil y un ordenador portátil, simbolizando la comunicación digital y el soporte en línea"
            />
            <div className="h-full w-full md:w-7/12">
              <h2 className="pt-9 h2 text-black">
                Si
                <span className="text-primary lowercase">
                  {" "}
                  eres un profesional{" "}
                </span>
                en tu sector, comprendemos tus necesidades. Y si no lo eres,
                nuestros expertos están listos para brindarte
                <span className="text-primary lowercase"> asesoramiento </span>
              </h2>
              <p className="py-4 body text-dark leading-5">
                Encuentra todo lo que necesitas y, si tienes alguna pregunta,
                nuestros expertos están disponibles para ayudarte a través del
                chat en línea. ¡Estamos aquí para asistirte en lo que necesites!
              </p>
              <a href="#" className="flex w-full justify-start items-center">
                {" "}
                {/* Adjusted href */}
                <button className="w-[80vw] md:w-80 bg-primary button flex items-center justify-center text-white rounded-full px-2 py-6 border-2 border-primary hover:bg-transparent hover:text-primary transition-all ease-in-out duration-150">
                  <p>Solicitar asistencia</p>
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function RegistrationForm() {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    surname: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <section id="form" className="px-4 lg:px-20 py-14 md:py-24">
      <div className="bg-white mb-12 container m-auto rounded-3xl px-4 py-12 lg:p-12">
        <h2 className="h1 lowercase flex justify-center pb-12">
          <span className="text-primary pr-2">Regístrate </span>y únete a
          nuestra comunidad
        </h2>
        <form className="lg:px-32" onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              className="w-full appearance-none rounded-full border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
              id="email"
              type="email"
              name="email"
              placeholder="Correo electrónico*"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4 lg:flex gap-6">
            <input
              className="mb-4 lg:mb-0 w-full md:w-1/2 appearance-none rounded-full border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
              id="name"
              type="text"
              name="name"
              placeholder="Nombre*"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              className="w-full md:w-1/2 appearance-none rounded-full border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
              id="surname"
              type="text"
              name="surname"
              placeholder="Apellidos*"
              value={formData.surname}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4 lg:flex gap-6">
            <textarea
              className="resize-both w-full h-[20vh] px-6 py-3 rounded-2xl border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
              id="message"
              name="message"
              placeholder="Introduzca su mensaje"
              required
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="flex justify-center pt-2 lg:pt-9">
            <button
              type="submit"
              className="bg-primary button flex items-center justify-center text-white rounded-full px-14 py-6 border-2 border-primary hover:bg-transparent hover:border-primary hover:text-dark transition-all ease-in-out duration-150"
            >
              Empieza hoy mismo
            </button>
          </div>
          {/* Legal */}
          <div className="w-full px-12 pt-4 text-center text-sm text-gray-300 mt-12">
            <p>
              Al continuar confirmas que aceptas nuestros
              <a href="#section1" target="_blank" className="underline">
                Términos y condiciones,
              </a>
              <a href="#section1" target="_blank" className="underline">
                Política de Privacidad
              </a>{" "}
              y
              <a href="#section1" target="_blank" className="underline">
                Cookies
              </a>
              .
            </p>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Expert;
