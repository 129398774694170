import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

const BlogFilters = ({
  initialFilters = {},
  onFilterChange,
  onCategorySelect,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [searchInput, setSearchInput] = useState(initialFilters.search || "");
  const [categorySelected, setCategorySelected] = useState(null);
  const [categories, setCategories] = useState([]);
  const [filterValues, setFilterValues] = useState({
    search: initialFilters.search || "",
    category_ids: initialFilters.category_ids || null,
  });
  const navigate = useNavigate();
  const { slug } = useParams();

  const menuRef = useRef(null);
  const svgRef = useRef(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !svgRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, svgRef]);

  useEffect(() => {
    const fetchCategories = async () => {
      const params = new URLSearchParams({
        query: "posts_categories",
        per_page: 100,
        parameter1: "alquilador",
        parameter2: "guide",
      });

      const response = await fetch(
        `${API_BASE_URL}/autocomplete?${params.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.records) {
        const categoriesArray = Object.values(data.records);
        setCategories(categoriesArray);
        if (initialFilters.category_ids) {
          const selectedCategory = categoriesArray.find(
            (cat) => cat.value === initialFilters.category_ids
          );
          setCategorySelected(selectedCategory);
        }
      }
    };

    fetchCategories();
  }, [initialFilters.category_ids]);

  useEffect(() => {
    if (slug && categories.length > 0) {
      const category = categories.find(
        (cat) => cat.data.slug === slug.replace("de-", "")
      );
      if (category) {
        setCategorySelected(category);
        const newFilterValues = {
          ...filterValues,
          category_ids: category.value,
        };
        setFilterValues(newFilterValues);
        onFilterChange(newFilterValues);
        onCategorySelect(category.text);
      } else {
        navigate(`/guias`);
      }
    }
  }, [slug, categories]);

  const selectCategory = (cat) => {
    navigate(`/guias/de-${cat.data.slug}`);
    onCategorySelect(cat.text);
    // setCategorySelected(category);
    // const newFilterValues = { ...filterValues, category_ids: id };
    // console.log(newFilterValues);
    // setFilterValues(newFilterValues);
    // onFilterChange(newFilterValues);
    // setShowMenu(false);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchBlog = () => {
    const newFilterValues = { ...filterValues, search: searchInput };
    setFilterValues(newFilterValues);
    onFilterChange(newFilterValues);
  };

  const clearSearch = () => {
    const newFilterValues = { ...filterValues, search: "" };
    setSearchInput("");
    setFilterValues(newFilterValues);
    onFilterChange(newFilterValues);
    // navigate(`/blog`)
  };

  const clearCategory = () => {
    onCategorySelect("guias elalquilador");
    navigate(`/guias`);
    const newFilterValues = { ...filterValues, category_ids: null };
    setCategorySelected(null);
    setFilterValues(newFilterValues);
    onFilterChange(newFilterValues);
  };

  return (
    <section className="bg-white">
      <div className="container items-center justify-between gap-12 px-4 py-3 m-auto md:flex">
        <div className="flex gap-0 md:gap-5">
          <div className="relative z-[9] flex items-center justify-between gap-2 w-full rounded-full bg-primary px-6 py-2 text-white">
            <p>Filtrar por Categoria</p>
            <svg
              ref={svgRef}
              className="cursor-pointer fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              onClick={() => setShowMenu(!showMenu)}
            >
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
              <path fill="none" d="M0 0h24v24H0z" />
            </svg>

            {showMenu && (
              <div
                ref={menuRef}
                id="filterDropdown"
                className="absolute left-0 top-12 z-[999999] w-full md:w-96 rounded-xl bg-white px-9 py-6 text-start border-gray border shadow-xl"
              >
                <ul className="space-y-2 text-base font-medium lowercase text-secondary">
                  {categories.map((cat) => (
                    <li
                      key={cat.value}
                      className="transition-all duration-300 ease-in-out cursor-pointer hover:text-primary"
                      onClick={() => selectCategory(cat)}
                    >
                      <div>{cat.text}</div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="flex gap-4">
            {filterValues.search && (
              <div className="flex items-center gap-3 px-6 py-2 text-white rounded-full bg-primary">
                <span>{filterValues.search}</span>
                <div className="cursor-pointer" onClick={clearSearch}>
                  x
                </div>
              </div>
            )}
            {categorySelected && (
              <div className="flex items-center gap-3 w-max px-6 py-2 text-white rounded-full bg-primary">
                <span>{categorySelected.text}</span>
                <div className="cursor-pointer" onClick={clearCategory}>
                  x
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="relative mt-2 flex min-w-[40vh] items-center gap-2 rounded-full border-2 border-dark pl-2 pr-[2rem] text-white md:mt-0">
          <input
            type="text"
            name="q"
            placeholder="Buscar artículo"
            value={searchInput}
            onChange={handleSearchChange}
            className="appearance-none border-0 bg-transparent placeholder:text-primary font-bold text-primary outline-none ring-0 focus:border-0 focus:outline-none focus:ring-0 py-[.5rem] px-[.75rem]"
          />
          <button
            type="submit"
            className="absolute flex items-center gap-2 px-4 py-1 rounded-full right-1 bg-primary"
            onClick={handleSearchBlog}
          >
            <svg
              className="w-6 h-6 fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="100"
              height="100"
            >
              <path
                d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                strokeWidth="1.5"
              />
            </svg>
            <p>Buscar</p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default BlogFilters;
