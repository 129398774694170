import React from 'react';
import{ useEffect, useState } from 'react';
import {is, DataEncoder} from '../utils/common';
import { useFilterContext } from '../helpers/FilterContext';
import { set } from 'date-fns';

function FilterInputBoolean({ filter, filtersContent }) {
  const { state, dispatch } = useFilterContext();
  const filterName = filter[0]
  const filterId = filter[1][3];
  const [boolean, setBoolean] = useState(false);
  function handleCheckClick(event){
    event.target.classList.toggle('checked');
    event.target.nextSibling.click();
    setBoolean(!boolean);
  }
  useEffect(() => {
    if(boolean){
      dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filterId, values: filter[1][1] } });
    }
    else{
      dispatch({ type: 'REMOVE_FILTER', payload: filterId })
    }
  }, [boolean]);
  
  return (
      <div className="flex items-center py-4 flex-col">
        <div className='w-full flex flex-row flex-wrap gap-x-4 mt-4'>
          <div className='w-[calc(50%-10px)]'>
            <span className='w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter' onClick={handleCheckClick}></span>
            <input type="checkbox" className='hidden' name="" id=""/>
            <label htmlFor="">{filter[1][2]}</label>
          </div>
        </div>
      </div>
  );
}

export default FilterInputBoolean;
