import React, { useState } from "react";

import Video from "../components/sections/Video";
import VideoSrc from "../assets/video/video-alquilador-pasos.mp4";

const VideoVariables = {
  title:
    "¿Cómo alquilo maquinaria y herramientas profesionales en elalquilador.com?",
  highlightedText: "elalquilador.com",
  remove_width_large: true,
  h1: "Videotutoriales",
};

function Videotutorials() {
  return (
    <>
      <section className="text-start py-14">
        <div className="container px-4 m-auto lg:px-20 space-y-14 md:space-y-24">
          <Video variables={VideoVariables} videoSrc={VideoSrc} />
        </div>
      </section>
    </>
  );
}

function RegistrationForm() {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    surname: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <section id="form" className="px-4 lg:px-20 py-14 md:py-24">
      <div className="container px-4 py-12 m-auto mb-12 bg-white rounded-3xl lg:p-12">
        <h2 className="flex justify-center pb-12 lowercase h1">
          <span className="pr-2 text-primary">Regístrate </span>y únete a
          nuestra comunidad
        </h2>
        <form className="lg:px-32" onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              className="w-full px-4 py-3 text-sm font-bold bg-transparent border-2 rounded-full appearance-none border-dark text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
              id="email"
              type="email"
              name="email"
              placeholder="Correo electrónico*"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="gap-6 mb-4 lg:flex">
            <input
              className="w-full px-4 py-3 mb-4 text-sm font-bold bg-transparent border-2 rounded-full appearance-none lg:mb-0 md:w-1/2 border-dark text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
              id="name"
              type="text"
              name="name"
              placeholder="Nombre*"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              className="w-full px-4 py-3 text-sm font-bold bg-transparent border-2 rounded-full appearance-none md:w-1/2 border-dark text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
              id="surname"
              type="text"
              name="surname"
              placeholder="Apellidos*"
              value={formData.surname}
              onChange={handleChange}
            />
          </div>
          <div className="gap-6 mb-4 lg:flex">
            <textarea
              className="resize-both w-full h-[20vh] px-6 py-3 rounded-2xl border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
              id="message"
              name="message"
              placeholder="Introduzca su mensaje"
              required
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="flex justify-center pt-2 lg:pt-9">
            <button
              type="submit"
              className="flex items-center justify-center py-6 text-white transition-all duration-150 ease-in-out border-2 rounded-full bg-primary button px-14 border-primary hover:bg-transparent hover:border-primary hover:text-dark"
            >
              Empieza hoy mismo
            </button>
          </div>
          {/* Legal */}
          <div className="w-full px-12 pt-4 mt-12 text-sm text-center text-gray-300">
            <p>
              Al continuar confirmas que aceptas nuestros
              <a href="#section1" target="_blank" className="underline">
                Términos y condiciones,
              </a>
              <a href="#section1" target="_blank" className="underline">
                Política de Privacidad
              </a>{" "}
              y
              <a href="#section1" target="_blank" className="underline">
                Cookies
              </a>
              .
            </p>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Videotutorials;
