import React, { useState, useEffect } from "react";
import CarrouselSection from "../components/CarrouselSection";
import Button from "../components/ui/Button";

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;

export function CarrouselBrands() {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const urlParams = [
    ["filters[home_carrousel]", "1"],
    ["with[0]", "image"],
  ];

  const paramString = urlParams
    .map(([param, value]) => `${param}=${value}`)
    .join("&");

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const url = `${API_BASE_URL}/brands?${paramString}`;
        const response = await fetch(encodeURI(url));

        if (!response.ok) {
          throw new Error("Error al cargar el producto");
        }
        const responseData = await response.json();
        if (
          typeof responseData.data === "object" &&
          responseData.data !== null
        ) {
          const brandIds = Object.keys(responseData.data);
          const filteredBrands = brandIds.reduce((filtered, brandId) => {
            const brand = responseData.data[brandId];
            if (brand.home_carrousel === 1 && brand.image && brand.image.path) {
              filtered.push(brand);
            }
            return filtered;
          }, []);
          setBrands(filteredBrands);
        } else {
          throw new Error("Los datos recibidos no son válidos");
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [paramString]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  const slides = brands.map((brand, index) => (
    <div key={index} className="flex-shrink-0">
      <div className="space-y-6">
        <a
          href={`/productos-marca/${brand.slug}`}
          key={index}
          className="flex-shrink-0"
        >
          <img
            src={IMAGE_BASE_URL + brand.image.path}
            className="object-cover transition-all duration-300 ease-in-out bg-white rounded-full w-44 h-44 hover:scale-105"
            alt={`${brand.brand}`}
          />
          <h3 className="mt-5 flex justify-center mx-auto text-base text-center w-44 lowercase">
            {brand.brand}
          </h3>
        </a>
      </div>
    </div>
  ));
  return (
    <section className="space-y-2 text-black text-start py-14 md:py-24 h2">
      <div>
        <div className="relative px-4 text-center lg:px-20">
          <h2>
            <span className="text-start text-primary">las mejores marcas</span>{" "}
            para ti
          </h2>
        </div>
        <CarrouselSection
          id="3"
          slides={slides}
          slidesPerGroup={1}
          desktopSlidesPerGroup={3}
          slidesPerView={2}
          desktopViews={6}
          class_mobile="brands_carrousel_mobile"
        />
        {/* <div className="flex justify-center pt-9">

          <Button href="/alquiler" type="primary">
            <p>Ver todas</p>
          </Button>

        </div> */}
      </div>
    </section>
  );
}
