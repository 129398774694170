import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function BreadcrumbBlog({ isPostList = false }) {
  const [breadcrumbCategory, setBreadcrumbCategory] = useState("");
  const [breadcrumbCategoryPath, setBreadcrumbCategoryPath] = useState("");
  const [breadcrumbPost, setBreadcrumbPost] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (!isPostList) {
      const category = localStorage.getItem("breadcrumbCategoryName") || "";
      const categoryPath = localStorage.getItem("breadcrumbCategoryPath") || "/";
      const postTitle = localStorage.getItem("breadcrumbPost") || "";

      setBreadcrumbCategory(category);
      setBreadcrumbCategoryPath(categoryPath);
      setBreadcrumbPost(postTitle);
    }
  }, [isPostList]);

  const getBreadcrumbType = () => {
    return location.pathname.includes("/blog") ? "Blog" : "Guía";
  };

  return (
    <nav aria-label="breadcrumb">
      <ul className="flex items-center gap-1 text-sm text-dark opacity-60 lowercase">
        <li>
          <Link to="/" className="hover:underline">Home</Link>
        </li>
        <li>/</li>
        <li>
          <Link to={`/${getBreadcrumbType().toLowerCase()}`} className="hover:underline">
            {getBreadcrumbType()}
          </Link>
        </li>
        {!isPostList && breadcrumbCategory && (
          <>
            <li>/</li>
            <li>
              <Link to={breadcrumbCategoryPath} className="hover:underline">
                {breadcrumbCategory}
              </Link>
            </li>
          </>
        )}
        {!isPostList && (
          <>
            <li>/</li>
            <li>{breadcrumbPost}</li>
          </>
        )}
      </ul>
    </nav>
  );
}

export default BreadcrumbBlog;
