import React, { createContext, useContext, useState } from 'react';

const ErrorContext = createContext();

export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
    const [fetchErrors, setFetchErrors] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const setError = async (error) => {
        if (error instanceof Response) {
            try {
                const errorBody = await error.json();
                if (errorBody.errors) {
                    const errorMessages = Object.values(errorBody.errors).map((messages) => messages.join(', ')).join('; ');
                    setFetchErrors(errorMessages);
                    console.log(errorMessages);
                    openModal();
                } else {
                    setFetchErrors('Error desconocido');
                    openModal();
                }
            } catch (e) {
                console.error("Error al parsear el cuerpo del error", e);
            }
        } else {
            setFetchErrors(error.toString());
            console.log(error);
            if (error && error.message) {
                openModal();
            }
        }
    };

    return (
        <ErrorContext.Provider value={{ fetchErrors, setError, isModalOpen, closeModal }}>
            {children}
        </ErrorContext.Provider>
    );
};
