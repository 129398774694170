import React from 'react';

function Breadcrumbs({ category, categoryPath, product }) {
  return (
    <nav aria-label="breadcrumb">
      <ul className="ml-8 pt-4 flex items-center gap-1">
        <li>
          <a href="/" className="text-darkgray hover:underline transition-all ease-in-out duration-150">
            Home
          </a>
        </li>
        {category && (
          <>
            <li aria-hidden="true">/</li>
            <li>
              <a
                href={categoryPath}
                className={`hover:underline transition-all ease-in-out duration-150 ${
                  !product ? 'font-bold text-dark' : 'text-darkgray'
                }`}
              >
                {category}
              </a>
            </li>
          </>
        )}
        {product && (
          <>
            <li aria-hidden="true">/</li>
            <li className="text-dark font-bold">
              {product}
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

export default Breadcrumbs;
