import React from 'react';
import { Input } from '@material-tailwind/react';

function FilterInputText({ filter, handleSearchInputChange }) {
  return (
      <div className="flex items-center py-4">
        <input type="text" onChange={handleSearchInputChange} className='w-full bg-transparent border border-gray p-2 rounded-md'/>
      </div>
  );
}

export default FilterInputText;