import React, { useState, useEffect } from 'react';
import { useFilterContext } from '../helpers/FilterContext';
import FilterItem from './FilterItem';

const Filters2 = ({filters, fetchedFilters, filtersContent, whereTopBar, keywords}) => {
  const { state, dispatch } = useFilterContext();

  const updateKeyword = (keyword) => {
    // dispatch({ type: 'UPDATE_KEYWORD', payload: keyword });
    console.log(keyword)
  }
  return (
    <div>
      <div className="pt-4 pb-[400px]">
      {
        filters.filters && Object.entries(filters.filters).map((filter, index) => 
          filter[1][0] != 'search'
            ? <FilterItem key={index} filtersContent={filtersContent} filter={filter} fetchedFilters={fetchedFilters} whereTopBar={whereTopBar}/>
            : null
        )
      }
      <div className='flex flex-wrap gap-4 pt-4'>
        {
          keywords && (
            keywords.map((keyword, index) => {
              return (
                <div key={index} className="flex space-x-2" onClick={() => updateKeyword(keyword)}>
                  <span className="text-primary underline text-left">{keyword.keyword}</span>
                </div>
              )
            })
          )
        }
      </div>
      </div>
    </div>
  );
};

export default Filters2;