import elmundo from "../../assets/images/Press/el-mundo.webp";
import abc from "../../assets/images/Press/abc.webp";
import larazon from "../../assets/images/Press/la-razon.webp";
import europapress from "../../assets/images/Press/europapress.webp";
import okdiario from "../../assets/images/Press/okdiario.webp";
import elperiodico from "../../assets/images/Press/elperiodico.webp";
import Image from "../ui/Image";
import { HighlightedTitle } from "../ui/HighlightedTitle";

function BrandsGrid() {
  const brands = [
    {
      src: elmundo,
      alt: "El Mundo",
      link: "https://www.elmundo.es/economia/dinero-inversion/2024/04/05/661022f3e85ece573d8b45ab.html",
    },
    {
      src: abc,
      alt: "Diario ABC",
      link: "https://www.abc.es/economia/plataforma-elalquiladorcom-lanza-elgestionador-software-empresas-alquiladoras-20240416154841-nt_amp.html",
    },
    {
      src: larazon,
      alt: "La Razón",
      link: "https://www.larazon.es/economia/alquilar-andamio-tabla-surf-esta-plataforma-donde-podra-hacerlo_202312256589295ad7b0c30001a829d2.html",
    },
    {
      src: europapress,
      alt: "Europa Press",
      link: "https://www.europapress.es/epsocial/sostenibilidad/noticia-plataforma-elalquilador-nace-empresas-particulares-utilicen-productos-forma-mas-sostenible-20231011135957.html",
    },
    {
      src: okdiario,
      alt: "Ok Diario",
      link: "https://okdiario.com/economia/como-reducir-tus-costes-alquilando-productos-12357448",
    },
    {
      src: elperiodico,
      alt: "El Periodico",
      link: "https://www.elperiodico.com/es/sociedad/20240213/elalquilador-com-solucion-obtener-herramienta-98136078",
    },
  ];

  return (
    <section id="section_talk">
      <div className="container px-4 m-auto space-y-1 text-center text-black lg:px-20 py-14 md:py-24 h2">
        <h2 className="lowercase h1 pb-9">
          <HighlightedTitle title="Hablan de nosotros" highlight="Hablan" />
        </h2>
        <div className="grid w-full grid-cols-2 lg:grid-cols-3">
          {brands.map((brand, index) => (
            <div key={index} className="flex items-center justify-center pb-2">
              <a href={brand.link}>
                <Image
                  src={brand.src}
                  alt={brand.alt}
                  customClass="w-full h-full object-cover"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default BrandsGrid;
