import { DataEncoder } from "../utils/common";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchAutocompleteFilters = async (text, queryType) => {
    const autoCompleteQuery = {
        query: queryType,
        search: text,
        per_page: 15
    };
    const encoder = new DataEncoder();
    try {
        const response = await fetch(`${API_BASE_URL}/autocomplete?${encoder.encode(autoCompleteQuery)}`);
        if (!response.ok) {
            throw new Error('Error al cargar los filtros');
        }
        const responseData = await response.json();
        return Object.keys(responseData.records).map(key => responseData.records[key]);
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};