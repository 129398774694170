import React from 'react';

function FilterInputRadio({ filter, globalFilters }) {

  return (
    <div className="flex items-center gap-2 cursor-pointer">
      Radios
    </div>
  );
}

export default FilterInputRadio;