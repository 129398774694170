import React from 'react';
import Errors from './Errors';
import { useError } from '../helpers/ErrorContext';

const ErrorModal = () => {
  const { fetchErrors, isModalOpen, closeModal } = useError();

  return (
    <Errors isOpen={isModalOpen} onClose={closeModal} widthModal='max-w-3xl' errors={fetchErrors}>
      <Errors.Body></Errors.Body>
    </Errors>
  );
};

export default ErrorModal;
