import React, { useEffect, useState } from 'react';
import { useCartDrawer } from '../../helpers/CartDrawerContext';
import { useEvent } from '../../helpers/useEvent';
import { useError } from '../../helpers/ErrorContext';

const ShoppingCart = ({ dates, quantity, offerId, zip, storePickup, storeDelivery, selectedZip, selectedOptions, addToCartActive }) => {
    const { toggleDrawer } = useCartDrawer();
    const [errorMessage, setErrorMessage] = useState('');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const sendEvent = useEvent();
    const { setError } = useError();
    const createCartAndGetSessionId = async () => {
        const url = `${API_BASE_URL}/shopping-cart`;
        try {
            console.log("Enviando petición para crear carrito...");
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const responseData = await response.json();
            if (!response.ok) {
                const errorMessages = Object.values(responseData.errors)
                        .flat()
                        .join(', ');
                throw new Error(errorMessages);
            }
            console.log('Carrito creado con éxito:', responseData);
            localStorage.setItem('session_id', responseData.session_id);
            return responseData.session_id;
        } catch (error) {
            console.error('Error al crear el carrito:', error);
            throw error;  // Re-throw the error to be handled in the caller function
        }
    };

    const checkSessionId = async () => {
        const sessionId = localStorage.getItem('session_id');
        const url = `${API_BASE_URL}/shopping-cart/${sessionId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const responseData = await response.json();
            if (!response.ok) {
                const errorMessages = Object.values(responseData.errors)
                        .flat()
                        .join(', ');
                throw new Error(errorMessages);
            }
            console.log('Carrito encontrado:', responseData);
            return responseData;
        } catch (error) {
            console.error('Error al buscar el carrito:', error);
            throw error;  // Re-throw the error to be handled in the caller function
        }
    };


    const submitOfferToCart = async (sessionId) => {
        console.log("Enviando estas fechas:", dates);
        const payload = {
            from_date: dates.from_date,
            to_date: dates.to_date,
            quantity: quantity,
            offer_id: offerId,
            zip: zip,
            store_pickup: storePickup ? "0" : "1",
            store_delivery: storeDelivery ? "0" : "1"
        };
        const url = `${API_BASE_URL}/shopping-cart/${sessionId}/offer`;
        console.log("Enviando oferta al carrito con los siguientes datos:", payload);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            const responseData = await response.json();
            if (!response.ok) {
                const errorMessages = Object.values(responseData.errors)
                        .flat()
                        .join(', ');
                throw new Error(errorMessages);
            }
            console.log('Oferta enviada con éxito:', responseData);
        } catch (error) {
            setError(error);
        }
    };

    const handleButtonClick = async () => {

        sendEvent('clic_web');

        if (!dates || !dates.from_date || !dates.to_date) {
            setErrorMessage('Por favor, selecciona un rango de fechas primero.');
            return;
        }

        if (!selectedOptions[0] || !selectedOptions[1]) {
            setErrorMessage('Por favor, selecciona las opciones de envío.');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
            return;
        }
        if(selectedOptions[0][1] === 1 || selectedOptions[1][1] === 1){
            if (!zip) {
                setErrorMessage('Por favor, selecciona un código postal.');
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
                return;
            }
        }

        let sessionId = localStorage.getItem('session_id');
        if (!sessionId) {
            console.log("No session ID found, creating new cart...");
            try {
                sessionId = await createCartAndGetSessionId();
            } catch (error) {
                console.error('Failed to create cart:', error);
                return;  // Stop further execution if cart creation fails
            }
        } else {
            console.log("Session ID found, using existing:", sessionId);
        }

        const isValidSession = await checkSessionId(sessionId);
        if (isValidSession.session_id) {
          await submitOfferToCart(sessionId);
          toggleDrawer(true);
        } else {
            sessionId = await createCartAndGetSessionId();
            handleButtonClick()
        }
    };

    useEffect(() => {
        checkSessionId();
    }, []);


    return (
        <div>
            <button onClick={handleButtonClick} disabled={addToCartActive} className={`mb-2 w-full h-14 bg-primary button flex items-center justify-center text-white rounded-full hover:bg-opacity-90 transition-all ease-in-out duration-150 lowercase ${!addToCartActive && 'bg-blue-gray-200' }`}>
                <p>Alquilar</p>
            </button>
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        </div>
    );
};

export default ShoppingCart;
