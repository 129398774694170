import imageIndustria from "../assets/images/xunta-galicia.png";

function Subvenciones() {
  return (
    <main className="text-left px-4 lg:px-20 py-14 md:py-24 lg:flex-col flex  gap-20">
      <section className=" px-4">
        <h1 className="pb-2 text-4xl text-left font-bold text-black md:text-4xl">
          subvenciones
        </h1>
        <img src={imageIndustria} alt="" className="mt-8 md:max-w-lg" />
      </section>
    </main>
  );
}

export default Subvenciones;
