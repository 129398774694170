import React from "react";
import SliderHero, { HeroSection } from "../components/SliderHero";
import { SectionSteps } from "../components/SectionSteps";
import { SectionText } from "../components/SectionText";
import { CarrouselCategories } from "../components/CarrouselCategories";
import { BecomeSupplier } from "../components/BecomeSupplier";
import { CarrouselBrands } from "../components/CarrouselBrands";
import { CarrouselEssentials } from "../components/CarrouselEssentials";
import { Sustainability } from "../components/Sustainability";
import { RatingClients } from "../components/RatingClients";

function Home() {
  return (
    <React.Fragment>
      <HeroSection />
      <SectionText />
      <CarrouselCategories
        title="descubre todas las categorías"
        highlightedText="categorías"
      />
      <SectionSteps />
      <CarrouselEssentials />
      <CarrouselBrands />
      <RatingClients
        title="opiniones de nuestros clientes"
        highlightedText="Opiniones"
      />
      <BecomeSupplier />
      <Sustainability />
    </React.Fragment>
  );
}

export default Home;
