export function parseContent(content) {
  // Regular expression to find HTML tags and their content
  const regex = /(<b>(.*?)<\/b>)/g;
  const output = [];
  let lastIndex = 0;

  // Find each match in the content string
  content.replace(regex, (match, p1, p2, offset) => {
    // Push the text before the tag, if any
    if (lastIndex < offset) {
      output.push(content.slice(lastIndex, offset));
    }
    // Push the content of the tag wrapped in a React element
    output.push(<b key={offset}>{p2}</b>);

    // Update the lastIndex to the end of the current match
    lastIndex = offset + match.length;
  });

  // Add any remaining text after the last match
  if (lastIndex < content.length) {
    output.push(content.slice(lastIndex));
  }

  return output;
}