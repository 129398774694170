import { useState } from "react";
import { useAuth } from "../hooks/AuthProvider";
import Button from "./ui/Button";
import Notification from "./ui/Notification";

function UserVerified({ url }) {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { user, token } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    message: "",
    isVisible: false,
  });

  async function handleEmailVerification() {
    try {
      setIsLoading(true);

      const response = await fetch(`${API_BASE_URL}/send-validation-email`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setNotification({
          message: "Correo enviado con éxito.",
          isVisible: true,
        });
      } else {
        let errorMessage;
        switch (response.status) {
          case 401:
            errorMessage = "401";
            break;
          case 404:
            errorMessage = "404";
            break;
          case 422:
            errorMessage = "Datos inválidos proporcionados.";
            break;
          case 500:
            errorMessage =
              "Error en el servidor, inténtelo de nuevo más tarde.";
            break;
          default:
            errorMessage = "Error en la petición.";
            break;
        }
        setNotification({ message: errorMessage, isVisible: true });
      }
    } catch (error) {
      console.error("Error:", error);
      setNotification({
        message: "Ocurrió un error inesperado.",
        isVisible: true,
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setNotification({ ...notification, isVisible: false });
      }, 5000);
    }
  }

  return (
    <div className="py-5 flex flex-col gap-5">
      {isLoading ? (
        <p>Enviando...</p>
      ) : (
        token &&
        (<div className="flex flex-col">
          <p>
            Todavía no has verificado tu email, por favor, para tener operativa
            tu cuenta, verifica tu email primero.
          </p>
          <p>
            Si no localizas el email de verificación, puedes enviarte otro
            pulsando el botón Reenviar.
          </p>
          <Button
            classButton="max-w-fit mt-4 mx-auto"
            type="primaryWhite"
            onClick={handleEmailVerification}
          >
            Reenviar
          </Button>
        </div>)
      )}
      <Notification
        message={notification.message}
        isVisible={notification.isVisible}
      />
    </div>
  );
}

export default UserVerified;
