import React, { useState } from 'react';

function ForgotPassword() {
    const [data, setData] = useState({ email: '' });
    const [errors, setErrors] = useState({});
    const [errorsMessages, setErrorsMessages] = useState([]);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    function validateInputs(inputs) {
        let errors = {};
        if (!inputs.email.trim()) {
            errors.email = 'Indica un correo electrónico.';
        } else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
            errors.email = 'Email no válido.';
        }
        return errors;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorsMessages([]);
        setErrors({});
        const validationErrors = validateInputs(data);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            fetch(`${API_BASE_URL}/forgot-password`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json().then(body => ({ status: response.status, body })))
                .then(result => {
                    const { status, body } = result;
                    if (status === 200) {
                        // Mensaje de confirmación
                        setData({ email: '' }); // Reset the email input
                        setErrors({}); // Reset the errors state
                        setErrorsMessages([]); // Set the confirmation message
                        setIsSuccessful(true);
                    } else {
                        const errors = body.errors || {};
                        setErrorsMessages(Object.values(errors).flat());
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    return (
        <div className='h-[calc(100vh-100px)] flex justify-center items-center  flex-col'>
            {isSuccessful ? (
                <div className="w-1/2 bg-white p-10 rounded-xl">
                    <p className="text-3xl text-primary font-bold">¡Gracias!</p>
                    <p className="mt-10">Hemos recibido tu petición para cambiar la contraseña correctamente. Recibiras un correo eléctornico con los pasos a seguir para obtener una nueva contraseñas</p>
                </div>
            ) : (
                <div>
                    <h1 className='text-3xl font-bold text-primary'>¿Olvidaste la contraseña?</h1>
                    <div className='flex flex-col gap-4 justify-center items-center'>
                        <form onSubmit={handleSubmit} className='mt-12'>
                            <div className='flex flex-col gap-4 justify-center items-center'>
                                <input
                                    type="text"
                                    className='w-full rounded-full px-4 py-4 transition-all ease-in-out duration-150'
                                    value={data.email}
                                    onChange={(e) => setData({ ...data, email: e.target.value })}
                                    placeholder="Introduce tu email de usuario" />
                                <button
                                    type="submit"
                                    className='bg-primary w-full button flex items-center justify-center gap-2 text-white stroke-white rounded-full px-24 py-4 hover:bg-opacity-90 transition-all ease-in-out duration-150'>
                                    Cambiar contraseña
                                </button>
                                <div className='flex flex-col justify-center text-center'>
                                    <div className='flex gap-2 mx-auto'>
                                        <span>¿No tienes cuenta?</span>
                                        <a href="/register" className="underline text-primary cursor-pointer">Regístrate aquí</a>
                                    </div>
                                </div>
                                <div className='w-full flex flex-col text-center'>
                                    {errors.email && <p className="text-xs text-red-500 text-center w-full">{errors.email}</p>}
                                    {errorsMessages.length > 0 && (
                                        <div className="text-xs text-red-500 text-center w-full">
                                            {errorsMessages.map((error, index) => (
                                                <p key={index}>{error}</p>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}

        </div>
    );
}

export default ForgotPassword;