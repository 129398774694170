import Menu from '../../components/private/Menu';

function Payments() {
  return (
    <div className="p-[15px] md:p-[35px] flex flex-col md:flex-row gap-8 md:gap-24 min-h-[calc(100vh-135px)]">
      <Menu position="payments"/>
      <div className="w-full">
        <h2 className="text-left text-[34px] text-primary font-bold mb-2">Métodos de pago</h2>
        <div className="w-full bg-white h-full flex flex-col items-start justify-start rounded-xl p-8">
          <div className=" text-left">
            <h3 className="text-lg font-bold">Información de facturación</h3>
          </div>
          <div className=" text-left mt-8 w-full">
            <div className="flex flex-row flex-wrap gap-[20px]">
              <div className="w-[calc(50%-10px)] mt-4">
                <label htmlFor="" className="pl-[25px]">Nombre</label>
                <input
                    type="text"
                    className='w-full rounded-full px-6 py-4 transition-all ease-in-out duration-150 bg-light mt-2'
                    placeholder=""
                />
              </div>
              <div className="w-[calc(50%-10px)] mt-4">
                <label htmlFor="" className="pl-[25px]">Email</label>
                <input
                    type="text"
                    className='w-full rounded-full px-6 py-4 transition-all ease-in-out duration-150 bg-light mt-2'
                    placeholder=""
                />
              </div>
              <div className="w-[calc(50%-10px)] mt-2">
                <label htmlFor="" className="pl-[25px]">Teléfono</label>
                <input
                    type="text"
                    className='w-full rounded-full px-6 py-4 transition-all ease-in-out duration-150 bg-light mt-2'
                    placeholder=""
                />
              </div>
              <div className="w-[calc(50%-10px)] mt-2">
                <label htmlFor="" className="pl-[25px]">Dirección</label>
                <input
                    type="text"
                    className='w-full rounded-full px-6 py-4 transition-all ease-in-out duration-150 bg-light mt-2'
                    placeholder=""
                />
              </div>
              <input
                  type="submit"
                  className='w-full bg-primary button flex items-center justify-center gap-2 text-white stroke-white rounded-full px-24 py-4 hover:bg-opacity-90 transition-all ease-in-out duration-150 mt-4'
                  value="Guardar cambios"
              />
            </div>

            <div className=" text-left mt-8">
              <h3 className="text-lg font-bold">Mis tarjetas</h3>
              <a href="" className='text-primary'>Añadir método de pago</a>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payments;