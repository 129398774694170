import React from "react";

export function SectionText() {
  return (
    <section className="container px-4 m-auto space-y-6 text-center text-black py-14 md:py-24 lg:space-y-2">
      <h2 className="h2">
        somos la web de alquiler más <span className="text-primary">fácil</span>
        , <span className="text-primary">rápida</span> y{" "}
        <span className="text-primary">segura</span>
      </h2>
      <h2 className="pt-0 font-bold body md:pt-6">
        ¡Encuentra el mejor servicio de alquiler de maquinaria y herramientas
        profesionales!
      </h2>
      <div className="flex justify-center pt-2 lg:pt-9">
        <a href="/quienes-somos">
          <button className="flex items-center justify-center py-6 text-white transition-all duration-150 ease-in-out border-2 rounded-full bg-primary button px-14 border-primary hover:bg-transparent hover:text-primary">
            <p>sobre nosotros</p>
          </button>
        </a>
      </div>
    </section>
  );
}
