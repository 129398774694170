import React, { createContext, useContext, useState } from 'react';

const CartDrawerContext = createContext({
  openRight: false, 
  toggleDrawer: () => {} 
});

export const useCartDrawer = () => useContext(CartDrawerContext);

export const CartDrawerProvider = ({ children }) => {
  const [openRight, setOpenRight] = useState(false);

  const toggleDrawer = (open) => {
    setOpenRight(open);
  };

  return (
    <CartDrawerContext.Provider value={{ openRight, toggleDrawer }}>
      {children}
    </CartDrawerContext.Provider>
  );
};
