import React from "react";
import imageText from "../assets/images/home/sustainability/section_eco.webp";

export function Sustainability() {
  return (
    <section className="container items-center gap-20 px-4 m-auto lg:px-20 py-14 md:py-24 lg:flex">
      <img
        src={imageText}
        className="w-full lg:w-1/2"
        alt="planta verde creciendo de la tierra, con la intención de simbolizar el crecimiento y la sostenibilidad"
      />
      <div className="order-first text-left lg:order-last">
        <p className="text-black h2">
          nuestro compromiso, pensar
          <span className="text-primary"> en verde</span>
        </p>
        <p className="text-dark pt-6 body container m-auto max-w-[100vh]">
          Alquilar herramientas y maquinaria: más ecológico y sostenible
        </p>
        <div className="flex items-center justify-center gap-6 pt-12">
          <a href="/sostenibilidad">
            <button className="flex items-center justify-center py-6 text-white transition-all duration-150 ease-in-out border-2 rounded-full bg-primary button px-14 border-primary hover:bg-transparent hover:text-primary">
              <p>sobre nosotros</p>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}
