import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  page: '',
  event: ''
};

const DataLayerContext = createContext();

const dataLayerReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE':
      return {
        ...state,
        page: action.page
      };
    case 'SEND_EVENT':
      return {
        ...state,
        event: action.event
      };
    default:
      return state;
  }
};

export const DataLayerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dataLayerReducer, initialState);

  return (
    <DataLayerContext.Provider value={{ state, dispatch }}>
      {children}
    </DataLayerContext.Provider>
  );
};

export const useDataLayer = () => {
  return useContext(DataLayerContext);
};
