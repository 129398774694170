import React from 'react';

function Input({
    id,
    name,
    children,
    disabled = false,
    href,
    type = 'text',
    label,
    classInput,
    required,
    placeholder,
    value,
    onChange,
    width = "w-full"
}) {
    const styles = {
        primary:
            "bg-primary button flex disabled:opacity-50 items-center justify-center text-white rounded-full px-14 py-4 border-2 border-primary hover:bg-transparent hover:text-white transition-all ease-in-out duration-150",
        primaryWhite:
            "bg-primary button flex items-center justify-center text-white rounded-full px-14 py-4 border-2 border-primary hover:bg-transparent hover:text-primary transition-all ease-in-out duration-150",
        primaryBlack:
            "bg-primary button flex items-center justify-center text-white rounded-full px-14 py-4 border-2 border-primary hover:bg-transparent hover:border-primary hover:text-dark transition-all ease-in-out duration-150",
    };

    const combinedClasses = `${styles[type]} ${classInput || ''}`;

    return (
        <div className={`flex flex-col gap-1 ${width}`}>
            <label htmlFor={id} className="text-dark w-full text-xs  ml-2">
                {label}
                {required ? <span className="text-primary-500 ml-1 font-bold">*</span> : null}
            </label>
            <input
                disabled={disabled}
                type={type}
                id={id}
                name={name}
                placeholder={placeholder}
                className="placeholder:opacity-35 disabled:opacity-50 placeholder:text-black h5 px-4 py-1.5 w-full bg-transparent ring-0 focus:ring-0 active:ring-0 border border-zinc-200 rounded-xl focus:border border-dark focus:border-primary outline-none focus:outline-none ease-in-out duration-150 text-black"
                value={value}
                onChange={onChange} />
        </div >
    )
}

export default Input;