import React from "react";
import { Rating } from "@material-tailwind/react";
import { HighlightedTitle } from "./ui/HighlightedTitle";

const ratings = [
  {
    name: "Ana M.",
    rating: 5,
    description:
      "Hacer una reforma en los tiempos actuales es extremadamente complicado y caro, por lo que buscamos la mejor forma de óptimizar gastos y esto no hubiese sido posible sin el alquilador. No solo abaratamos mucho sino que nos encontramos con un servicio impecable, solo puedo recomendarlos a todo el mundo.",
  },
  {
    name: "Leonor Montiel Egea",
    rating: 5,
    description:
      "Grandes profesionales, la descubrí al inicio de una reforma y he ahorrado dinero y tiempo, llevan y recogen todo aquello que necesites en el lugar que les indiques. Me dieron todas las facilidades para que tuviese el mejor servicio. Sin duda repetiré, gracias!🤩🤩🤩",
  },
  {
    name: "Aurelio Fernández",
    rating: 5,
    description:
      "Que maravilla!! Aquí encontré todo lo necesario para la limpieza y pequeña reforma que hice en mi pequeño patio la semana pasada. No tuve que comprar maquinaria que usaría poco.Trato muy agradable y servicio rápido y profesional.Volveré a alquilar con ellos sin dudarlo.",
  },
];

export function RatingClients({ title, highlightedText }) {
  return (
    <section className="container m-auto text-center px-4 lg:px-20 py-14 md:py-24 text-black h2 space-y-2">
      <h2>
        <HighlightedTitle title={title} highlight={highlightedText} />
      </h2>
      <div className="pt-12 lg:flex gap-6 space-y-6 lg:space-y-0">
        {ratings.map((item, index) => (
          <div
            key={index}
            className="bg-white px-4 md:px-9 py-9 text-start flex-1 rounded-3xl"
          >
            <div className="pb-6 flex gap-2 items-center">
              <Rating
                value={item.rating}
                readonly
                className="text-yellow-500"
              />
            </div>
            <p className="h3 text-black">
              <span className="text-primary">{item.name}</span>
            </p>
            <p className="pt-6 body text-dark">{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
