import { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { HighlightedTitle } from "../components/ui/HighlightedTitle";

function Icon({ index, open }) {
  return (
    <div
      className={`icon h-9 md:h-12 w-9 md:w-12 flex justify-center items-center text-center text-primary transition-all ease-in-out duration-150 ${
        open === index ? "open-icon" : "close-icon"
      }`}
    >
      <span className="h2 !font-thin">{open === index ? "-" : "+"}</span>
    </div>
  );
}

function Faqs() {
  const [open, setOpen] = useState(null);
  const handleOpen = (index) => setOpen(open === index ? null : index);

  const faqs = [
    {
      question: "¿Qué tipo de maquinaria se puede alquilar en elalquilador?",
      answer: (
        <>
          <p>
            En <strong>elalquilador</strong> ofrecemos una amplia variedad de maquinaria para todo
            tipo de proyectos. Desde <strong>elevadores de personas</strong>, manipulador
            telescópico, <strong>plataforma tijera, camión cesta</strong>, brazo articulado,
            andamios, motosierra, cortatepe, motosegadora, pisón, rodillo, etc.
            Nuestro catálogo está diseñado para cubrir las necesidades tanto de
            profesionales como de particulares. <a className="underline text-primary" href="/motivos-para-alquilar">Descubre más aquí</a>{" "}
            sobre ello.
          </p>
        </>
      ),
    },
    {
      question: "¿Por qué alquilar maquinaria en lugar de comprarla?",
      answer: (
        <>
          <p>
            El alquiler de maquinaria ofrece diversas ventajas, entre las que se
            encuentran:
          </p>
          <ul className="pt-3 pl-5 list-disc">
            <li><strong>Ahorro de costes:</strong> no necesitas hacer grandes inversiones iniciales.</li>
            <li>
            <strong>Mantenimiento incluido:</strong> nosotros nos encargamos de mantener la
              maquinaria en óptimas condiciones.
            </li>
            <li>
            <strong>Acceso a tecnología moderna:</strong> siempre podrás contar con equipos
              actualizados sin preocuparte por la depreciación. Conoce más sobre
              las ventajas de alquilar en <a href="/blog" className="underline text-primary">nuestro blog especializado</a>.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "¿Cómo funciona el proceso de alquiler con elalquilador?",
      answer: (
        <>
          <p>Nuestro proceso es rápido y sencillo:</p>
          <ol className="pt-3 pl-5 list-decimal">
            <li>Elige la maquinaria que necesitas.</li>
            <li>Solicita un presupuesto online.</li>
            <li>
              Programa el período de alquiler que mejor se ajuste a tu proyecto.
            </li>
            <li>
              Recibe la maquinaria directamente en el lugar que elijas. Para más
              detalles, visita nuestra <a href="/" className="underline text-primary">página de funcionamiento</a>.
            </li>
          </ol>
        </>
      ),
    },
    {
      question: "¿Es necesario dejar un depósito al alquilar maquinaria?",
      answer: (
        <p>
          Sí, se requiere un depósito como garantía al alquilar maquinaria en
          elalquilador. La cantidad puede variar según el equipo alquilado y la
          duración del alquiler. Este depósito se reembolsa al finalizar el
          contrato y tras la inspección del equipo.
        </p>
      ),
    },
    {
      question: "¿Cómo el alquiler de maquinaria contribuye a la sostenibilidad?",
      answer: (
        <p>
          El alquiler de maquinaria no solo es una opción económica, sino también
          ecológica. Ayuda a reducir el desperdicio, fomenta el uso compartido de
          recursos y minimiza la huella de carbono. En <strong>elalquilador</strong> estamos
          comprometidos con ello y apoyamos prácticas que respetan el medio
          ambiente. Puedes leer más sobre nuestras políticas de sostenibilidad en{" "}
          <a href="/sostenibilidad" className="underline text-primary">este artículo</a>.
        </p>
      ),
    },
    {
      question:
        "¿elalquilador ofrece servicios de asesoramiento para elegir la maquinaria adecuada?",
      answer: (
        <p>
          ¡Por supuesto! Nuestro equipo de expertos está disponible para ayudarte
          a seleccionar la maquinaria que mejor se adapte a las necesidades de tu
          proyecto, asegurando que puedas trabajar de manera eficiente y segura.
          Ponte en contacto con nosotros a través de nuestra{" "}
          <a href="/escribe-a-nuestro-experto#form" className="underline text-primary">página de contacto</a>.
        </p>
      ),
    },
    {
      question:
        "¿elalquilador cuenta con servicio de entrega y recogida de maquinaria?",
      answer: (
        <p>
          Sí, la mayoría de nuestros almacenes asociados ofrecen servicio de
          entrega y recogida de maquinaria para tu comodidad. Puedes coordinar la
          entrega en la ubicación que prefieras y nosotros nos encargamos del
          transporte. Esto es especialmente útil si trabajas en proyectos en
          distintas ubicaciones o si no dispones de un vehículo adecuado para
          transportar la maquinaria.
        </p>
      ),
    },
    {
      question: "¿Qué debo hacer si necesito extender el período de alquiler?",
      answer: (
        <p>
          Si necesitas más tiempo del inicialmente previsto, solo debes ponerte en
          contacto con nosotros antes de la fecha de vencimiento del alquiler. Te
          ayudaremos a extender el período, ajustando el contrato y evitando
          penalizaciones. Ofrecemos flexibilidad en función de la disponibilidad
          de la maquinaria. Podrás obtener más información en la sección de{" "}
          <a href="/" className="underline text-primary">términos de alquiler</a>.
        </p>
      ),
    },
    {
      question: "¿Qué requisitos son necesarios para alquilar maquinaria?",
      answer: (
        <>
          <p>Los requisitos para alquilar maquinaria incluyen:</p>
          <ul className="pt-3 pl-5 list-disc">
            <li>Presentar un documento de identidad válido.</li>
            <li>Completar un formulario con tus datos de contacto.</li>
            <li>
              Realizar un depósito de seguridad (según el tipo de maquinaria).
            </li>
            <li>Aceptar los términos y condiciones del alquiler.</li>
            <li>
              Si eres una empresa, también se te solicitará una acreditación de la
              compañía.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "¿Qué debo hacer si la maquinaria alquilada no funciona correctamente?",
      answer: (
        <p>
          En caso de que sufras algún problema con la maquinaria, te pedimos que
          te pongas en contacto con nuestro equipo de atención al cliente o
          directamente con nuestros colaboradores lo antes posible. Si es
          necesario, reemplazarán el equipo defectuoso de inmediato para evitar
          retrasos en tu proyecto. Nuestro colaboradores quieren garantizar que tu
          experiencia de alquiler sea eficiente y sin complicaciones.
        </p>
      ),
    },
    {
      question: "¿elalquilador ofrece capacitación para el uso de maquinaria?",
      answer: (
        <p>
          Sí, sabemos que algunas herramientas requieren conocimientos específicos
          para su correcto manejo. Por eso, ofrecemos capacitación y asesoramiento
          para asegurarnos de que utilices la maquinaria de forma segura y eficaz.
          Contamos con videos tutoriales y guías prácticas para cada equipo.{" "}
          <a href="/blog/de-construccion/cursos-formacion-usar-maquinaria-construccion-por-que-elegir-elalquilador-para-realizarlos" className="underline text-primary">Aprende más sobre nuestros servicios de formación</a>.
        </p>
      ),
    },
    {
      question: "¿Puedo alquilar maquinaria de forma mensual o a largo plazo?",
      answer: (
        <p>
          Ofrecemos opciones de alquiler flexibles, tanto a corto como a largo plazo. 
          Si necesitas alquilar maquinaria por varios meses, contamos con tarifas especiales y contratos de larga duración para garantizar que obtengas el mejor precio posible. 
          Puedes solicitar un presupuesto personalizado en nuestra <a href="/blog/de-alquiler/renting-que-es-cuales-son-sus-caracteristicas-como-debe-reflejarse" className="underline text-primary">página de alquiler a largo plazo</a>.
        </p>
      ),
    },
    {
      question: "¿Puedo cancelar una reserva de alquiler?",
      answer: (
        <p>
          Si necesitas cancelar o modificar una reserva puedes hacerlo, pero
          siempre según las políticas de cada colaborador. Antes de acordar el
          alquiler de cualquier herramienta profesional o maquinaria, podrás
          conocerlas para tomar la mejor decisión.
        </p>
      ),
    },
  ];
  

  return (
    <div className="max-w-6xl py-20 px-4 mx-auto flex flex-col gap-8 md:gap-14">
      <h1 className="md:pl-20 h1 text-start">Preguntas frequentes</h1>
      <div className="flex flex-col gap-6">
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            open={open === index}
            icon={<Icon index={index} open={open} />}
            className="p-4 md:py-2 md:px-20 bg-white rounded-2xl border-none"
          >
            <AccordionHeader
              className="flex gap-0 items-center justify-between h4 font-bold text-black select-none border-none py-0"
              onClick={() => handleOpen(index)}
            >
              <h2>
                <HighlightedTitle title={`${index + 1}.`} highlight={`${index + 1}.`} />{" "}
                {faq.question}
              </h2>
            </AccordionHeader>
            <AccordionBody className="accordion-content overflow-hidden transition-max-height duration-300 text-start body font-bold text-dark pb-4 pr-6 leading-5">
              {faq.answer}
            </AccordionBody>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

export default Faqs;
