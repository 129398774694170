import React from 'react';
import { Helmet } from 'react-helmet-async';

const ProductSchema = ({ product }) => {
  const {
    short_name,
    description,
    sku,
    offers,
    id,
    images = [],
    slug,
    products_offers_published = [],
    review,
  } = product;


  const aggregateRating = products_offers_published[0]?.reviews || {};
  const brand = products_offers_published[0]?.junior?.name || 'No brand';
  const imagesurl = images.length > 0 
    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${images[0].path}`
    : 'default_image_url'; 

  const url = `https://elalquilador.com/alquiler-de/${slug}`;

  const schemaData = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": short_name,
    "description": description,
    "sku": sku,
    "brand": brand,
    "offers": offers,
    "productID": id,
    "image": imagesurl,
    "url": url,
    "aggregateRating": aggregateRating,
    "review": review,
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
};

export default ProductSchema;
