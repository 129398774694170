export const Modal = ({ isVisible, imageSrc, onClose }) => {
    if (!isVisible) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[999]">
        <div className="relative bg-white p-4 rounded-lg shadow-lg">
          <img src={imageSrc} alt="Ampliada" className="max-h-[80vh] max-w-[90vw]"/>
          <button
            onClick={onClose}
            className="absolute top-0 right-0 mt-2 mr-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
      </div>
    );
  };
  