import React from "react";

function Conditions() {
  return (
    <section className="container gap-20 px-4 m-auto lg:px-20 py-14 md:py-24 lg:flex">
      <div className="container mx-auto text-left">
        <div className="header text-center mb-5">
          <h1 className="text-2xl font-bold">
            Condiciones de uso y condiciones Generales de Contratación
          </h1>
        </div>

        {/* ANEXO I CONDICIONES GENERALES DE CONTRATACIÓN */}
        <section className="mb-5">
          {/* CARÁCTER DEL CONTRATO */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">CARÁCTER DEL CONTRATO</h2>
            <p>
              Las presentes condiciones de contratación (En adelante Condiciones
              Generales de Contratación o Condiciones de Uso) se aplican a la
              compraventa de servicios ofertados por los proveedores en la web
              de El Alquilador. El idioma de dichas condiciones es el
              castellano.
            </p>
            <p>
              En lo que afecta a consumidores finales estas condiciones de
              contratación se redactan de conformidad con lo dispuesto en el
              Real Decreto Legislativo 1/2007 de 16 de noviembre por el que se
              aprueba el texto refundido de la Ley General para la Defensa de
              los Consumidores y Usuarios y tendrán fuerza de contrato entre las
              partes una vez que sean aceptadas.
            </p>
            <p>
              En lo que afecten a relaciones entre profesionales y personas
              jurídicas será de aplicación las obligaciones y deberes de los
              contratos generales del Código Civil y del Código Mercantil.
            </p>
          </div>

          {/* OBJETO DEL CONTRATO */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">OBJETO DEL CONTRATO</h2>
            <p>
              El presente contrato tiene como objeto la prestación de servicios
              de oferta de servicios a modo Marketplace poniendo en común la
              oferta y la demanda de servicios de alquiler y compra de productos
              y servicios relacionados con el ocio y recreación, jardinería,
              reparaciones, ferretería y otras categorías que puedan incluirse
              en el futuro en la lista de servicios prestados por elalquilador.
            </p>
          </div>

          {/* QUIENES SOMOS */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">1. QUIENES SOMOS</h2>
            <p>
              El prestador de servicios es MYGARRET S.L, titular del servicio y
              de la página web{" "}
              <a href="http://www.elalquilador.com" className="text-blue-500">
                www.elalquilador.com
              </a>{" "}
              (en adelante elalquilador.com) con domicilio a efectos de
              notificaciones en Calle Nicaragua 2, planta primera, puerta
              Izquierda, 15005 A Coruña, España y número de Identificación
              Fiscal B42887570.
            </p>
          </div>

          {/* INTERVINIENTES EN LA OFERTA Y CONTRATACIÓN DE SERVICIOS */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              2. INTERVINIENTES EN LA OFERTA Y CONTRATACIÓN DE SERVICIOS
            </h2>
            <p>
              Elalquilador.com: El propietario del portal web que permite la
              interacción entre ofertantes de material y consumidores
              interesados en disponer de una herramienta de forma temporal.
            </p>
            <p>
              SOLICITANTES: Personas físicas o jurídicas que tienen interés en
              contratar un servicio de alquiler de herramienta.
            </p>
            <p>
              PROVEEDOR: Personas físicas o jurídicas que ofrecen sus servicios
              profesionales a través de la web elalquilador.com.
            </p>
            <p>
              USUARIO: cualquier persona física o jurídica que acceda y navegue
              por la web independientemente de su condición de proveedor o
              solicitante.
            </p>
          </div>

          {/* CONTENIDOS E INFORMACIÓN SUMINISTRADA EN LA PÁGINA WEB */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              3. CONTENIDOS E INFORMACIÓN SUMINISTRADA EN LA PÁGINA WEB
            </h2>
            <p>
              elalquilador.com se reserva el derecho a modificar la oferta de
              servicios presentada en su página en cualquier momento.
            </p>
            <p>
              elalquilador.com no es responsable de la veracidad y exactitud de
              las ofertas publicadas por los Proveedores.
            </p>
            <p>
              elalquilador.com hace todos los esfuerzos dentro de sus medios
              para que la información contenida en su portal se muestre de forma
              veraz y sin errores tipográficos.
            </p>
            <p>
              En el caso que en algún momento se produjera algún error de este
              tipo ajeno en todo momento a la voluntad de elalquilador.com se
              procedería inmediatamente a su corrección. De existir un error
              tipográfico en alguno de las ofertas de servicios o información
              facilitada por los proveedores y/o Solicitantes elalquilador.com
              no tendrá ninguna responsabilidad.
            </p>
          </div>

          {/* DESCRIPCIÓN DEL SERVICIO */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              4. DESCRIPCIÓN DEL SERVICIO
            </h2>
            <p>
              La finalidad del Sitio Web de elalquilador.com es la de ofrecer
              una plataforma de contacto entre Proveedores profesionales
              (PROVEEDORES) y personas usuarias que están interesados en
              alquilar los productos ofertados (SOLICITANTES).
            </p>
            <p>
              A través de su portal elalquilador.com ofrece a los Solicitantes y
              a los Proveedores información sobre cómo ponerse en contacto entre
              ellos y contratar servicios. Los Solicitantes pueden opinar
              públicamente en elalquilador.com sobre el servicio recibido y
              valorarlo para que lo tengan en cuenta otros usuarios y
              Solicitantes.
            </p>
          </div>

          {/* PRECIO DEL SERVICIO. CARGOS APLICADOS POR ELALQUILADOR.COM */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              5. PRECIO DEL SERVICIO. CARGOS APLICADOS POR ELALQUILADOR.COM
            </h2>
            <p>
              Las partes son libres para fijar el precio que convengan para la
              prestación y contratación de sus servicios. elalquilador.com como
              proveedor de la plataforma y del servicio aplicará la tarifa
              correspondiente de servicio que se determinará en base a
              condiciones particulares.
            </p>
          </div>

          {/* EXCLUSIÓN DE RESPONSABILIDAD */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              6. EXCLUSIÓN DE RESPONSABILIDAD
            </h2>
            <p>
              elalquilador.com no toma parte en la oferta y demanda de los
              servicios así como en los acuerdos que se adopten entre las
              partes. Tampoco asume ninguna posición o responsabilidad sobre la
              ejecución de los servicios acordados entre las partes por lo que
              no es responsable en ningún caso de la exactitud de la información
              facilitada de la ejecución del servicio ni de la calidad de este
              así como tampoco del cumplimiento o incumplimiento de las
              obligaciones nacidas entre ellas a raíz de la contratación de los
              servicios.
            </p>
            <p>
              Las partes eximen mediante esta cláusula de forma expresa a
              elalquilador.com sobre cualquier incumplimiento o desacuerdo que
              pudiera surgir entre ambos.
            </p>
            <p>
              En ningún caso elalquilador.com adoptará o asumirá la posición de
              mediador en la resolución de conflictos entre las partes.
            </p>
          </div>

          {/* CAPACIDAD LEGAL Y EDAD PARA CONTRATAR */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              CAPACIDAD LEGAL Y EDAD PARA CONTRATAR
            </h2>
            <p>
              Debes ser mayor de edad y disponer de plena capacidad de obrar
              para contratar para usar los servicios de elalquilador.com.
              elalquilador.com se reserva el derecho de rechazar el alta dar de
              baja y/o cancelar los datos de aquellos Solicitante o Usuarios que
              no acrediten esta condición a juicio de elalquilador.com.
            </p>
          </div>

          {/* REGISTRO DE USUARIOS */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">7.REGISTRO DE USUARIOS</h2>
            <p>
              El uso de los servicios de este portal requiere el previo registro
              de Usuario bien como Proveedor o bien como Solicitante debiendo
              completar para un registro exitoso como mínimo la información que
              se marca con un asterisco. En el momento del registro de la cuenta
              se debe elegir un nombre de usuario y una contraseña que servirán
              como método de acceso al portal. El Usuario podrá darse de baja
              como usuario registrado desde su panel de control en cualquier
              momento o escribiendo a datos@elalquilador.com.
            </p>
            <p>
              El Usuario de los servicios se compromete a conservar de forma
              segura su nombre de usuario y contraseña y no revelarlos ni
              cederlos para su uso por parte de terceros así como a usarlos con
              la diligencia debida. El Usuario de los servicios se compromete a
              notificar a Elalquilador.com a la mayor brevedad el uso no
              autorizado de su nombre de usuario y contraseña o cualquier otro
              fallo en la seguridad detectado. elalquilador.com no será
              responsable por los daños o pérdidas que se pudieran originar
              debido al no cumplimiento de esta obligación por su parte.
            </p>
            <p>
              El Usuario es el único responsable de la información suministrada
              durante su registro y garantiza que los datos facilitados a
              elalquilador.com son veraces y se encuentran actualizados y se
              compromete a comunicar a elalquilador.com cualquier modificación
              de los mismos así como a mantener la información facilitada
              actualizada en todo momento.
            </p>
          </div>

          {/* PUBLICACIÓN DE OFERTAS */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">8. PUBLICACIÓN DE OFERTAS</h2>
            <p>
              elalquilador.com ofrece a los Proveedores la posibilidad de
              publicar una oferta o anuncio de sus productos para que sean
              vistos y puedan ser objeto de búsqueda por parte de los usuarios y
              solicitantes.
            </p>
            <p>
              En todo caso el Proveedor será el único responsable de que una
              información falsa o inexacta que hubiese facilitado pudiese causar
              un perjuicio a Elalquilador.com o a terceros por dicha
              información.
            </p>
            <p>
              Al solicitar un servicio o producto el Usuario y/o Solicitante
              autoriza y consiente que sus datos de contacto sean cedidos a
              terceros (Proveedores) para ejecutar el servicio solicitado. Esta
              comunicación de datos personales es necesaria para la correcta
              ejecución del servicio. Puede consultar más información en nuestra
              Política de Privacidad pinchando aquí.
            </p>
            <h3 className="text-md font-semibold mt-4">
              Sobre la oferta de servicios:
            </h3>
            <p>
              Los presupuestos facilitados y las ofertas realizadas son
              vinculantes en caso de ser aceptadas salvo que no se haya
              facilitado la información completa y se descubra un error grave y
              esencial en la valoración de la oferta o de la demanda. En este
              caso las partes podrán modificar el contenido de la oferta y
              presupuesto atendiendo a las circunstancias reales.
            </p>
            <p>
              Los Proveedores no podrán ver el resto de las ofertas de los demás
              Usuarios ni tampoco podrán tener acceso a la información de quien
              ha obtenido la puja ganadora sobre la oferta realizada por un
              usuario.
            </p>
            <p>
              Los Usuarios podrá informar a elalquilador.com acerca de cualquier
              irregularidad que detecten en el funcionamiento de la plataforma
              así como sobre la información que aparece o publican otros
              Usuarios.
            </p>
          </div>

          {/* REGIMEN SANCIONADOR. CANCELACIÓN Y SUSPENSIÓN DE CUENTAS */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              9. REGIMEN SANCIONADOR. CANCELACIÓN Y SUSPENSIÓN DE CUENTAS
            </h2>
            <p>
              elalquilador.com no asume ninguna responsabilidad sobre la calidad
              de los servicios prestados por los Usuarios. En cualquier caso si
              elalquilador.com tuviera conocimiento de que algún Usuario
              incumple la legalidad o las normas de uso podrá cancelar su cuenta
              de forma provisional o permanente previa notificación exponiendo
              el motivo de esta así como si existe la posibilidad de
              restauración del servicio y las acciones necesarias para ello.
            </p>
            <p>
              Si elalquilador.com considera que un Usuario incumple con las
              presentes Condiciones se reserva el derecho a suspender
              temporalmente o revocar permanentemente su registro y derecho de
              acceso o usar el servicio en cualquier momento previa notificación
              exponiendo el motivo de esta empleándose de los medios necesarios
              para ello como por ejemplo e bloqueo de direcciones IP o cuentas
              de correo electrónico u otros datos facilitados como por ejemplo
              el teléfono.
            </p>
          </div>

          {/* EL CORRECTO USO DE LOS SERVICIOS OFRECIDOS EN ELALQUILADOR.COM */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              10. EL CORRECTO USO DE LOS SERVICIOS OFRECIDOS EN ELALQUILADOR.COM
            </h2>
            <p>
              Cuando un Usuario se registra en elalquilador.com se compromete a
              hacer uso de los servicios ofrecidos con respeto y de conformidad
              con la legislación vigente la moral y las buenas costumbres
              generalmente aceptadas y el orden público así como con las
              presentes Condiciones de Uso igualmente se obliga a aceptar y
              respetar las condiciones particulares de cada servicio aplicadas
              por los otros Usuarios.
            </p>
            <p>
              Los Usuarios no podrán hacer uso de la información y contenido de
              este Portal con fines ilícitos o que estén expresamente prohibidos
              en la legislación vigente y en las propias Condiciones de Uso e
              igualmente en las condiciones particulares que apliquen. Tampoco
              se podrán emplear contra de los derechos e intereses de
              elalquilador.com de sus miembros o terceros. En tales casos el
              usuario deberá responder frente a éstos. En estos supuestos se
              incluye la introducción o difusión de “virus informáticos” dañar
              inutilizar o sobrecargar la web deteriorar o impedir la normal
              utilización del servicio así como dañar los sistemas de
              información o los documentos archivos y toda clase de contenidos
              almacenados ya sea en equipos informáticos (hacking) de
              elalquilador.com de sus miembros o de cualquier Usuario de los
              servicios.
            </p>
            <h3 className="text-md font-semibold mt-4">
              Política anti-spam y prohibición de scrapping
            </h3>
            <p>
              Mediante la aceptación de las presentes condiciones el Usuarios se
              compromete a no recabar datos con fines publicitarios y proceder
              al envío de comunicaciones comerciales de cualquier clase y por
              cualquier medio.
            </p>
            <p>
              Igualmente se compromete a no poner a disposición de terceros con
              cualquier fin datos recabados en el Sitio Web.
            </p>
            <p>
              En caso de que algún usuario tuviese conocimiento de estas
              conductas por parte de otros usuarios deberá ponerlo en
              conocimiento de dirigiéndose a: datos@elalquilador.com.
            </p>
          </div>

          {/* MENSAJERÍA ENTRE LAS PARTES */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              11. MENSAJERÍA ENTRE LAS PARTES
            </h2>
            <p>
              El usuario reconoce que las comunicaciones a través de correo
              electrónico con elalquilador.com o con otros usuarios se
              consideran declaraciones de intención absoluta. Un correo
              electrónico se considera originado por el propietario de la
              dirección que lo envía excepto que se demuestre lo contrario.
            </p>
            <p>
              elalquilador.com no se responsabiliza en ningún caso de la
              veracidad o pertinencia de la información que los usuarios
              proporcionan siendo esta de exclusiva responsabilidad de la
              persona emisora.
            </p>
          </div>

          {/* PROPIEDAD INTELECTUAL E INDUSTRIAL */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              12.PROPIEDAD INTELECTUAL E INDUSTRIAL
            </h2>
            <p>
              elalquilador.com como titular del nombre de dominio{" "}
              <a href="http://www.elalquilador.com" className="text-blue-500">
                www.elalquilador.com
              </a>{" "}
              asume la propiedad intelectual sin limitaciones del diseño
              estructura y distribución textos y contenidos logotipos botones
              imágenes dibujos marcas nombres comerciales código fuente
              creatividades así como todos los derechos de propiedad intelectual
              e industrial y cualquier otro signo distintivo que en ella
              aparezcan a excepción de aquellas referidas a mercas o
              denominaciones comerciales de los usuarios registrados. El Usuario
              se compromete a respetar estos derechos.
            </p>
            <h3 className="text-md font-semibold mt-4">
              Derechos sobre el contenido publicado por los Usuarios
            </h3>
            <p>
              El Usuario de la web es el titular de todo el material que
              publique en nuestra Web siempre y cuando efectivamente sea el
              legítimo dueño de lo que publica. El Usuario garantiza frente a
              elalquilador.com y terceros que dispone de los permisos necesarios
              en su caso para publicar el contenido y concede una licencia no
              exclusiva continua y universal a elalquilador.com para que pueda
              utilizarlo. Esta licencia podrá ser cedida o traspasada a terceros
              por parte de elalquilador.com sin requerir autorización por parte
              del Usuario. En caso de disputa frente a terceros por infracción
              de derechos de propiedad intelectual o industrial elalquilador.com
              se reserva el derecho y se encuentra autorizada por el Usuario a
              revelar su identidad a los terceros en disputa.
            </p>
            <p>
              En ningún caso elalquilador.com asumirá responsabilidad alguna
              respecto de los derechos de propiedad intelectual o industrial
              titularidad de terceros que se vean infringidos por la actividad
              de un Usuario.
            </p>
            <p>
              Queda prohibida la reproducción explotación alteración
              distribución o comunicación pública del del contenido de esta web
              para otros usos que no sean la legítima información o contratación
              por parte de los Usuarios de los servicios ofertados.
            </p>
            <p>
              En caso de reclamación por parte de terceros titulares de derechos
              el usuario exonerará a elalquilador.com de toda responsabilidad
              relacionada con aquella incluidas las denuncias por violación de
              los derechos de autor de competencia desleal protección de datos
              y/o de otros derechos de terceros. En estos casos el usuario debe
              notificar a elalquilador.com cualquier notificación por parte de
              terceros que tengan intención de emprender acciones legales
              relativas a la utilización del esta Web. elalquilador.com se
              reserva el derecho de tomar las medidas legales oportunas para
              defenderse de las reclamaciones efectuadas por terceros o realizar
              sus propias demandas. Las medidas propuestas por el usuario deben
              acordarse de antemano con elalquilador.com. La exoneración de
              responsabilidad también incluye el reembolso de los gastos legales
              incurridos por los Titulares para conocer sus derechos legales.
            </p>
            <p>
              Mediante la aceptación de las presentes condiciones el Usuario
              reconoce que no dispone de ningún derecho de propiedad o licencia
              sobre la propia web de elalquilador.com o su contenido.
            </p>
            <p>
              El usuario puede iniciar cualquier reclamación relativa a derechos
              sobre el contenido publicado en el site enviando un correo a:
              datos@elalquilador.com.
            </p>
          </div>

          {/* PROTECCIÓN DE DATOS Y MEDIDAS DE SEGURIDAD */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              13. PROTECCIÓN DE DATOS Y MEDIDAS DE SEGURIDAD
            </h2>
            <p>
              elalquilador.com ha adoptado las medidas de seguridad adecuadas
              para llevar a cabo los procesos descritos en el detalle de
              servicios. Estas medidas de seguridad serán revisadas
              periódicamente de acuerdo con el estado de la técnica.
            </p>
            <p>
              elalquilador.com trata datos de los usuarios registrados y sobre
              las actividades realizadas con distintas finalidades como
              responsable del tratamiento. Puede consultar la información
              adicional relativa al tratamiento de datos en nuestra política de
              privacidad.
            </p>
            <p>
              El Usuario puede ejercer sus derechos de acceso rectificación y
              supresión así como otros derechos y obtener información adicional
              sobre el tratamiento de sus datos dirigiéndose a elalquilador.com
              por correo electrónico a datos@elalquilador.com o consultando
              nuestra Política de Privacidad aquí.
            </p>
            <h3 className="text-md font-semibold mt-4">
              elalquilador.com como encargado del tratamiento. Medidas de
              seguridad y responsabilidad
            </h3>
            <p>
              En relación a los datos alojados e intercambiados en la plataforma
              por Proveedores y Solicitantes elalquilador.com únicamente dispone
              de acceso a datos de transacciones realizadas y la información de
              la cuenta de usuario. Los datos de los clientes y de los
              proveedores permanecen almacenados en el back office de
              elalquilador durante los plazos de conservación legalmente
              aplicables. Nunca almacenamos tarjetas u otros datos bancarios.
            </p>
            <p>
              En cualquier caso como prestador de servicio y alojador de los
              datos elalquilador.com adquiere frente a los proveedores la
              condición de Encargado del Tratamiento de acuerdo con lo
              establecido en el artículo 28 del Reglamento (UE) 2016/679 y los
              Proveedores y Solicitantes adquieren frente a elalquilador.com y
              los interesados la condición de Responsables del Tratamiento.
            </p>
            <p>
              Puede consultar las cláusulas de aplicación para este supuesto
              pinchando aquí. La aceptación de las presentes condiciones de
              contratación supone la aceptación de las cláusulas de encargo del
              tratamiento.
            </p>
          </div>

          {/* RÉGIMEN DE EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              14. RÉGIMEN DE EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD
            </h2>
            <p>
              Elalquilador.com no se responsabiliza por los daños y perjuicios
              de cualquier naturaleza que puedan deberse a la falta de exactitud
              errores u omisiones de la información y servicios contenidos en
              este Sitio Web u otros contenidos a los que se pueda acceder a
              través del mismo ni asume ningún deber o compromiso de verificar
              ni de vigilar sus contenido e informaciones.
            </p>
            <h3 className="text-md font-semibold mt-4">
              Exclusión de garantías y de responsabilidad por el funcionamiento
              del Sitio Web y sus servicios
            </h3>
            <p>
              elalquilador.com no puede garantizar la disponibilidad y
              continuidad del funcionamiento su web y de sus servicios.
              elalquilador.com intentara en la medida de lo posible advertir con
              suficiente antelación de las interrupciones de servicio siempre
              que ello sea posible. elalquilador.com no puede garantizar que su
              servidor web esté libres de virus errores programas espía
              (spyware) Troyanos o similar software malintencionado.
              elalquilador.com no se hace responsable de cualquier daño al
              hardware o software de los dispositivos de los usuarios. Tampoco
              se hace responsable de ninguna pérdida de información a raíz de la
              transmisión uso de datos o contenido erróneo publicado por los
              Usuarios.
            </p>
            <p>
              elalquilador.com no se hace responsable por los daños y perjuicios
              de toda naturaleza que puedan deberse a la falta de disponibilidad
              o de continuidad en el funcionamiento de su Web y tampoco de los
              daños que los Usuarios hubiesen podido atribuir a los fallos y
              errores de la web.
            </p>
            <h3 className="text-md font-semibold mt-4">
              Exclusión de garantías y de responsabilidad por la utilización del
              Sitio Web de los servicios y de los contenidos por los Usuarios
            </h3>
            <p>
              elalquilador.com no puede garantizar que los Usuarios utilicen los
              servicios y contenidos de su Web de acuerdo con las presentes
              condiciones de uso condiciones particulares que resulten de
              aplicación ni con respeto a la legislación vigente ni que lo hagan
              de forma diligente y prudente. elalquilador.com tampoco puede
              garantizar la veracidad de la información suministrada por los
              usuarios ya sea en el registro o ya sea en la publicación de
              ofertas y demandas de servicio. Por tanto elalquilador.com no será
              responsable por los daños y perjuicios de cualquier naturaleza que
              derivados de la utilización de sus servicios y de los contenidos
              ofrecidos por parte de los Usuarios o que puedan deberse a la
              falta de veracidad vigencia o autenticidad de la información que
              estos proporcionan a otros Usuarios acerca de sí mismos y tampoco
              será responsable por los daños y perjuicios de cualquier índole
              que puedan deberse a la suplantación de la personalidad de un
              tercero efectuada por otro usuario o tercero en cualquier clase de
              comunicación o publicación de contenido realizada a través de la
              Web de elalquilador.
            </p>
            <p>
              elalquilador.com ha adoptado las medidas de seguridad necesarias
              para evitar fugas de información e incidencias y no puede hacerse
              responsable de que por error del usuario o por negligencia los
              Proveedores o solicitantes incluyan información personal en sus
              solicitudes de tal forma que se revelen datos de carácter
              personal. En estos casos serán los propios usuarios responsables
              de las posibles infracciones y sanciones que se pudieren derivar y
              mantendrán indemne a elalquilador.com frente a posibles
              reclamaciones de terceros por este motivo.
            </p>
            <p>
              elalquilador.com no puede ser responsable en ninguna circunstancia
              del uso que los usuarios hagan de la información recibida o
              suministrada. En caso de mal uso pérdida alteración manipulación
              revelación a personas no autorizadas de la información que las
              partes intercambien en la plataforma elalquilador.com no será
              responsable de los posibles daños que se produzcan.
            </p>
            <p>
              Igualmente elalquilador.com no será responsable por revelaciones
              de datos a terceros malos usos pérdidas alteraciones o accesos
              indebidos a la información que las partes disponen dentro de la
              aplicación por falta de observación de las medidas de seguridad y
              confidencialidad de estas en el uso de elalquilador.
            </p>
            <h3 className="text-md font-semibold mt-4">
              Exclusión de garantías sobre enlaces a otros sitios web
            </h3>
            <p>
              La web de elalquilador.com puede contener enlaces o hipervínculos
              a otras páginas web. elalquilador.com no es responsable de los
              contenidos de esas otras webs ni tampoco de su funcionamiento y
              usos. El Usuario debe ser consciente cuando abandona nuestro sitio
              web de que debe leer y revisar las condiciones generales y las
              políticas de privacidad de esos sitios de terceros.
            </p>
            <h3 className="text-md font-semibold mt-4">
              Exclusión de garantías por la cualificación profesional y la
              ejecución de los trabajos acordados
            </h3>
            <p>
              elalquilador.com no interviene en la negociación llevada a cabo
              entre las partes por lo que no será responsable en ningún caso del
              cumplimiento de las contraídas entre aquellas y por tanto se exime
              expresamente a elalquilador.com de cualquier desavenencia que
              pudiera surgir entre ambos.
            </p>
            <p>
              Son las partes las únicas responsables de la comprobación de la
              idoneidad de los servicios ofrecidos y que estos se adaptan a las
              necesidades del solicitante. Además es responsable del correcto y
              oportuno seguimiento del servicio contratado.
            </p>
            <p>
              elalquilador.com en ningún supuesto controlará ni intervendrá en
              la ejecución de los servicios ni en la adecuación calidad o
              resultado de los mismos por lo que elalquilador.com excluye con
              toda la extensión permitida por el ordenamiento jurídico cualquier
              responsabilidad por los daños y perjuicios de toda naturaleza que
              puedan deberse a la ejecución o resultado de los servicios
              prestados. Las partes eximen de forma expresa a elalquilador.com
              de cualquier discrepancia responsabilidad daño perjuicio o
              menoscabo como resultado de la contratación de un servicio.
            </p>
          </div>

          {/* DENUNCIA Y COMUNICACIÓN DE ACTIVIDADES ILÍCITAS O CONTRARIAS A LAS CONDICONES DE USO */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              15. DENUNCIA Y COMUNICACIÓN DE ACTIVIDADES ILÍCITAS O CONTRARIAS A
              LAS CONDICONES DE USO
            </h2>
            <p>
              En el caso de que cualquier Usuario o tercero considere que
              existen hechos o circunstancias que revelen el carácter ilícito de
              cualquier contenido en el Sitio Web o de su utilización deberá
              ponerse en contacto con elalquilador.com a través de
              datos@elalquilador.com o cualquier otro dato de contacto que
              aparezca en nuestro portal. En todo caso se deberá detallar la
              supuesta actividad ilícita llevada a cabo en el Portal.
            </p>
          </div>

          {/* DURACIÓN Y TERMINACIÓN */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              16. DURACIÓN Y TERMINACIÓN
            </h2>
            <p>
              La presentación de los servicios que elalquilador.com proporciona
              a través del Sitio Web tiene duración indefinida. En cualquier
              caso elalquilador.com podrá con preaviso poner fin de forma
              definitiva o provisional a la prestación de sus servicios.
            </p>
          </div>

          {/* LEGISLACIÓN APLICABLE Y JURISDICCIÓN */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              17. LEGISLACIÓN APLICABLE Y JURISDICCIÓN
            </h2>
            <p>
              El acceso y uso de elalquilador.com la interpretación cumplimiento
              y ejecución de las presentes condiciones de contratación (o
              términos de uso) se interpretarán conforme a lo establecido por
              las leyes españolas. En el caso de los consumidores y usuarios
              serán competentes siempre los juzgados y tribunales del domicilio
              del consumidor. En el caso de personas jurídicas y entidades
              comerciales o profesionales serán competentes los juzgados de
              Madrid.
            </p>
            <p>
              El consumidor dispone además de la posibilidad de acudir al
              servicio de resolución de disputas en línea de la Comisión
              Europea.
            </p>
          </div>

          {/* ENTRADA EN VIGOR Y MODIFICACIONES DE LAS CONDICIONES DE CONTRATACIÓN */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">
              18. ENTRADA EN VIGOR Y MODIFICACIONES DE LAS CONDICIONES DE
              CONTRATACIÓN
            </h2>
            <p>
              Estas Condiciones de Contratación entraron en vigor en fecha de 19
              de abril de 2024. elalquilador.com se reserva el derecho exclusivo
              a modificar corregir o actualizar de forma unilateral estas
              Condiciones en cualquier momento previa notificación a los
              Usuarios junto con la fecha de entrada en vigor en este mismo
              apartado.
            </p>
            <p>
              El Usuario debe visitar esta página con regularidad para estar al
              día de las modificaciones que se puedan producir.
            </p>
            <p>
              En caso de actualizaciones de estas condiciones y en caso de no
              ser aceptadas por el Usuario implicará que no pueda usar el
              servicio.
            </p>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Conditions;
