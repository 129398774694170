import React from "react";

function Button({ children, disabled, href, type, onClick, classButton }) {
  const styles = {
    primary:
      "bg-primary button flex disabled:opacity-50 items-center justify-center text-white rounded-full px-14 py-4 border-2 border-primary hover:bg-transparent hover:text-white transition-all ease-in-out duration-150",
    primaryWhite:
      "bg-primary button flex items-center justify-center text-white rounded-full px-14 py-4 border-2 border-primary hover:bg-transparent hover:text-primary transition-all ease-in-out duration-150",
    primaryBlack:
      "bg-primary button flex items-center justify-center text-white rounded-full px-14 py-4 border-2 border-primary hover:bg-transparent hover:border-primary hover:text-dark transition-all ease-in-out duration-150",
    primarySmall:
      "text-sm bg-primary button flex items-center justify-center text-white rounded-full px-9 py-2 border-2 border-primary hover:bg-transparent hover:border-primary hover:text-primary transition-all ease-in-out duration-500",
  };

  const combinedClasses = `${styles[type]} ${classButton || ""}`;

  if (href)
    return (
      <a href={href} className={combinedClasses}>
        {children}
      </a>
    );

  if (onClick)
    return (
      <button onClick={onClick} disabled={disabled} className={combinedClasses}>
        {children}
      </button>
    );

  return (
    <button disabled={disabled} className={combinedClasses}>
      {children}
    </button>
  );
}

export default Button;
