import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Errors = ({ isOpen, onClose, children, errors, widthModal = 'max-w-xl' }) => {
    const [modalOpen, setModalOpen] = useState(isOpen);
    const [status, setStatus] = useState();
    const [title, setTitle] = useState('Se ha producido un error');
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        setModalOpen(isOpen);

        if (errors instanceof Response) {
            setStatus(errors.status);
            errors.json().then(data => {
                if (data && data.errors) {
                    setErrorMessages(Object.values(data.errors).flat());
                    // console.log(errors.response)
                    setTitle(getErrorTitle(errors.status));
                }
            }).catch(error => {
                console.error('Error parsing JSON response:', error);
            });
        } else if (errors instanceof Error) {
            const statusCodeMatch = errors.message.match(/Status: (\d+)/);
            const statusCode = statusCodeMatch ? parseInt(statusCodeMatch[1], 10) : 422;
            setStatus(statusCode);
            setTitle(getErrorTitle(statusCode));

            if (statusCode === 422 && errors.details) {
                setErrorMessages(Array.isArray(errors.details) ? errors.details : [errors.details]);
            } else {
                setErrorMessages([errors.message]);
            }
        } else if (typeof errors === 'string') {
            setErrorMessages([errors]);
            setTitle('Error');
        }
    }, [isOpen, errors]);

    const getErrorTitle = (statusCode) => {
        switch (statusCode) {
            case 401:
                return 'Ha ocurrido un error';
            case 403:
                return 'Ha ocurrido un error';
            case 405:
                return 'Ha ocurrido un error';
            case 422:
                return 'Ha ocurrido un error';
            case 500:
                return 'Ha ocurrido un error';
            default:
                return 'Ha ocurrido un error';
        }
    };

    const closeModal = () => {
        console.log('closeModal en Errors llamado');
        setModalOpen(false);
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };
        if (modalOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalOpen]);

    if (!modalOpen) return null;

    const childrenArray = React.Children.toArray(children);
    const header = childrenArray.find(child => child.type === Errors.Header);
    const body = childrenArray.find(child => child.type === Errors.Body);

    return ReactDOM.createPortal(
        <div className="flex justify-center">
            <div role="dialog" aria-modal="true" className="fixed inset-0 z-[1000] overflow-y-auto">
                <div className="fixed inset-0 bg-black bg-opacity-50 "></div>
                <div className="relative flex items-center justify-center min-h-screen p-4">
                    <div className={`relative w-full rounded-xl bg-white p-4 shadow-xl ${widthModal}`}>
                        <div className="flex items-center justify-between">
                            <div className="font-bold">{header ? header : title}</div>
                            <button className="px-3 py-1 rounded-lg cursor-pointer hover:bg-gray-600 bg-gray" onClick={closeModal}>
                                &times;
                            </button>
                        </div>
                        <div className="py-4">
                            {body}
                            {errorMessages.length > 0 && (
                                <div className='flex flex-col gap-4'>
                                    <p>Por favor, corrígelo para poder continuar.</p>
                                    <div className='flex flex-col gap-0'>
                                        {errorMessages.map((error, index) => (
                                            <div key={index} className="text-primary rounded-xl">
                                                <p>{index + 1} - {error}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

Errors.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    errors: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string
    ]),
    widthModal: PropTypes.string,
};

Errors.Header = ({ children }) => <div className="modal-header">{children}</div>;
Errors.Body = ({ children }) => <div className="modal-body">{children}</div>;

Errors.Header.propTypes = {
    children: PropTypes.node.isRequired,
};

Errors.Body.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Errors;
