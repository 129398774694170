import React from 'react';

const Notification = ({ message, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className={`fixed bottom-4 left-1/2 transition transform -translate-x-1/2 bg-primary text-white p-4 rounded-md ${isVisible ? 'animate-slideUp' : ''}`}>
      {message}
    </div>
  );
};

export default Notification;