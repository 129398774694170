import React from "react";

function Legal() {
    return (
        <section className="text-left px-4 lg:px-20 py-14 md:py-24 lg:flex-col flex  gap-20">
            <section className=" px-4">
                <h1 className="pb-2 text-4xl text-left font-bold text-black md:text-4xl">
                    Aviso legal
                </h1>
                <div className="py-6 space-y-2">
                    <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                        Derecho de la información
                    </h2>
                    <p className="text-sm text-black text-opacity-60">
                        En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, le informamos que el Sitio Web es titularidad de la sociedad MyGarret SL, sociedad mercantil constituida de acuerdo con la legislación española, provista con CIF B42887570. Usted puede ponerse en contacto con nosotros a través de la siguiente dirección de correo electrónico datos@elgestionador.com, o si lo prefiere, a través del teléfono +34 911 935 181.
                    </p>
                </div>
                <div className="py-6 space-y-2">
                    <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                        Usuarios
                    </h2>
                    <p className="text-sm text-black text-opacity-60">
                        El acceso y/o uso al Sitio Web le atribuye la condición de Usuario, y acepta, desde dicho acceso y/o uso, el presente Aviso Legal y, en su caso, los cambios efectuados en el mismo.
                    </p>
                </div>
                <div className="py-6 space-y-2">
                    <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                        Uso del sitio web por parte de los usuarios
                    </h2>
                    <p className="text-sm text-black text-opacity-60">
                        El Sitio Web puede proporcionar acceso a multitud de textos, gráficos, dibujos, diseños, códigos, software, fotografías, música, vídeos, sonidos, bases de datos, imágenes, expresiones e informaciones (en adelante, “Contenidos”) pertenecientes a LA EMPRESA o a terceros a los que el Usuario puede tener acceso.
                    </p>
                    <p className="text-sm text-black text-opacity-60">
                        El Usuario asume la responsabilidad del uso del Sitio Web. Dicha responsabilidad se extiende al registro que, en su caso, sea necesario para acceder a los Contenidos del Sitio Web.
                    </p>
                    <p className="text-sm text-black text-opacity-60">
                        El Usuario se compromete a hacer un uso adecuado de los Contenidos ofrecidos a través del Sitio Web y con carácter enunciativo pero no limitativo, a no emplearlos para (i) incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público; (ii) provocar daños en los sistemas físicos y lógicos del titular del Sitio Web, de sus proveedores o de terceras personas, (iii) introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados.
                    </p>
                    <p className="text-sm text-black text-opacity-60">
                        legal.text3_4
                    </p>
                </div>
                <div className="py-6 space-y-2">
                    <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                        Propiedad intelectual del sitio web
                    </h2>
                    <p className="text-sm text-black text-opacity-60">
                        Todos los derechos sobre el Contenido, diseño y código fuente de este Sitio Web y, en especial, con carácter enunciativo pero no limitativo, todos los derechos sobre las fotografías, imágenes, textos, logotipos, diseños, marcas, nombres comerciales, datos que se incluyen en el Sitio Web y cualesquiera otros derechos de propiedad intelectual e industrial son titularidad de LA EMPRESA, o bien de terceros que han autorizado expresamente a LA EMPRESA para la utilización de los mismos en su Sitio Web.
                    </p>
                    <p className="text-sm text-black text-opacity-60">
                        Por ello y en virtud de lo dispuesto en Real Decreto Legislativo 1/1996, de 12 de abril, por el que se aprueba el texto refundido de la Ley de Propiedad Intelectual, regularizando, aclarando y armonizando las disposiciones legales vigentes sobre la materia, así como en la Ley 17/2001, de 7 de diciembre de Marcas y la legislación complementaria en materia de propiedad intelectual e industrial, queda expresamente prohibida la reproducción, transmisión, adaptación, traducción, distribución, comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los Contenidos del Sitio Web, en cualquier soporte y por cualquier medio técnico, salvo autorización expresa otorgada por escrito por parte de LA EMPRESA.
                    </p>
                    <p className="text-sm text-black text-opacity-60">
                        LA EMPRESA no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otra propiedad o derecho relacionado con el Sitio Web, y en ningún caso se entenderá que el acceso y navegación de los Usuarios implica una renuncia, transmisión, licencia o cesión total ni parcial de dichos derechos por parte de LA EMPRESA.
                    </p>
                    <p className="text-sm text-black text-opacity-60">
                        Cualquier uso de esos contenidos no autorizado previamente por parte de LA EMPRESA será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial y dará lugar a las responsabilidades legalmente establecidas.
                    </p>
                </div>
                <div className="py-6 space-y-2">
                    <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                        Responsabilidad y garantías
                    </h2>
                    <p className="text-sm text-black text-opacity-60">
                        LA EMPRESA declara que ha adoptado las medidas necesarias que, dentro de sus posibilidades y el estado de la tecnología, permitan el correcto funcionamiento de su Sitio Web así como la ausencia de virus y componentes dañinos. Sin embargo, LA EMPRESA no puede hacerse responsable de: (a) la continuidad y disponibilidad de los Contenidos; (b) la ausencia de errores en dichos Contenidos ni la corrección de cualquier defecto que pudiera ocurrir; (c) la ausencia de virus y/o demás componentes dañinos; (d) los daños o perjuicios que cause cualquier persona que vulnere los sistemas de seguridad de LA EMPRESA.
                    </p>
                    <p className="text-sm text-black text-opacity-60">
                        LA EMPRESA podrá suspender temporalmente y sin previo aviso, la accesibilidad al Sitio Web con motivo de operaciones de mantenimiento, reparación, actualización o mejora. No obstante, siempre que las circunstancias lo permitan, LA EMPRESA comunicará al Usuario, con antelación suficiente, la fecha prevista para la suspensión de los contenidos.
                    </p>
                    <p className="text-sm text-black text-opacity-60">
                        LA EMPRESA no se responsabiliza del uso que los usuarios puedan hacer de los Contenidos incluidos en el Sitio Web. En consecuencia, LA EMPRESA no garantiza que el uso que los usuarios puedan hacer de los Contenidos que en su caso se incluyan en el Sitio Web, se ajusten al presente Aviso Legal, ni que lo hagan de forma diligente.
                    </p>
                </div>
                <div className="py-6 space-y-2">
                    <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                        Hipervínculos
                    </h2>
                    <p className="text-sm text-black text-opacity-60">
                        El Sitio Web puede contener hipervínculos que permitan al Usuario acceder a páginas Web de terceros. LA EMPRESA no asume ninguna responsabilidad por el contenido, informaciones o servicios que pudieran aparecer en dichos sitios, que se entenderán ofrecidos exclusivamente con carácter informativo por parte de LA EMPRESA, y que en ningún caso implican relación, aceptación o respaldo alguno entre LA EMPRESA y las personas o entidades titulares de tales contenidos o titulares de los sitios donde se encuentren.
                    </p>
                </div>
                <div className="py-6 space-y-2">
                    <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                        Modificaciones
                    </h2>
                    <p className="text-sm text-black text-opacity-60">
                        Las condiciones del presente Aviso Legal estarán vigentes hasta que sean modificadas, pudiendo LA EMPRESA efectuar estos cambios que serán comunicados al Usuario.
                    </p>
                    <p className="text-sm text-black text-opacity-60">
                        LA EMPRESA podrá suprimir, añadir o cambiar tanto los Contenidos como la forma en la que los mismos aparezcan localizados o presentados. Se entienden como vigentes, las condiciones que estén publicadas en el momento en el que el usuario acceda al Sitio Web de LA EMPRESA.
                    </p>
                </div>
                <div className="py-6 space-y-2">
                    <h2 className="text-xl md:text-2xl pb-2 text-black font-bold">
                        Legislación aplicable y jurisdicción
                    </h2>
                    <p className="text-sm text-black text-opacity-60">
                        Los presentes Términos y Condiciones están sometidos a la legislación española. En caso de conflicto, las partes se someten a los Juzgados y Tribunales correspondientes al domicilio de LA EMPRESA, salvo que la ley prevea expresamente otra jurisdicción.
                    </p>
                </div>
            </section>
        </section>
    );
}

export default Legal;
