import React from 'react';

const TopBar = () => {
  return (
    <div className="items-center justify-center hidden w-screen px-4 py-2 text-base text-center text-white bg-primary md:flex">
      ¡Si necesitas ayuda, llama o escribe gratis a nuestros expertos al <a href="tel:+34613259533">&nbsp;613 259 533</a>!
    </div>
  );
}

export default TopBar;