import React, { useEffect, useState } from "react";
import Menu from "../../components/private/Menu";
import { useAuth } from "../../hooks/AuthProvider";
import Input from "../../components/ui/Input";
import Modal2 from "../../components/Modal2";
import Errors from "../../components/Errors";
import { useError } from "../../helpers/ErrorContext";
import { set } from "date-fns";

const Addresses = () => {
    const initialAddress = {
        address_id: "",
        label: "",
        recipient: "",
        address: "",
        town: "",
        zip: "",
        province: "",
        country_id: '212',
        fiscal: 0,
        default: 0,
    };
    const [modalDeleteAddress, setModalDeleteAddress] = useState(null);
    const [fetchErrors, setFetchErrors] = useState([]);
    const [errorsMessages, setErrorsMessages] = useState({});
    const [errors, setErrors] = useState({});
    const { token, isAuthenticated } = useAuth();
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState(null);
    const [modalErrors, setModalErrors] = useState(false);
    const [isThirdpartyNull, setIsThirdpartyNull] = useState(false);
    const { setError } = useError();

    function validateAddress(data) {
        let errors = {};
        if (!data.label) {
            errors.label = 'Indique un nombre para identificar la dirección.';
        }
        if (!data.recipient) {
            errors.recipient = 'Indique un destinatario';
        }
        if (!data.data) {
            errors.data = 'Indique una dirección.';
        }
        if (!data.province) {
            errors.province = 'Indique una provincia.';
        }
        if (!data.town) {
            errors.town = 'Indique una población.';
        }
        if (!data.zip) {
            errors.zip = 'Indique un código postal.';
        }
        else if (!/^\d{4,5}$/.test(data.zip)) {
            errors.zip = 'El c.p tiene que ser un número de 4 o 5 dígitos';
        }
        return errors;
    }

    const urlParams = [
        ["with[0]", "favorite_products"],
        ["with[1]", "thirdparty.fiscal_address"],
        ["with[2]", "thirdparty.addresses"],
    ];
    const paramString = urlParams.map(([param, value]) => `${param}=${value}`).join("&");
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const fetchGetAddresses = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/user?${paramString}`, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const userData = await response.json();
            if (!response.ok) {
                const errorMessages = Object.values(userData.errors)
                .flat()
                .join(', ');
                throw new Error(errorMessages);
            }
            if (userData.thirdparty === null) {
                setIsThirdpartyNull(true);
            } else {
                setAddresses(userData.thirdparty.addresses);
                setIsThirdpartyNull(false);
            }
        } catch (error) {
            setError(error);
        }
    };
    const fetchAddAddress = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/addresses`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(address)
            });
            const userData = await response.json();
            if (!response.ok) {
                const errorMessages = Object.values(userData.errors)
                .flat()
                .join(', ');
                throw new Error(errorMessages);
            }
            setAddress(null);
            fetchGetAddresses();
        } catch (error) {
            setError(error);
        }
    };
    const fetchDeleteAddress = async (address_id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/addresses/${address_id}`, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const responseData = await response.json();
            if (!response.ok) {
                const errorMessages = Object.values(responseData.errors)
                .flat()
                .join(', ');
                throw new Error(errorMessages);
            }
            fetchGetAddresses();
            setModalDeleteAddress(null);
        } catch (error) {
            setError(error);
        }
    };
    const fetchUpdateAddress = async (address_id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/addresses/${address_id}`, {
                method: "PUT",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(address)
            });
            const responseData = await response.json();
            if (!response.ok) {
                const errorMessages = Object.values(responseData.errors)
                .flat()
                .join(', ');
                throw new Error(errorMessages);
            }
            setAddress(null);
            fetchGetAddresses();
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchGetAddresses();
        }
    }, [isAuthenticated, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddress((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setAddress((prevData) => ({
            ...prevData,
            [name]: checked ? 1 : 0
        }));
    };

    const handleAddAddress = async (e) => {
        fetchAddAddress(e);
    };

    const handleDeleteAddress = (address_id) => {
        fetchDeleteAddress(address_id);
    }
    const handleUpdateAddress = (address_id) => {
        fetchUpdateAddress(address_id);
    }

    useEffect(() => {
        if (fetchErrors && fetchErrors.status > 400 && fetchErrors.status < 500) {
            setModalErrors(true)
        }
    }, [fetchErrors]);

    return (
        <div className="p-[15px] md:p-[35px] flex flex-col md:flex-row gap-8 md:gap-24 min-h-[calc(100vh-135px)]">
            <Menu position="addresses" />
            <div className="w-full">
                <h2 className="mb-2 text-3xl font-bold text-left text-primary">mis direcciones</h2>

                {isThirdpartyNull ? (
                    <div className="flex flex-col w-full h-full gap-4 p-8 text-left bg-white rounded-xl">
                        <p>Para añadir una dirección primero debes dar de alta tu dirección fiscal pinchando <a href="/perfil" className="text-blue-600 underline">aquí</a>.</p>
                    </div>
                ) : (
                    <div className="flex flex-col w-full h-full gap-4 p-8 text-left bg-white rounded-xl">
                        <div className="flex items-center justify-between">
                            <div>Tienes {addresses.length} {addresses.length > 1 ? 'direcciones' : 'dirección'} guardadas</div>
                            <div>
                                <button onClick={() => setAddress(initialAddress)} className="px-4 py-1 text-white rounded-lg bg-primary hover:bg-primary-700">Añadir</button>
                            </div>
                        </div>
                        {address && (
                            <div className="flex flex-col gap-4 p-4 bg-light rounded-xl">
                                <div className="flex flex-row items-center justify-between">
                                    <span className="text-xl font-bold text-primary">
                                        {address.id ? 'Editar dirección' : 'Nueva dirección'}
                                    </span>
                                    <div className="flex gap-2">
                                        <button onClick={() => setAddress(null)} className="px-4 py-1 text-white bg-gray-600 rounded-lg hover:bg-gray-800">Cancelar</button>
                                        {address.id ? (
                                            <button onClick={() => handleUpdateAddress(address.id)} className="px-4 py-1 text-white rounded-lg bg-primary hover:bg-primary-700">Guardar</button>
                                        ) : (
                                            <button onClick={handleAddAddress} className="px-4 py-1 text-white rounded-lg bg-primary hover:bg-primary-700">Crear</button>
                                        )}
                                    </div>
                                </div>
                                <form className="contents">
                                    <div className="flex flex-col gap-5 md:flex-row">
                                        <input type="hidden" name="country_id" value="212" />
                                        <Input label="Etiqueta para la dirección" id="label" name="label" required={true} placeholder="escriba la dirección" value={address.label} onChange={handleChange} width="w-full md:w-3/12" />
                                        <Input label="Destinatario" id="recipient" name="recipient" required={true} placeholder="escriba la empresa o destinatarios" value={address.recipient} onChange={handleChange} width="w-full md:w-6/12" />
                                    </div>
                                    <div className="flex flex-col gap-5 md:flex-row">
                                        <Input label="Dirección" id="address" name="address" required={true} placeholder="escriba la dirección" value={address.address} onChange={handleChange} width="w-full md:w-3/5" />
                                        <Input label="Provincia" id="province" name="province" required={true} placeholder="provincia" value={address.province} onChange={handleChange} width="w-full md:w-2/5" />
                                    </div>
                                    <div className="flex flex-col gap-5 md:flex-row">
                                        <Input label="C.P" id="zip" name="zip" required={true} placeholder="c.p" value={address.zip} onChange={handleChange} width="w-full md:w-1/5" />
                                        <Input label="Población" id="town" name="town" required={true} placeholder="población" value={address.town} onChange={handleChange} width="w-full md:w-4/5" />
                                    </div>
                                    <div className="flex flex-col gap-5 md:flex-row">
                                        <div className="flex items-center gap-2">
                                            <input type="checkbox" className="w-4 h-4 text-sm rounded-lg cursor-pointer" name="fiscal" id="fiscal" checked={address.fiscal === 1} onChange={handleCheckboxChange} />
                                            <label className="cursor-pointer" htmlFor="fiscal">Fiscal</label>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <input type="checkbox" className="w-4 h-4 text-sm rounded-lg cursor-pointer" name="default" id="default" checked={address.default === 1} onChange={handleCheckboxChange} />
                                            <label className="cursor-pointer" htmlFor="default">Predeterminada</label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                        {addresses.length > 0 ? (
                            <div className="flex flex-col gap-4">
                                <div className="grid grid-cols-2 gap-4">
                                    {addresses.map((address, index) => (
                                        <div key={index} className="flex flex-col gap-4 p-4 bg-light rounded-xl">
                                            <div className="flex flex-row items-center justify-between">
                                                <div className="flex gap-3">
                                                    {address.label && <span className="px-4 py-1 bg-white border rounded-xl">{address.label}</span>}
                                                </div>
                                                <div className="flex gap-2">
                                                    {address.fiscal !== 1 &&
                                                        <button id="btnDeleteAddress" className="px-2 py-1 text-white bg-gray-600 rounded-lg t justify-centerext-center hover:bg-gray-800" onClick={() => setModalDeleteAddress(address)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                            </svg>
                                                        </button>
                                                    }
                                                    <button id="btnEditAddress" className="px-2 py-1 text-white bg-gray-600 rounded-lg t justify-centerext-center hover:bg-gray-800" onClick={() => setAddress(address)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex flex-row gap-4">
                                                <div className="w-1/2">
                                                    <p className="font-bold text-primary">Dirección</p>
                                                    <p>{address.recipient}</p>
                                                    <p>{address.address}</p>
                                                    {address.province}
                                                    <p>{address.zip} - {address.town}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-row justify-end gap-4">
                                                {address.fiscal === 1 && <div className="px-4 py-1 text-white rounded-lg bg-primary">Fiscal</div>}
                                                {address.default === 1 && <div className="px-4 py-1 text-white rounded-lg bg-primary">Predeterminada</div>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col gap-4 p-4 bg-light rounded-xl">
                                <p>No tienes direcciones guardadas.</p>
                            </div>
                        )}
                    </div>
                )}

            </div>

            {modalDeleteAddress && (
                <Modal2 isOpen={modalDeleteAddress} onClose={() => setModalDeleteAddress(null)} widthModal="max-w-xl">
                    <Modal2.Header>Eliminar dirección guardada</Modal2.Header>
                    <Modal2.Body>
                        <div className="py-4 flex flex-col gap-5">
                            <p>¿Esta seguro de eliminar la dirección?</p>
                            <div className="flex flex-col gap-1">
                                <span>{modalDeleteAddress.label}</span>
                                <span>{modalDeleteAddress.address}</span>
                                <span>{modalDeleteAddress.province}</span>
                                <span>{modalDeleteAddress.zip} - {modalDeleteAddress.town}</span>
                            </div>
                        </div>

                    </Modal2.Body>
                    <Modal2.Footer>
                        <div className="flex justify-end">
                            <button className="flex gap-2 px-4 py-1.5 bg-red-50 text-red-900 rounded-xl" onClick={() => handleDeleteAddress(modalDeleteAddress.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                                <span>Eliminar</span>
                            </button>
                        </div>
                    </Modal2.Footer>
                </Modal2>
            )}

            <Errors isOpen={modalErrors} widthModal='max-w-3xl' errors={fetchErrors}>
                <Errors.Body></Errors.Body>
            </Errors>

        </div>
    );
};

export default Addresses;
