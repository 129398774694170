import React from 'react';
import{ useEffect, useState } from 'react';
import {is, DataEncoder} from '../utils/common';
import { useFilterContext } from '../helpers/FilterContext';
import { set } from 'date-fns';

function FilterInputList({ filter, filtersContent, fetchedFilters }) {
  const { state, dispatch } = useFilterContext();
  let list = [];
  // const [list, setList] = useState(filtersContent.values.filter[0]);
  if(filter[0] === 'propulsion'){
    list = filtersContent.values.propulsion;
  }
  else if(filter[0] === 'use_type'){
    list = filtersContent.values.use_type;
  }
  const [selectedItem, setSelectedItem] = useState(null); // Nuevo estado para el elemento seleccionado

  function handleListClick(item){
    setSelectedItem(item); // Actualiza el elemento seleccionado cuando se hace clic en un elemento
    if(filter[0] === 'propulsion' ){
      dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: 'propulsion', values: [9, item] } }); // Actualiza los filtros con el valor seleccionado
    }
    else if(filter[0] === 'use_type'){
      dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: 'use_type', values: item } }); // Actualiza los filtros con el valor seleccionado
    }
  }



  // useEffect(() => {
  //   if(boolean){
  //     dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filterId, values: filter[1][1] } });
  //   }
  //   else{
  //     dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filterId, values: '' } });
  //   }
  // }, [boolean]);
  return (
    <div className="flex items-center py-4 flex-col">
      <div className='w-full flex flex-row flex-wrap gap-x-4 mt-4'>
        {
          list &&
            list.map((item, index) => {
              return(
                <div key={item} className='w-[100%]'>
                  <span 
                    className={`w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter ${selectedItem === item ? 'checked' : ''}`} 
                    onClick={() => handleListClick(item)}
                  ></span>
                  <input type="checkbox" className='hidden' name="" id="" checked={selectedItem === item} readOnly/>
                  <label htmlFor="" className='text-sm'>{item}</label>
                </div>
              )
            })
        }
      </div>
    </div>
);
}

export default FilterInputList;
