import { DataEncoder } from "../utils/common";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchProducts = async (params, setError) => {
  const encoder = new DataEncoder();
  const url = `${API_BASE_URL}/products?${encoder.encode(params)}`;
  try {
    const response = await fetch(encodeURI(url));
    if (!response.ok) {
      throw response;
    }
    return response.json();
  } catch (error) {
    setError(error);
    throw new Error('Error al cargar los productos');
  }
};

export const fetchProductById = async (id, paramString, setError) => {
  const url = `${API_BASE_URL}/products/${id}?${paramString}`;
  try {
    const response = await fetch(encodeURI(url));
    if (!response.ok) {
      throw response;
    }
    return response.json();
  } catch (error) {
    setError(error);
    throw new Error('Error al cargar el producto');
  }
};

export const fetchHolidays = async (offerId, setError) => {
  const today = new Date();
  const formattedStartDate = today.toISOString().split("T")[0];
  const twoYearsFromNow = new Date(today);
  twoYearsFromNow.setFullYear(today.getFullYear() + 2);
  const formattedEndDate = twoYearsFromNow.toISOString().split("T")[0];

  const queryParams = new URLSearchParams({
    "filters[offer_id]": offerId,
    "filters[from_date]": formattedStartDate,
    "filters[to_date]": formattedEndDate,
  }).toString();

  const url = `${API_BASE_URL}/products-offers/busy-dates?${queryParams}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw response;
    }
    return response.json();
  } catch (error) {
    setError(error);
    throw new Error('Error en la solicitud de días festivos y reservas');
  }
};

export const fetchShippingInfo = async (offerId, zip, storePickup, storeDelivery, setError) => {
  const queryParams = new URLSearchParams({
    offer_id: offerId,
    zip: zip,
    store_pickup: storePickup ? "0" : "1",
    store_delivery: storeDelivery ? "0" : "1",
  });

  const url = `${API_BASE_URL}/products-offers/shipping?${queryParams.toString()}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw response;
    }
    return response.json();
  } catch (error) {
    setError(error);
    throw new Error(`HTTP error! Status: ${error.status}`);
  }
};

export const fetchProductDetails = async (id, paramString, setError) => {
  const url = `${API_BASE_URL}/products/${id}?${paramString}`;
  try {
    const response = await fetch(encodeURI(url));
    if (!response.ok) {
      throw response;
    }
    return response.json();
  } catch (error) {
    setError(error);
    throw new Error('Error al cargar los detalles del producto');
  }
};

export const fetchUserFavorites = async (token, setError) => {
  const url = `${API_BASE_URL}/user?with[]=favorite_products`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw response;
    }
    const data = await response.json();
    return data.favorite_products;
  } catch (error) {
    setError(error);
    throw new Error('Failed to fetch user data');
  }
};

export const toggleFavoriteProduct = async (token, productId, isFavorite, setError) => {
  const method = isFavorite ? 'DELETE' : 'POST';
  const url = `${API_BASE_URL}/user/products/favorites/${productId}`;

  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const result = await response.json();
      throw new Error(`Failed to modify favorite. Status: ${response.status}, Message: ${result.message}`);
    }
    return response.json();
  } catch (error) {
    setError(error);
    throw new Error('Failed to modify favorite product');
  }
};
