import "./styles/App.css";
import "./styles/tailwind.css";
import "./styles/index.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./pages/Home";
import TopBar from "./components/TopBar";
import NavbarSticky from "./components/NavbarSticky";
import NewsletterSection from "./components/NewsletterSection";
import AssistantAvatar from "./components/AssistantAvatar";
import ScrollToTopButton from "./components/ScrollToTop";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import PDP from "./pages/PDP";
import PDPComprar from "./pages/PDPComprar";
import Plp from "./pages/Plp";
import Test from "./pages/Test";
import HowWorks from "./pages/HowWorks";
import AboutUs from "./pages/AboutUs";
import Thanks from "./pages/ThanksPage";
import Team from "./pages/Team";
import Sustainability from "./pages/Sustainability";
import Suppliers from "./pages/Suppliers";
import Advantages from "./pages/Advantages";
import Expert from "./pages/Expert";
import Videotutorials from "./pages/Videotutorials";
import Checkout from "./pages/Checkout";
import { AuthProvider } from "./hooks/AuthProvider";
import ClientArea from "./pages/private/ClientArea";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Register from "./pages/Register";
import PrivateRoute from "./routes/PrivateRoute";
import Account from "./pages/private/Account";
import Addresses from "./pages/private/Addresses";
import Orders from "./pages/private/Orders";
import Payments from "./pages/private/Payments";
import Security from "./pages/private/Security";
import Favorites from "./pages/private/Favorites";
import Plp2 from "./pages/Plp2";
import Legal from "./pages/Legal";
import Posts from "./pages/Posts";
import Post from "./pages/Post";
import Conditions from "./pages/Conditions";
import Cookies from "./pages/Cookies";
import Policy from "./pages/Policy";
import { CartDrawerProvider } from "./helpers/CartDrawerContext";
import { DrawerPlacement } from "./components/CartDrawer";
import { FilterProvider } from "./helpers/FilterContext";
import { PostsProvider } from "./helpers/PostsContext";
import { ErrorProvider } from "./helpers/ErrorContext";
import ErrorModal from "./components/ErrorModal";
import NotFound from "./pages/NotFound";
import { NotificationProvider } from "./helpers/NotificationContext";
import { Notification } from "./components/ui/Notification";
import Subvenciones from "./pages/Subvenciones";
import { DataLayerProvider } from "./context/DataLayerContext";
import CookieConsentBanner from "./components/CookieConsentBanner";
import VerifyEmail from "./pages/VerifyEmail";
import Faqs from "./pages/Faqs";

function App() {
  return (
    <div className="App">
      <ErrorProvider>
        <AuthProvider>
          <DataLayerProvider>
            <CartDrawerProvider>
              <FilterProvider>
                <PostsProvider>
                  <NotificationProvider>
                    <HelmetProvider>
                      <div className="font-sans antialiased bg-primary">
                        <DrawerPlacement />
                        <AppHeader />
                        <main className="w-screen bg-light">
                          <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/alquiler-de/:id" element={<PDP />} />
                            <Route path="/alquiler" element={<Plp2 />} />
                            <Route path="/alquiler/:slug?" element={<Plp2 />} />
                            <Route
                              path="/productos-marca/:slug?"
                              element={<Plp2 />}
                            />
                            <Route
                              path="/comprar/:id"
                              element={<PDPComprar />}
                            />
                            <Route
                              path="/como-funcionamos"
                              element={<HowWorks />}
                            />
                            <Route
                              path="/quienes-somos"
                              element={<AboutUs />}
                            />
                            <Route
                              path="/trabaja-con-nosotros"
                              element={<Team />}
                            />
                            <Route
                              path="/sostenibilidad"
                              element={<Sustainability />}
                            />
                            <Route
                              path="/proveedores"
                              element={<Suppliers />}
                            />
                            <Route
                              path="/motivos-para-alquilar"
                              element={<Advantages />}
                            />
                            <Route
                              path="/escribe-a-nuestro-experto"
                              element={<Expert />}
                            />
                            <Route
                              path="/videotutoriales"
                              element={<Videotutorials />}
                            />
                            <Route path="/test/:id" element={<Test />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/checkout" element={<Checkout />} />
                            <Route path="/gracias" element={<Thanks />} />
                            <Route path="/blog" element={<Posts />} />
                            <Route path="/blog/:slug" element={<Posts />} />
                            <Route path="/blog/:slug/:id" element={<Post />} />
                            <Route path="/guias" element={<Posts />} />
                            <Route path="/guias/:slug" element={<Posts />} />
                            <Route path="/guias/:slug/:id" element={<Post />} />
                            <Route path="/aviso-legal" element={<Legal />} />
                            <Route
                              path="/politica-de-privacidad"
                              element={<Policy />}
                            />
                            <Route
                              path="/politica-cookies"
                              element={<Cookies />}
                            />
                            <Route
                              path="/condiciones-de-uso"
                              element={<Conditions />}
                            />
                            <Route
                              path="/subvenciones"
                              element={<Subvenciones />}
                            />
                            <Route
                              path="/preguntas-frecuentes"
                              element={<Faqs />}
                            />
                            <Route
                              path="/verify-email"
                              element={<VerifyEmail />}
                            />

                            <Route
                              path="/forgot-password"
                              element={<ForgotPassword />}
                            />
                            <Route
                              path="/reset-password/"
                              element={<ResetPassword />}
                            />
                            <Route element={<PrivateRoute />}>
                              <Route
                                path="/area-cliente"
                                element={<ClientArea />}
                              />
                              <Route path="/perfil" element={<Account />} />
                              <Route
                                path="/direcciones"
                                element={<Addresses />}
                              />
                              <Route path="/pedidos" element={<Orders />} />
                              <Route
                                path="/metodos-de-pago"
                                element={<Payments />}
                              />
                              <Route path="/seguridad" element={<Security />} />
                              <Route
                                path="/favoritos"
                                element={<Favorites />}
                              />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                          </Routes>
                          <NewsletterSection />
                          <ScrollToTopButton />
                          <AssistantAvatar />
                          <ErrorModal />
                          <CookieConsentBanner />
                        </main>
                        <Footer />
                      </div>
                    </HelmetProvider>
                  </NotificationProvider>
                </PostsProvider>
              </FilterProvider>
            </CartDrawerProvider>
          </DataLayerProvider>
        </AuthProvider>
      </ErrorProvider>
    </div>
  );
}

function AppHeader() {
  const location = useLocation();
  const isCheckoutPage = location.pathname === "/checkout";
  if (isCheckoutPage) {
    return null;
  }
  return (
    <>
      <TopBar />
      <NavbarSticky />
    </>
  );
}

export default App;
