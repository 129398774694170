import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToHash({ targetHashes }) {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash && targetHashes.includes(hash)) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash, targetHashes]);

  return null;
}

export default ScrollToHash;
