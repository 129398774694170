import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Register() {
	const [customerType, setCustomerType] = useState('');
	const location = useLocation();
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
	const [title, setTitle] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showRepeatPassword, setShowRepeatPassword] = useState(false);

	function handleShowPassword() {
		setShowPassword(!showPassword);
	}

	function handleShowRepeatPassword() {
		setShowRepeatPassword(!showRepeatPassword);
	}

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		setCustomerType(params.get('customer_type'));

	}, [location]);

	useEffect(() => {
		if (customerType === 'company') {
			setTitle('empresa');
		} else if (customerType === 'bussines') {
			setTitle('autónomo');
		} else {
			setTitle('particular');
		}
	}, [customerType]);

	const [data, setData] = useState({
		email: '',
		email_confirmation: '',
		password: '',
		password_confirmation: '',
		name: '',
		surname: '',
		check_legal: false,
		check_comercial: false
	});

	const isFormValid = () => {
		return data.email !== '' &&
			data.email_confirmation !== '' &&
			data.password !== '' &&
			data.password_confirmation !== '' &&
			data.name !== '' &&
			data.surname !== '' &&
			data.check_legal === true;
	};
	const [errors, setErrors] = useState({});
	const [errorsMessages, setErrorsMessages] = useState({});


	function validateInputs(inputs) {
		let errors = {};
		if (!inputs.name.trim()) {
			errors.name = 'El nombre es obligatorio.';
		}
		if (!inputs.surname) {
			errors.surname = 'El apellido es obligatorio.';
		}
		if (!inputs.email) {
			errors.email = 'El email es obligatorio.';
		} else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
			errors.email = 'Email no válido.';
		}
		if (!inputs.email_confirmation) {
			errors.email_confirmation = 'Confirmar email es obligatorio.';
		} else if (inputs.email_confirmation !== inputs.email) {
			errors.email = 'Los emails no coinciden.';
		}
		if (!inputs.password) {
			errors.password = 'La contraseña es obligatoria.';
		}
		if (!inputs.password_confirmation) {
			errors.password_confirmation = 'Confirmar contraseña es obligatoria.';
		}
		if (!inputs.check_legal) {
			errors.check_legal = 'Debe aceptar las condiciones de uso.';
		}
		return errors;
	}

	function handleRegister(event) {
		event.preventDefault();
		const validationErrors = validateInputs(data);
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
		} else {
			// Enviar datos del formulario
			setErrors({});
			fetch(`${API_BASE_URL}/register`, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			}).then(response => {
				return response.json().then(data => ({ status: response.status, body: data }));
			}).then(result => {
				if (result.status === 200) {
					localStorage.setItem('userToken', result.body.auth_token);
					window.location.href = '/area-cliente';
				} else if (result.status === 422) {
					const errors = result.body.errors || {};
					setErrorsMessages(errors);
				} else if (result.status === 500) {
					setErrorsMessages(errors);
				} else {
					setErrorsMessages(errors);
				}
			}).catch(error => {
				console.error('Error:', error);
			});
		}
	}



	return (
		<div className='h-[calc(100vh-100px)] flex justify-center items-center flex-col'>
			<h1 className='text-3xl font-bold text-primary'>registro {title}</h1>
			<form onSubmit={handleRegister} className='flex w-full mt-12'>
				<div className='flex flex-col items-center justify-center w-full gap-4 px-3 mx-auto md:w-2/5 md:px-0'>
					<div className='flex flex-col w-full gap-4 md:flex-row'>
						<div className='flex flex-col w-full gap-1 md:w-1/3'>
							<input
								type="text"
								className='w-full px-4 py-4 transition-all duration-150 ease-in-out rounded-full focus:outline-none focus:border focus:border-primary'
								value={data.name}
								onChange={(e) => setData({ ...data, name: e.target.value })}
								placeholder="nombre"
							/>
							{errors.name && <p className="w-full text-xs text-right text-red-500">{errors.name}</p>}
						</div>
						<div className='flex flex-col w-full gap-1 md:w-2/3'>
							<input
								type="text"
								className='w-full px-4 py-4 transition-all duration-150 ease-in-out rounded-full focus:outline-none focus:border focus:border-primary'
								value={data.surname}
								onChange={(e) => setData({ ...data, surname: e.target.value })}
								placeholder="apellidos"
							/>
							{errors.surname && <p className="w-full text-xs text-right text-red-500">{errors.surname}</p>}
						</div>
					</div>
					<div className='flex flex-col w-full gap-4 md:flex-row'>
						<div className='flex flex-col w-full gap-1 md:w-1/2'>
							<input
								type="text"
								className='w-full px-4 py-4 transition-all duration-150 ease-in-out rounded-full focus:outline-none focus:border focus:border-primary'
								value={data.email}
								onChange={(e) => setData({ ...data, email: e.target.value })}
								placeholder="email"
							/>
							{errors.email && <p className="w-full text-xs text-right text-red-500">{errors.email}</p>}
						</div>
						<div className='flex flex-col w-full gap-1 md:w-1/2'>
							<input
								type="text"
								className='w-full px-4 py-4 transition-all duration-150 ease-in-out rounded-full focus:outline-none focus:border focus:border-primary'
								value={data.email_confirmation}
								onChange={(e) => setData({ ...data, email_confirmation: e.target.value })}
								placeholder="repite tu email"
							/>
							{errors.email_confirmation && <p className="w-full text-xs text-right text-red-500">{errors.email_confirmation}</p>}
						</div>
					</div>
					<div className='flex flex-col w-full gap-4 md:flex-row'>
						<div className='flex flex-col w-full gap-1 md:w-1/2'>
							<div className='flex justify-center items-center relative'>
								<input
									type={`${showPassword ? 'text' : 'password'}`}
									className='w-full px-4 py-4 transition-all duration-150 ease-in-out rounded-full focus:outline-none focus:border focus:border-primary'
									value={data.password}
									onChange={(e) => setData({ ...data, password: e.target.value })}
									placeholder="contraseña"
								/>
								<span className='text-primary text-xs absolute right-4 cursor-pointer' onClick={handleShowPassword}>
									Mostrar
								</span>
							</div>
							{errors.password && <p className="w-full text-xs text-right text-red-500">{errors.password}</p>}
						</div>
						<div className='flex flex-col w-full gap-1 md:w-1/2'>
						<div className='flex justify-center items-center relative'>
							<input
								type={`${showRepeatPassword ? 'text' : 'password'}`}
								className='w-full px-4 py-4 transition-all duration-150 ease-in-out rounded-full focus:outline-none focus:border focus:border-primary'
								value={data.password_confirmation}
								onChange={(e) => setData({ ...data, password_confirmation: e.target.value })}
								placeholder="repite tu contraseña"
							/>
							<span className='text-primary text-xs absolute right-4 cursor-pointer' onClick={handleShowRepeatPassword}>
								Mostrar
							</span>
						</div>
							{errors.password_confirmation && <p className="w-full text-xs text-right text-red-500">{errors.password_confirmation}</p>}
						</div>
					</div>
					<div className='flex flex-col items-center gap-2'>
						<div className='flex gap-3'>
							<div className='flex flex-col w-full gap-1 md:w-full'>
								<div className='flex gap-3'>
									<input type="checkbox"
										className='w-4 h-4 text-sm rounded-lg cursor-pointer'
										name="check_legal"
										id="check_legal"
										checked={data.check_legal}
										onChange={(e) => setData({ ...data, check_legal: e.target.checked })}
										value="1" />
									<label className="cursor-pointer" htmlFor="check_legal">acepto las <a href="/condiciones-de-uso" className="underline cursor">condiciones de uso</a></label>
								</div>
								{errors.check_legal && <p className="w-full text-xs text-right text-red-500">{errors.check_legal}</p>}
							</div>
						</div>
						<div className='flex items-center gap-3'>
							<input type="checkbox"
								className='w-4 h-4 text-sm rounded-lg cursor-pointer'
								name="check_comercial"
								id="check_comercial"
								checked={data.check_comercial}
								onChange={(e) => setData({ ...data, check_comercial: e.target.checked })}
								value="1" />
							<label className="cursor-pointer" htmlFor="check_comercial"> acepto  <a href="#" className="underline">comunicaciones comerciales</a></label>
						</div>
					</div>
					<button
						type="submit"
						disabled={!isFormValid()}
						className='bg-primary w-[300px] disabled:opacity-50 button flex items-center justify-center gap-2 text-white stroke-white rounded-full px-24 py-4 hover:bg-opacity-90 transition-all ease-in-out duration-150'>
						registrarme
					</button>
					<div className='flex gap-2'>
						<span>¿ya tienes cuenta?</span>
						<a href="/login" className="underline cursor-pointer text-primary">inicia sesión aquí</a>
					</div>
					{errorsMessages ? (
						<div>
							{Object.keys(errorsMessages).length > 0 && (
								<div>
									{Object.entries(errorsMessages).map(([key, value], index) => (
										<p key={index} className="w-full text-xs text-center text-red-500"> {value.join(', ')}</p>
									))}
								</div>
							)}
						</div>
					) : 'no errores'}
				</div>
			</form >
		</div >
	)
}

export default Register;