import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Modal2 = ({ isOpen, onClose, children, widthModal = 'max-w-xl' }) => {
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Escape') {
				onClose();
			}
		};

		if (isOpen) {
			window.addEventListener('keydown', handleKeyDown);
		} else {
			window.removeEventListener('keydown', handleKeyDown);
		}

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, onClose]);

	if (!isOpen) return null;

	const childrenArray = React.Children.toArray(children);
	const header = childrenArray.find(child => child.type === Modal2.Header);
	const body = childrenArray.find(child => child.type === Modal2.Body);
	const footer = childrenArray.find(child => child.type === Modal2.Footer);

	return ReactDOM.createPortal(
		<div className="flex justify-center">
			<div role="dialog" aria-modal="true" className="fixed inset-0 z-[1000] overflow-y-auto">
				{/* Overlay */}
				<div className="fixed inset-0 bg-black bg-opacity-50 "></div>
				{/* Panel */}
				<div className="relative flex min-h-screen items-center justify-center p-4">
					<div className={`relative w-full rounded-xl bg-white p-4 shadow-xl ${widthModal}`}>
						<div className="flex items-center justify-between">
							<div>{header}</div>
							<button className="px-3 cursor-pointer py-1 rounded-lg hover:bg-gray-600  bg-gray" onClick={onClose}>
								&times;
							</button>
						</div>
						<div>
							{body}

						</div>
						<div>
							{footer}
						</div>

					</div>
				</div>
			</div>
		</div >,
		document.body
	);
};

Modal2.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	widthModal: PropTypes.string,
};

// Sub-components for header, body, and footer
Modal2.Header = ({ children }) => <div className="modal-header">{children}</div>;
Modal2.Body = ({ children }) => <div className="modal-body">{children}</div>;
Modal2.Footer = ({ children }) => <div className="modal-footer">{children}</div>;

// PropTypes for sub-components
Modal2.Header.propTypes = {
	children: PropTypes.node.isRequired,
};

Modal2.Body.propTypes = {
	children: PropTypes.node.isRequired,
};

Modal2.Footer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Modal2;