import React from 'react';
import { useEffect, useState } from 'react';
import { is, DataEncoder } from '../../utils/common';
import { useFilterContext } from '../../helpers/FilterContext';
import { set } from 'date-fns';

function FilterBrands({ filter, filtersContent, fetchedFilters }) {
  //filter[1][1] = 'Autocomplete:brands' - Lets get content after the :
  const filterName = filter[1][1].split(':')[1];
  const filterId = filter[1][3];
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const arrayFilterObject = {
    "query": filterName,
    "search": search,
    "per_page": 5
  }
  const { state, dispatch } = useFilterContext();
  const [allCategories, setAllCategories] = useState({});
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    if( state.filtersSelected[filterId] ){
      let n = 0;
      for (let [key, value] of Object.entries(state.filtersSelected)) {
        n = value
      }
      const array = Object.entries(n)
      let contentToPush = [];
      array.map((item) => {
        contentToPush.push({
          value: Number(item[1].value),
          text: item[1].text
        })
      })
      setSelectedItems(contentToPush);
    }
    else{
      setSelectedItems([]);
      setSearch('');
      setRecords([]);
    }
  }, [state.filtersSelected]);

  const encoder = new DataEncoder();
  const fetchAutocompleteFilters = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/autocomplete?${encoder.encode(arrayFilterObject)}`);
      if (!response.ok) {
        throw new Error('Error al cargar los filtros');
      }
      const responseData = await response.json();
      const recordsArray = Object.keys(responseData.records).map(key => {
        return responseData.records[key];
      });
      const newRecords = recordsArray.filter(record => 
        !selectedItems.some(selectedItem => selectedItem.value === record.value)
      );
      setRecords(newRecords);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleSearchAutocomplete = (event) => {
    const searchText = event.target.value;
    setSearch(searchText);
  };

  useEffect(() => {
    if (search !== '' && search.length > 2) {
      fetchAutocompleteFilters();
    }
  }, [search, selectedItems]);

  function handleCheckBoxSpan(selected) {
    if(selectedItems.length > 0){
      const index = selectedItems.findIndex(item => item.value === selected.value);
      let updatedSelected;
      if (index === -1) {
        updatedSelected = [...selectedItems, selected];
      } else {
        updatedSelected = [...selectedItems];
        updatedSelected.splice(index, 1);
      }
      setSelectedItems(updatedSelected);
      const idsToUpdate = updatedSelected.map(item => item.value);
      dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filterId, values: idsToUpdate } });
    }
    else{
      const updateSelected = [...selectedItems, selected];
      setSelectedItems(updateSelected);
      const idsToUpdate = updateSelected.map(item => item.value);
      dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filterId, values: idsToUpdate } });
    }
  }

  const RenderCategories = ({ categories }) => {
    // Función recursiva para renderizar categorías y subcategorías
    const renderCategories = (categories, level = 0) => {
      // Verifica si el nivel es mayor que 4 para detener la recursividad
      if (level > 4 || !categories) return null;
  
      return categories.map((category, index) => (
        <div key={index}>
          <div style={{ marginLeft: `${level * 20}px` }}>
            <strong>Nivel {level + 1}:</strong> {category.description}
          </div>
          {category.categories && category.categories.length > 0 && (
            <div>
              {renderCategories(category.categories, level + 1)}
            </div>
          )}
        </div>
      ));
    };
  
    return <div>{renderCategories(categories)}</div>;
  };

  return (
    <div className="flex flex-col items-center py-4 w-full">
      <div className='w-full'>
        <input type="text" className='w-full p-2 bg-transparent border rounded-md border-gray' onChange={handleSearchAutocomplete} placeholder='Busca...' value={search}/>
      </div>
      <div className='flex flex-row flex-wrap w-full mt-4 gap-x-4'>
        {
          selectedItems.length > 0 ?
            selectedItems.map((record, index) => {
              return (
                <div key={record.value} className='w-full' onClick={() => handleCheckBoxSpan(record)}>
                  <span className='w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter checked'></span>
                  <label htmlFor="">{record.text}</label>
                </div>
              )
            })
            : null
        }
        {
          records.map((record, index) => {
            return (
              <div key={record.value} className='w-full' onClick={() => handleCheckBoxSpan(record)}>
                <span className='w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter'></span>
                <label htmlFor="">{record.text}</label>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default FilterBrands;
