import React from 'react';
import { useEffect, useState } from 'react';
import { is, DataEncoder } from '../utils/common';
import { useFilterContext } from '../helpers/FilterContext';
import { set } from 'date-fns';


function FilterInputArrayRange({ filter, filtersContent, fetchedFilters }) {
  const filterName = filter[1][1].split(':')[1];
  const filterId = filter[1][3];
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState('');
  const [zipSelected, setZipSelected] = useState({});
  const [distanceInitial, setDistanceInitial] = useState(100);
  const distancesCustom = [
    5, 10, 15, 20, 30, 40, 50, 100, 1000
  ]
  const { state, dispatch } = useFilterContext();
  const [previousZipSelected, setPreviousZipSelected] = useState();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    if( state.filtersSelected.zip_distance ){
      let n = 0;
      for (let [key, value] of Object.entries(state.filtersSelected)) {
        n = value
      }
      const array = Object.entries(n)
      //vamos a pasar este objeto a array state.filtersSelected.zip_distance
      const arrayZipDistance = Object.entries(state.filtersSelected.zip_distance)

      setZipSelected(arrayZipDistance[0][1]);
    }
    else{
      setZipSelected({});
      setSearch('');
    }
  }, [state.filtersSelected]);

  const encoder = new DataEncoder();
  const fetchAutocompleteFilters = async () => {
    const arrayFilterObject = {
      "query": filterName,
      "search": search,
      "per_page": 5
    }
    try {
      const response = await fetch(`${API_BASE_URL}/autocomplete?${encoder.encode(arrayFilterObject)}`);
      if (!response.ok) {
        throw new Error('Error al cargar los filtros');
      }
      const responseData = await response.json();
      const recordsArray = Object.keys(responseData.records).map(key => {
        return responseData.records[key];
      });
      const newRecords = recordsArray.filter(record => record.value !== zipSelected.value);
      setRecords(newRecords);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleSearchAutocomplete = (event) => {
    const searchText = event.target.value;
    setSearch(searchText);
  };

  useEffect(() => {
    if (search !== '' && search.length > 3) {
      fetchAutocompleteFilters();
    }
  }, [search, zipSelected]);

  function handleCheckBoxSpan(selected){
    if(selected){
      dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filterId, values: [selected.value, distanceInitial] } });
    }
    else{
      dispatch({ type: 'REMOVE_FILTER', payload: filterId });
      setDistanceInitial(100);
    }
  }
  function handleSetZipDistance(distance){
    dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filterId, values: [zipSelected.value, distance] } });
    setDistanceInitial(distance);
  }
  return (
    <div className="flex flex-col items-center py-4">
      <div className='w-full'>
        <input id='distance_filter_input' type="text" className='w-full p-2 bg-transparent border rounded-md border-gray' onChange={handleSearchAutocomplete} placeholder='Introduce tu código postal' value={search}/>
      </div>
      <div className='flex flex-row flex-wrap w-full mt-4 gap-x-4'>
        {
          zipSelected.value ?
            <div className='w-[calc(100%-10px)]' onClick={() => handleCheckBoxSpan(false)}>
              <span className='w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter checked'></span>
              <label>{zipSelected.text}</label>
            </div>
            :  null
        }
        {
          records.map((record, index) => {
            return (
              <div key={record.value} className='w-full' onClick={() => handleCheckBoxSpan(record)}>
                <span className='w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter'></span>
                {/* <span className='w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter' onClick={handleCheckBoxSpan}></span> */}
                <label htmlFor="">{record.text}</label>
              </div>
            )
          })
        }
      </div>
      {
        zipSelected.value &&
          <div className='pt-4'>
            <h4 className='font-bold'>Editar rango</h4>
            <div className='flex flex-row flex-wrap gap-4'>
              {
                distancesCustom.map((distance, index) => {
                  return (
                    <div key={index} className='w-[calc(33%-10px)]' onClick={() => handleSetZipDistance(distance)}>
                      <span className={`w-[15px] h-[15px] border border-primary inline-block mr-2 rounded-full checkbox-filter ${distance == distanceInitial ? 'checked' : ''}`}></span>
                      <span>{distance} km</span>
                    </div>
                  )
                }
                )
              }
            </div>
          </div>

      }
    </div>
  );
}

export default FilterInputArrayRange;
