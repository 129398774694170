import Skeleton from 'react-loading-skeleton'
import imageProduct from '../assets/images/image-product.png';
import imageHeart from '../assets/images/heart.svg'; 

function ProductCardSkeleton() {
  return (
    <div className={`w-[21.375rem] relative flex h-[32.5rem] shrink-0 grow-0 flex-col rounded-2xl bg-white p-4`}>
      
      <div className="flex h-full flex-1 flex-col">
          <div className="flex-1">
            <Skeleton height={240} />
          </div>
          <div className="flex flex-col">
              <div className="body uppercase text-gray">
                <Skeleton />
              </div>
              <h4 className="mt-2">
                <Skeleton />
              </h4>
              <div className="body mt-3">
                <Skeleton count={2} />
              </div>
          </div>
          <div className="mt-5 flex items-center justify-between">
              <div className="flex flex-col">
                  <span className="strong text-primary"><Skeleton /></span>
                  <span className="caption"><Skeleton /></span>
              </div>
              <div className="flex flex-col">
                  <span><Skeleton /></span>
                  <span className="caption"><Skeleton /></span>
              </div>
          </div>
      </div>
      <button className="mt-4 w-full"><Skeleton /></button>
    </div>
  );
}

export default ProductCardSkeleton;