import React from 'react';
import Star from './Star';

const StarRating = ({ rating, maxRating = 5 }) => {
  const filledStars = Math.floor(rating);
  const hasPartialStar = rating % 1 !== 0;
  const partialStarWidth = `${(rating % 1) * 100}%`;
  const emptyStars = Math.floor(maxRating - rating);

  return (
    <div className="flex items-center gap-1">
      {Array.from({ length: filledStars }).map((_, index) => (
        <div className="" key={`filled-${index}`}>
          <Star fill="#FFC700" />
        </div>
      ))}
      {hasPartialStar && (
        <div className="relative" key="partial">
          <Star fill="#B8B8B8" />
          <div className="absolute top-0 left-0 overflow-hidden" style={{ width: partialStarWidth }}>
            <Star fill="#FFC700" />
          </div>
        </div>
      )}
      {Array.from({ length: emptyStars }).map((_, index) => (
        <div key={`empty-${index}`}>
          <Star fill="#B8B8B8" />
        </div>
      ))}
    </div>
  );
};

export default StarRating;
