import React, { useEffect } from 'react';
import Button from '../components/ui/Button';
import { useEvent } from '../helpers/useEvent';

const Thanks = () => {

    const sendEvent = useEvent();

    useEffect(() => {
        sendEvent('purchase');
    }, []);

    return (
        <div className='flex flex-col items-center justify-center py-10 h-1/2'>
            <div className='flex flex-col gap-10 p-4 bg-white shadow rounded-xl py-10 w-[80%]'>
                <h1 className="text-3xl font-bold text-primary">¡Gracias por tu nuevo alquiler!</h1>
                <p>Te hemos enviado un correo electrónico con más detalles.</p>
                <Button href="/alquiler" type='primary' classButton={'w-fit mx-auto'}>
                    Seguir alquilando
                </Button>
            </div>
        </div>
    );
};

export default Thanks;
