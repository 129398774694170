import React, { useState } from "react";

const CartDrawerContent = ({
	cartData,
	onDeleteLine,
	onDeliveryLine,
	showDeleteButton,
	showDeliveryOptions,
	zip,
	sessionId,
}) => {
	const [deliveryOptions, setDeliveryOptions] = useState({});

	// console.log("Rendering CartDrawerContent with cartData:", cartData);

	if (
		!cartData ||
		!cartData.sales_orders ||
		cartData.sales_orders.length === 0
	) {
		return <div>No hay datos del carrito disponibles.</div>;
	}
	
	const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;

	const formatCurrency = (number) => {
		return new Intl.NumberFormat("es-ES", {
			style: "currency",
			currency: "EUR",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(number);
	};

	const handleDeliveryOptionChange = (lineId, option, value) => {
		const updatedOptions = { ...deliveryOptions[lineId], [option]: value };
		setDeliveryOptions((prev) => ({ ...prev, [lineId]: updatedOptions }));
		if (zip) {
			onDeliveryLine(sessionId, lineId, updatedOptions, zip);
		} else {
			console.error("Código postal no disponible");
		}
	};

	const renderProductContent = (line, salesLines) => {
		const productName = line.product.short_name;
		const options = deliveryOptions[line.id] || {};
		const relatedDescriptions = salesLines
			.filter((l) => l.related_line_id === line.id)
			.map((l) => ({
				description: l.description,
				base_line: l.base_line
			}));

		return (
			<div className="relative flex flex-col w-[70%] lg:w-full">
				<div className="flex gap-4 justify-start">
					<span className="font-bold truncate text-primary">{productName}</span>
					{showDeleteButton && (
						<button
							className="absolute bottom-0 right-0 p-2 text-xs text-white transition-all duration-300 ease-in-out rounded-lg bg-light hover:bg-red-400"
							onClick={() => onDeleteLine(cartData.session_id, line.id)}>
							<svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clipPath="url(#clip0_1503_64120)">
								<path d="M20.5851 5.63623H5.88342V20.3379C5.88342 22.6481 7.77364 24.5384 10.0839 24.5384H16.3846C18.6949 24.5384 20.5851 22.6481 20.5851 20.3379V5.63623Z" stroke="#414042" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M3.78314 5.63623H22.6853" stroke="#414042" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M14.2843 1.43579H12.1841C11.029 1.43579 10.0839 2.3809 10.0839 3.53603V5.63626H16.3846V3.53603C16.3846 2.3809 15.4395 1.43579 14.2843 1.43579Z" stroke="#414042" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M11.134 10.8868V19.2878" stroke="#414042" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M15.3345 10.8868V19.2878" stroke="#414042" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
								</g>
								<defs>
								<clipPath id="clip0_1503_64120">
								<rect width="25.2028" height="25.2028" fill="white" transform="translate(0.632812 0.38562)"/>
								</clipPath>
								</defs>
							</svg>
						</button>
					)}
				</div>
				<div className="pr-12 text-left">
					{line.offer_info && line.offer_info.days ? (
						<div className="w-full pr-2 text-sm">
							<p className="flex justify-start w-full gap-2">
								Días:{" "}
								<span className="font-bold">
									{line.offer_info.days.quantity}
								</span>
							</p>
							<p className="flex justify-start w-full gap-2">
								Precio/día:{" "}
								<span className="font-bold ">
									{formatCurrency(line.offer_info.days.day_price)}
								</span>
							</p>
							{relatedDescriptions.length > 0 &&
								relatedDescriptions.map((desc, index) => (
									<p key={index} className="flex justify-start w-full gap-2">
										{desc.description}:{" "}
										<span className="font-bold">
											{formatCurrency(desc.base_line)}
										</span>
									</p>
								))}
							{/* <p className="flex justify-between w-full">
								Precio total:{" "}
								<span className="font-bold">
									{formatCurrency(line.base_line)}
								</span>
							</p> */}

							{showDeliveryOptions && (
								<div className="flex flex-col">
									{/* <label>
										<input
											type="checkbox"
											checked={options.storePickup || false}
											onChange={(e) =>
												handleDeliveryOptionChange(
													line.id,
													"storePickup",
													e.target.checked
												)
											}
										/>{" "}
										Recoger en tienda
									</label>
									<label>
										<input
											type="checkbox"
											checked={options.storeDelivery || false}
											onChange={(e) =>
												handleDeliveryOptionChange(
													line.id,
													"storeDelivery",
													e.target.checked
												)
											}
										/>{" "}
										Devolver en tienda
									</label> */}
								</div>
							)}

						</div>
					) : null}
				</div>
			</div>
		);
	};

	const cartItems = cartData.sales_orders.flatMap((order) =>
		order.sales_orders_lines
			.filter((line) => line.related_line_id === null)
			.map((line) => (
				<div
					key={line.id}
					className="flex w-full gap-4 p-2 mb-4 text-left border border-gray-500 h-fit rounded-xl">
					{line.product.image && line.product.image.path && (
						<img
							src={`${IMAGE_BASE_URL}${line.product.image.path}`}
							alt={line.product.short_name}
							style={{ width: "80px", height: "80px" }}
						/>
					)}
					{renderProductContent(line, order.sales_orders_lines)}
				</div>
			))
	);

	const filteredCartItems = cartItems.filter(Boolean);

	// console.log("Filtered cart items:", filteredCartItems);

	return (
		<div className="h-[50vh] lg:h-[60vh] overflow-scroll">
			{filteredCartItems.length > 0 ? (
				filteredCartItems
			) : (
				<div className="h-full w-full flex justify-center items-center h3 px-12">
					No hay elementos en el carrito.
				</div>
			)}
		</div>
	);
};

export default CartDrawerContent;
