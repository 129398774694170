import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

export function TransparentTabs({ description, location }) {
  const data = [
    {
      label: "Descripción",
      value: "descripcion",
      desc: description || "",
    },
    {
      label: "Localización",
      value: "localizacion",
      desc: location && location.geocodezip ? `
        Localidad: ${location.geocodezip.place_name || 'N/A'}<br/>
        Región: ${location.geocodezip.region || 'N/A'}<br/>
        Código Postal: ${location.zip || 'N/A'}
        <iframe
          width="600"
          height="450"
          style="border:0"
          loading="lazy"
          allowFullScreen
          className="w-full"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCvygGCAxzvKyhIYAnhKV4tOCfsKdGR5sA&q=${location.geocodezip.place_name},${location.geocodezip.zip}">
        </iframe>
      ` : "No hay información de localización disponible.",
    },
  ];

  return (
    <Tabs value="descripcion" className="w-full DescriptionAlquila">
      <TabsHeader
        indicatorProps={{
          className:
            "flex gap-2 px-0 items-end md:px-4 lg:px-12 border-b-2 border-gray border-primary bg-transparent shadow-none rounded-none",
        }}
      >
        {data.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            className="w-full pb-4 font-bold text-center text-black border-b-2 cursor-pointer lg:w-1/4 "
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <div className="font-bold text-left text-primary">
            <TabPanel key={value} value={value} className="px-4 mt-4 text-base font-bold lg:px-20">
              <div dangerouslySetInnerHTML={{ __html: desc }} />
            </TabPanel>
          </div>
        ))}
      </TabsBody>
    </Tabs>
  );
}
