import ImageWithText from "../components/sections/ImageWithText";
import ImageWithTextImage from "../../src/assets/images/Team/hero-team.webp";
import Team1Image from "../../src/assets/images/Team/team-talent.webp";
import Team2Image from "../../src/assets/images/Team/team-remote_friendly.webp";
import Team3Image from "../../src/assets/images/Team/team-diversity.webp";
import TeamBgImage1 from "../../src/assets/images/Team/bg_team-1.webp";
import TeamBgImage2 from "../../src/assets/images/Team/bg_team-2.webp";
import TeamBgImage3 from "../../src/assets/images/Team/bg_team-3.webp";

const ImageWithTextVariables = {
  title: "trabaja con  nosotros",
  highlightedText: " nosotros",
  highlightClass: "text-primary block",
  subtitle: "haz realidad tu futuro profesional",
  subtitle_3:
    "siempre buscamos nuevos talentos con ganas de crecer para incorporarlos a nuestro equipo",
  buttonText: "Únete al equipo",
  href: "#section1",
  image: ImageWithTextImage,
  alt: "Imagen tomada desde arriba donde se aprecian las manos de dos personas, una de ella está utilizando un portátil y la otra una tablet y representan la uníon del equipo de elalquilador.com",
  useH1: true,
  h1AdditionalClasses: "flex flex-wrap justify-center gap-x-2.5",
};

function Team() {
  return (
    <>
      <ImageWithText variables={ImageWithTextVariables} />
      {/* to be converted to reusable componenet */}
      <section className="text-start py-14">
        <div className="container m-auto text-black h2 px-4 lg:px-20 space-y-14 md:space-y-24">
          {/* Team 1 */}
          <div className="w-full md:flex items-center">
            <div className="h-full w-full md:w-7/12">
              <p className="pt-9 h1 lowercase text-black">
                Buscamos el{" "}
                <span className="text-primary">talento en cada persona</span>
              </p>
              <p className="pt-2 pb-6 body font-bold text-black">
                La contribución de todos es fundamental gracias a la
                heterogeneidad de nuestras competencias.
              </p>
              <p className="body text-dark leading-5">
                Nuestro patrimonio personal y profesional es nuestra riqueza y
                el trabajo en equipo es nuestra fortaleza.
              </p>
              <p className="body text-dark leading-5">
                La empatía es la que nos guía hacia los resultados.
              </p>
            </div>
            <img
              src={Team1Image}
              className="mt-4 md:mt-0 rounded-3xl h-full w-full md:w-5/12 object-contain"
              alt="Grupo de personas uniendo sus manos en señal de trabajo en equipo y colaboración"
            />
          </div>
          {/* Team 2 */}
          <div className="w-full md:flex items-center gap-12">
            <div className="h-full w-full md:w-7/12 order-2 md:order-1">
              <p className="pt-4 pb-6 h1 lowercase text-black">
                Somos ¡<span className="text-primary">remote-friendly</span>!
              </p>
              <p className="body text-dark leading-5">
                En nuestro equipo todos tenemos la opción de trabajar desde casa
                dos días a la semana. Estamos orgullosos de ser un espacio de
                trabajo que ofrece igualdad de oportunidades.
              </p>
            </div>
            <img
              src={Team2Image}
              className="mt-4 md:mt-0 rounded-3xl h-full w-full md:w-5/12 object-contain order-last md:order-first"
              alt="Persona trabajando en un portátil desde una cocina de casa o coworking, representando el teletrabajo"
            />
          </div>
          {/* Team 3 */}
          <div className="w-full md:flex items-center gap-12">
            <div className="h-full w-full md:w-7/12">
              <p className="pt-4 pb-6 lowercase h1 text-black">
                En elalquilador celebramos la
                <span className="text-primary lowercase"> diversidad</span>
              </p>
              <p className="body text-dark leading-5">
                Estamos comprometidos a crear un ambiente de trabajo inclusivo
                para todos los empleados independientemente de su experiencia,
                origen, género, religión, orientación sexual, edad o
                discapacidad.
              </p>
            </div>
            <img
              src={Team3Image}
              className="mt-4 md:mt-0 rounded-3xl h-full w-full md:w-5/12 object-contain"
              alt="diversas manos de diferentes índoles alineadas sobre una pared amarilla, simbolizando diversidad e inclusión"
            />
          </div>
        </div>
      </section>
      {/* to be converted to reusable componenet */}
      {/* <section className="bg-light w-full h-[30vh] lg:h-[70vh] flex items-center justify-center">
        <div className="w-1/3 h-full">
          <img
            src={TeamBgImage1}
            className="object-cover bg-white h-full w-full"
            alt="marketing en elalquilador"
          />
        </div>
        <div className="w-1/3 h-full">
          <img
            src={TeamBgImage2}
            className="object-cover bg-white h-full w-full"
            alt="asesoramiento de herramientas y maquinaria"
          />
        </div>
        <div className="w-1/3 h-full">
          <img
            src={TeamBgImage3}
            className="object-cover bg-white h-full w-full"
            alt="alquiler de maquinaria online"
          />
        </div>
      </section> */}
    </>
  );
}

export default Team;
