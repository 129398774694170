import react, { useState } from 'react';
import Menu from '../../components/private/Menu';
import { useAuth } from "../../hooks/AuthProvider";


function Security() {
	const { token } = useAuth();
	const [passwordChange, setPasswordChange] = useState(false);
	const [data, setData] = useState({
		password: '',
		password_confirmation: '',
	});

	const [errors, setErrors] = useState({});

	function validateInputs(inputs) {
		let errors = {};
		if (!inputs.password) {
			errors.password = 'La contraseña es obligatoria.';
		}
		if (!inputs.password_confirmation) {
			errors.password_confirmation = 'Confirmar contraseña es obligatoria.';
		}
		return errors;
	}
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
	function handleChangePassword(event) {
		event.preventDefault();
		const validationErrors = validateInputs(data);
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
		} else {
			setErrors({});
			fetch(`${API_BASE_URL}/user`, {
				method: 'PATCH',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify(data)
			}).then(response => {
				return response.json().then(data => ({ status: response.status, body: data }));
			}).then(result => {
				if (result.status === 200) {
					setPasswordChange(true);
				} else if (result.status === 422) {
					const errors = result.body.errors || {};
					const errorMessages = Object.keys(errors).map(key => `${key}: ${errors[key].join(', ')}`).join('\n');
					alert(`Errores de validación:\n${errorMessages}`);
				} else if (result.status === 500) {
					alert('Error en el servidor');
				} else {
					alert('Error en la petición');
				}
			}).catch(error => {
				console.error('Error:', error);
			});
		}
	}

	return (
		<div className="p-[15px] md:p-[35px] flex flex-col md:flex-row gap-8 md:gap-24 min-h-[calc(100vh-135px)]">
			<Menu position="security" />
			<div className="w-full">
				<h2 className="text-left text-[34px] text-primary font-bold mb-2">seguridad</h2>
				<div className="flex flex-col items-start justify-start w-full h-full p-8 bg-white rounded-xl">
					{passwordChange ? (
						<div className="flex flex-col gap-10">
							<div className="text-2xl font-bold text-primary">¡Contraseña cambiada!</div>
							<div className="">Hemos actualizado tu contraseña correctamente. Recibiras un correo notificando el cambio.</div>
						</div>) : (
						<div className="contents">
							<div className="text-left ">
								<h3 className="text-lg font-bold">Aqui puedes modificar tu contraseña</h3>
							</div>
							<div className="w-full mt-8 text-left ">
								<form onSubmit={handleChangePassword}>
									<div className="flex flex-row flex-wrap gap-3">
										<div className="w-full md:w-1/2 mt-4">
											<label htmlFor="" className="pl-[25px]">Contraseña nueva</label>
											<input
												type="password"
												value={data.password}
												onChange={(e) => setData({ ...data, password: e.target.value })}
												className='w-full px-6 py-4 mt-2 transition-all duration-150 ease-in-out rounded-full bg-light'
												placeholder="contraseña"
											/>
										</div>
										<div className="w-full md:w-1/2 mt-2">
											<label htmlFor="" className="pl-[25px]">Confirmar contraseña nueva</label>
											<input
												type="password"
												value={data.password_confirmation}
												onChange={(e) => setData({ ...data, password_confirmation: e.target.value })}
												className='w-full px-6 py-4 mt-2 transition-all duration-150 ease-in-out rounded-full bg-light'
												placeholder="confirmar contraseña"
											/>
										</div>
										<button
											type="submit"
											className='flex items-center justify-center w-full md:w-1/2 gap-2 px-24 py-4 mt-4 text-white transition-all duration-150 ease-in-out rounded-full bg-primary button stroke-white hover:bg-opacity-90'>
											Guardar cambios
										</button>
									</div>
								</form>
							</div>
						</div>
					)}

				</div>
			</div>
		</div>
	)
}

export default Security;