// NotificationContext.jsx
import React, { createContext, useContext, useState } from 'react';
import Notification from '../components/ui/Notification';
const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({ isVisible: false, message: '' });

  const showNotification = (message) => {
    console.log('showNotification', message);
    setNotification({ isVisible: true, message });
    setTimeout(() => {
      setNotification({ isVisible: false, message: '' });
    }, 3000);
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      <Notification {...notification} />
      {children}
    </NotificationContext.Provider>
  );
};