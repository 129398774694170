import React from 'react';

const Label = ({ text, borderColor="transparent", borderRadius = '24px', color = '#414042', backgroundColor = '#FFFFFF' }) => {
  const labelStyle = {
    border: '2px solid',
    borderRadius,
    borderColor,
    color,
    backgroundColor,
    padding: '4px 12px'
  };

  return (
    <span className="text-[12px]" style={labelStyle}>
      {text}
    </span>
  );
};

export default Label;
